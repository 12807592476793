import { axiosInstance } from '../lib/api_client'

type CreateParams = Readonly<{
  unitId: number
  quantity: number
  mode: 'payment' | 'subscription'
}>

type BasketRepository = Readonly<{
  create: (params: CreateParams) => Promise<void>
  delete: (unitId: number) => Promise<void>
  delete_all: () => Promise<void>
}>

const basketRepository: BasketRepository = {
  async create ({ unitId, quantity, mode }) {
    return await axiosInstance.post(`/units/${unitId}/basket`, { quantity, mode })
  },
  async delete (unitId) {
    return await axiosInstance.delete(`/units/${unitId}/basket`)
  },
  async delete_all () {
    return await axiosInstance.post('/basket/delete_all')
  },
}

export { basketRepository }
