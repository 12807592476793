import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'

const GoogleAnalytics = () => {
  const location = useLocation()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [location.pathname])

  return null
}

export { GoogleAnalytics }
