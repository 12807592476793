import { createContext, useState } from 'react'

const ModalContext = createContext<{
  modalState: string
  setModalState?: (state: string) => void
  modalVars: any
  setModalVars?: (object: any) => void

}>({ modalState: '', modalVars: {} })

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState<string>('')
  const [modalVars, setModalVars] = useState<any>({})

  return (
    <ModalContext.Provider value={ { modalState, setModalState, modalVars, setModalVars } }>
      { children }
    </ModalContext.Provider>
  )
}

export { ModalProvider, ModalContext }
