import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '../stylesheets/Error.scss'
import { AuthContext } from '../contexts/auth_context'
import { BasketContext } from '../contexts/basket_context'

const ErrorExpired = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <div className="generic-not-found">
      <div>
        <div className="art">
        </div>
        <h1>{ t('errors.expired.title') }</h1>
        <p>
          {
            isAuthenticated
              ? t('errors.expired.body1')
              : t('errors.expired.body2')
          }
        </p>
        <p>
          {
            isAuthenticated
              ? <Link to="/account/buy" className="button">{ t('errors.expired.button_orders') }</Link>
              : <Link to="/login" className="button">{ t('errors.expired.button_login') }</Link>
          }
        </p>
        <p>
          <Link to="/" className="button white">{ t('errors.expired.button_top') }</Link>
        </p>
      </div>
    </div>
  )
}

export { ErrorExpired }
