const AboutPersonalInformation = () => {
  return <>
    <h1>本人情報の登録について</h1>
    <p>
      お客様に安心してご利用いただくために、お客様の個人情報（お名前、生年月日など）をご登録いただきます。
    </p>
    <p>
      なお、一度登録された個人情報を変更される場合は、本人確認書類の提出が必要になります。
    </p>
    <p>
      すでにHOLICをご利用のお客様で、まだ個人情報を登録されていない方は、以下のタイミングで個人情報の登録が必要になります。
    </p>
    <ul>
      <li>商品を出品するとき</li>
      <li>商品を購入するとき</li>
    </ul>
    <p>
      個人情報の登録は、初回のみ必要です。サービスを利用するたびに登録する必要はありません。
    </p>
    <p>
      登録されたお名前と売上金の振込先口座の名義が異なる場合は、振込依頼をお受けすることができません。
    </p>
    <p>
      口座名義がお客様のお名前と一致しなくなった場合（姓が変わった場合等）、HOLICを継続してご利用いただくためには、個人情報を更新していただく必要があります。
    </p>
  </>
}

export { AboutPersonalInformation }
