import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CardSettings, Image, Item, TagPreview, Unit } from '../types'
import clsx from 'clsx'
import { ItemImages } from './item_images'
import { ItemCollect } from './item_collect'
import { SearchContext } from '../contexts/search_context'

type Props = Readonly<{
  // cardCategoryVariant: CardCategoryVariant
  item: Item
  cardSettings: CardSettings
  addRemoveCallback?: (object) => void
  className?: string
}>

const CardSingle = ({ item, cardSettings, addRemoveCallback, className }: Props) => {
  const { t, i18n } = useTranslation()

  const { filters, searchString, endpointForExistingFilters } = useContext(SearchContext)

  const [ownsAtLeastOne, setOwnsAtLeastOne] = useState<boolean>(false)
  const [images, setImages] = useState<Image[]>()
  const [units, setUnits] = useState<Unit[]>([])
  const [collectView, setCollectView] = useState<boolean>(false)

  useEffect(() => {
    setUnits(item.units ?? [])
  }, [item.units])

  useEffect(() => {
    setOwnsAtLeastOne(units.length > 0)
  }, [units.length])

  // determine category
  const category = useMemo<TagPreview|undefined>(() => {
    if (item.tags.length === 0) return

    return item.tags.reduce((prev, current) => prev.depth > current.depth || current.type !== 'Category' ? prev : current)
  }, [item.tags])

  const itemName = useMemo(() => {
    return (i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn
  }, [i18n.language, item.nameEn, item.nameJa])

  const categoriesLink = useCallback((tagId: string) => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, searchString)

    return p + params
  }, [endpointForExistingFilters, filters, searchString])

  const itemCategoryLink = useMemo(() => {
    if (!category) return

    let title: string|undefined = category.slug ? category.slug.split('_')[0] : undefined

    if (!title || title.length < 1) {
      title = (i18n.language === 'ja' && category.nameJa) ?? !category.nameEn ? category.nameJa : category.nameEn
    }

    return <Link to={ categoriesLink(String(category.id)) } className="card-category"><h4>{ title }</h4></Link>
  }, [categoriesLink, category, i18n.language])

  useEffect(() => {
    setImages(item.images)
  }, [item.images])

  const viewItemLink = useMemo(() => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${category?.id ?? 1}/items/${item.id}`

    const params = endpointForExistingFilters(filters, searchString)

    return p + params
  }, [category?.id, endpointForExistingFilters, filters, item.id, searchString])

  const viewItemButton = useMemo(() => {
    // const buttonText = t('cards.view')

    const buttonText = item.holicListingsCount > 0
      ? t('cards.view_sale', { c: item.holicListingsCount, p: item.holicMinimumPrice.toLocaleString() })
      : t('cards.view')

    return (
      <Link className={ clsx('button', { tool: item.holicListingsCount <= 0 }, { small: item.holicListingsCount > 0 }, { purchasable: item.holicListingsCount > 0 }) } to={ viewItemLink }>{ buttonText }</Link>
    )
  }, [item.holicListingsCount, item.holicMinimumPrice, t, viewItemLink])

  const viewItemButtonSmall = useMemo(() => {
    // return (
    //   <Link className={ clsx('button', 'tool', 'view-item') } to={ viewItemLink }>{ t('cards.view_small') }</Link>
    // )

    return <Link className={ clsx('button', 'tool', 'view-item') } to={ `/items/${item.id}/listing` }>{ t('cards.sell') }</Link>
  }, [item.id, t])

  const addRemoveCallbackItem = useCallback((params) => {
    if (params.value > 0) {
      // adding a unit
      const newUnit: Unit = {
        condition: params.condition,
        verified: false,
      }

      setUnits(units => units ? units.concat([newUnit]) : [newUnit])
    } else {
      // removing a unit
      const newUnits = units
      let index = -1

      if (params.id) {
        index = units?.findIndex(unit => unit.id === params.id)
      } else {
        index = units?.findIndex((e) => {
          return (
            e.condition === params.condition
          )
        })
      }

      if (index < 0) return

      newUnits?.splice(index, 1)

      setUnits([...newUnits])
    }

    addRemoveCallback?.({ condition: params.condition, value: params.value, itemId: item.id, id: params.id })
  }, [addRemoveCallback, item.id, units])

  const itemCollect = useMemo(() => {
    if (!collectView) return

    return <ItemCollect collectCallback={ addRemoveCallbackItem } units={ units } item={ item } defaultCondition={ cardSettings.defaultCondition ?? 0 } />
  }, [addRemoveCallbackItem, cardSettings.defaultCondition, collectView, item, units])

  const itemCollectButton = useMemo(() => {
    if (!cardSettings.canCollect) return

    return <button className={ 'gold tool collect-item' } onClick={ _ => { setCollectView(v => !v) } }>{ collectView ? t('cards.collect_cancel') : t('cards.collect') }</button>
  }, [cardSettings.canCollect, collectView, t])

  return (
    <li className={ clsx('card', className, { owned: ownsAtLeastOne && cardSettings.canCollect }, { 'can-collect': cardSettings.canCollect }) }>
      <div className="flex item-name">
        <h3>{ itemName }</h3>
        { itemCategoryLink }
      </div>
      <ItemImages images={ images } resolution={ cardSettings.imageResolution } link={ viewItemLink } navigationStyle='grouped' />
      { viewItemButton }
      <div className='item-actions'>
        { itemCollectButton }
        { viewItemButtonSmall }
      </div>
      { itemCollect }
    </li>
  )
}

export { CardSingle }
