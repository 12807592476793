import { useMemo, useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'
import queryString from 'query-string'

import { AccountGeneral } from './account_general'
import { AccountBuy } from './account_buy'
import { AccountSell } from './account_sell'
import { AccountPoints } from './account_points'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/auth_context'
import { Register } from './register'

type AccountView = Readonly<{
  path: string
}>

const accountViews: AccountView[] = [
  {
    path: 'general'
  },
  {
    path: 'buy'
  },
  {
    path: 'sell'
  },
  {
    path: 'points'
  },
]

type AccountProps = Readonly<{
  gitSha: string
}>

const Account = ({ gitSha }: AccountProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useContext(AuthContext)

  const qS = queryString.parse(location.search)
  const page = qS.page as string
  const [currentPage, setCurrentPage] = useState<number>(Number(page ?? 1))

  const [currentView, setCurrentView] = useState<AccountView>(accountViews[0])
  const [subpath, setSubpath] = useState<string|undefined>(undefined)

  const path = useParams()
  useEffect(() => {
    setCurrentView(accountViews.find(av => av.path === path.path) ?? accountViews[0])
    setSubpath(path.subpath)
  }, [path])

  const tabs = useMemo(() => {
    if (subpath) return

    return (
      <div className="tabs">
        {
          accountViews.map(a => {
            const link = a === accountViews[0] ? '' : `/${a.path}`
            const text = t(`account.paths.${a.path}`)

            return <Link key={ a.path } to={ `/account${link}` } className={ clsx({ selected: currentView === a }) }>{ text }</Link>
          })
        }
      </div>
    )
  }, [t, subpath, currentView])

  useEffect(() => {
    setCurrentPage(Number(page) || 1)
  }, [page])

  const settingsPage = useMemo(() => {
    if (currentView.path === 'sell') {
      return <AccountSell currentPage={ currentPage } subpath={ subpath } />
    }

    if (currentView.path === 'buy') {
      return <AccountBuy currentPage={ currentPage } />
    }

    if (currentView.path === 'points') {
      return <AccountPoints currentPage={ currentPage } />
    }

    return <AccountGeneral gitSha={ gitSha } />
  }, [currentPage, currentView.path, gitSha, subpath])

  if (!isAuthenticated) {
    return <Register />
  }

  return (
    <div>
      <div className="narrow">
        <h1>{ t('account.title') }</h1>
      </div>
      { tabs }
      { settingsPage }
    </div>
  )
}

export { Account }
