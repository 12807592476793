import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, Navigate, useMatch } from 'react-router-dom'
import queryString from 'query-string'
import { GenericNotFound } from './generic_not_found'
import { AuthContext } from '../contexts/auth_context'
import { Feedback, Message, Order, OrderUpdate } from '../types'
import { ordersRepository } from '../repositories/orders_repository'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { useTranslation } from 'react-i18next'
import { conditions } from '../lib/conditions'
import { countries } from '../lib/countries'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { OrderFeedback } from './order_feedback'

import '../stylesheets/Messages.scss'
import { OrderMessages } from './order_messages'
import Select from 'react-select'
import { SearchContext } from '../contexts/search_context'
import { UnitBuyCompleteModal } from './unit_buy_complete_modal'
import { ModalWrapper } from './modal_wrapper'
import { BasketContext } from '../contexts/basket_context'

const SubscribeCancelIntentModal = ({ order, onClose }: { order: Order, onClose: (status: string) => void }) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')

  const confirmChangeSubscriptionStatus = useCallback(() => {
    if (!order?.id || loading) return

    setLoading(true)

    const newStatus = order.status === 'active' ? 'cancelled' : 'active'

    const orderUpdate: OrderUpdate = {
      id: order.id,
      status: newStatus,
    }

    ordersRepository
      .update({ id: order.id, params: orderUpdate })
      .then(() => {
        setStatus(newStatus)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, onClose, order.id])

  const expiryDate = useMemo(() => {
    return order?.expiryDate ? new Date(order.expiryDate).toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' }) : ''
  }, [order?.expiryDate])

  useEffect(() => {
    if (!status && order.status === 'cancelled') {
      confirmChangeSubscriptionStatus()
    }
  }, [])

  if (!status && order.status === 'cancelled') {
    return <ModalWrapper>
      <p>Loading, please wait...</p>
    </ModalWrapper>
  }

  if (status === 'active') {
    return (
      <ModalWrapper>
        <p>ご継続ありがとうございます。</p>
        <p>引き続きKomifloをお楽しみください！</p>
        <p>
          <button className="full" onClick={ () => onClose(status) }>
            閉じる
          </button>
        </p>
      </ModalWrapper>
    )
  }

  if (status === 'cancelled') {
    return (
      <ModalWrapper>
        <h1>自動更新停止のご確認</h1>
        <p>自動更新の停止手続きが完了いたしました。</p>
        <p>サブスクリプション終了日まで引き続きお楽しみください。</p>
        <p>サブスクリプション期間は{ expiryDate }に終了します。</p>
        <p>
          <button className="full" onClick={ () => onClose(status) }>
            閉じる
          </button>
        </p>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      <h1>プランの管理</h1>
      <p>サブスクリプションの自動更新を本当に停止しますか？</p>
      <p>またのご利用をお待ちしております。</p>
      <p>
        <button className="full" onClick={ confirmChangeSubscriptionStatus } disabled={ loading }>
          キャンセル手続きの完了
        </button>
      </p>
      <p>
        <button className="full" onClick={ () => setStatus(order.status) } disabled={ loading }>
          続ける
        </button>
      </p>
    </ModalWrapper>
  )
}

const SubscriptionPlanSettingsModal = ({ setShowSubscriptionCancelIntent, onClose }: { setShowSubscriptionCancelIntent: (show: boolean) => void, onClose: () => void }) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper>
      <h1>プランの設定</h1>
      <p>
        <button className="full white" onClick={ () => {
            setShowSubscriptionCancelIntent(true)
            onClose()
          } }>
          プランのキャンセル
        </button>
      </p>
      {/* <p>
        <Link to={`/subscriptions/${order.id}`} className="full white button">
          お支払い方法の設定
        </Link>
      </p> */}
      <p>
        <button className="full" onClick={ onClose }>
          閉じる
        </button>
      </p>
    </ModalWrapper>
  )
}

type Status = Readonly<{
  value: string
  label: string
}>

const Orders = () => {
  const { t, i18n } = useTranslation()
  const { addError } = useContext(ErrorsContext)
  const { clearBasket } = useContext(BasketContext)

  const params = useMatch('/orders/:id')?.params

  const orderId = useMemo<number|'latest'>(() => {
    if (params === undefined) return 0

    return params.id === 'latest' ? 'latest' : parseInt(params.id ?? '0') || 0
  }, [params])

  const qS = queryString.parse(location.search)
  const result = qS.result as string
  const [showOrderCompleteModal, setShowOrderCompleteModal] = useState<boolean>(result === 'success')

  useEffect(() => {
    if (orderId === 'latest' && showOrderCompleteModal) {
      clearBasket?.()
    }
  }, [orderId, clearBasket, showOrderCompleteModal])

  const [order, setOrder] = useState<Order|undefined>()
  const [orderLoading, setOrderLoading] = useState<boolean>(true)
  const [statusUpdateLoading, setStatusUpdateLoading] = useState<boolean>(false)

  const { isAuthenticated } = useContext(AuthContext)

  const getOrder = useCallback(() => {
    setOrderLoading(true)

    ordersRepository
      .get(String(orderId))
      .then(({ order }) => {
        setOrder({
          ...order,
          // if the user has just completed a successful payment and the subscription status is pending, force the status to "active"
          status: showOrderCompleteModal && order.startDate && order.status === 'incomplete' ? 'active' : order.status,
        })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setOrderLoading(false)
      })
  }, [addError, orderId])

  useEffect(() => {
    getOrder()
  }, [getOrder])

  const isSubscription = useMemo(() => {
    return order?.startDate !== null
  }, [order?.startDate])

  const totalPrice = useMemo(() => {
    if (!order) return 0

    return +(order.itemPriceJpy ?? 0) + +(order.shippingPriceJpy ?? 0)
  }, [order])

  const country = useMemo(() => {
    if (!order?.address?.countryCode) return

    return countries.find(c => c.value === order?.address?.countryCode)
  }, [order])

  const support = useMemo(() => {
    if (order?.role !== 'buyer') return

    return <>
      <hr />
      <p className='center'>
        <a href="mailto:support@holic.net" className="button tool">{ t('order.view.help') }</a>
      </p>
    </>
  }, [order?.role, t])

  const {
    handleSubmit,
    control,
  } = useForm<OrderUpdate>()

  const status = useWatch({
    control,
    name: 'status',
  })

  const onSubmit = useCallback((params) => {
    if (!order?.id || statusUpdateLoading) return

    // TODO: Validation
    const orderUpdate: OrderUpdate = { ...params }

    ordersRepository
      .update({ id: order.id, params: orderUpdate })
      .then(() => {
        setOrder({
          ...order,
          status: params.status
        })
        alert(t('order.view.status_updated'))
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setStatusUpdateLoading(false)
      })

    setStatusUpdateLoading(true)
  }, [addError, order, statusUpdateLoading, t])

  const updateOrder = useMemo(() => {
    if (order?.status === 'cancelled') return

    if (order?.role !== 'seller') return

    const statuses: Status[] = [
      {
        label: t('order.view.status_shipped'),
        value: 'shipped',
      },
      {
        label: t('order.view.status_succeeded'),
        value: 'succeeded',
      },
    ]

    return (
      <>
        <hr />
        <form onSubmit={ handleSubmit(onSubmit) }>
          <h3>{ t('order.view.status_update') }</h3>
          <div className="select">
            <Controller
              control={ control }
              name='status'
              render={ ({ field }) => (
                <Select
                  options={ statuses }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ statuses.find(t => t.value === status) }
                />
              ) }
            />
          </div>
          <p>
            <button className="full" disabled={ statusUpdateLoading }>{ t('order.view.status_save') }</button>
          </p>
        </form>
      </>
    )
  }, [control, handleSubmit, onSubmit, order?.role, order?.status, status, statusUpdateLoading, t])

  const [showSubscriptionCancelIntent, setShowSubscriptionCancelIntent] = useState<boolean>(false)
  const [showPlanSettings, setShowPlanSettings] = useState<boolean>(false)
  
  const { filters, endpointForExistingFilters } = useContext(SearchContext)
  const categoriesLink = useCallback((tagId: number) => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, '')

    return p + params
  }, [endpointForExistingFilters, filters])

  const items = useMemo(() => {
    return order?.units?.map((u) => {
      if (!u.userId || !u.id) return null

      // TODO: should only have one listing here
      const listingPrice = u.listings?.map(l => l.priceJpy).reduce((previous, current) => previous + current, 0) ?? 0

      // const itemName = (i18n.language === 'ja' && ui.item?.nameJa) ?? !ui.item?.nameEn ? ui.item?.nameJa : ui.item?.nameEn

      const itemName = u.items?.map(i => {
        return (i18n.language === 'ja' && i.nameJa) ?? !i.nameEn ? i.nameJa : i.nameEn
      }).join(' / ') ?? ''

      const condition = conditions.find(c => c.id === u.condition)
      const conditionName = t(`collections.conditions.long.${condition?.id ?? 0}`)

      const tags = u.items?.flatMap(i => i.tags)
      const tag = tags && tags.length > 0 ? tags.reduce((previous, current) => current.depth > previous.depth && current.type === 'Category' ? current : previous) : undefined

      const tagUi: JSX.Element = !tag
        ? <></>
        : <span key={ tag.id }>- <Link to={ categoriesLink(tag.id) }>{
            (i18n.language === 'ja' && tag.nameJa) ?? !tag.nameEn ? tag.nameJa : tag.nameEn
          }</Link></span>

      return (
        <li key={ u.id }>
          <span><Link to={ `/u/${u.userId}/units/${u.id}` }>{ itemName }</Link> ({ conditionName }){ tagUi }</span>
          <span>{ Math.round(listingPrice) }</span>
        </li>
      )
    })
  }, [categoriesLink, i18n.language, order?.units, t])

  const shippingMethod = useMemo(() => {
    if (order?.role !== 'seller') return

    return (
      <>
        <p>
          { t('order.view.shipping_method') }<strong>{ t(`account.sell.shipping_options.options.${order?.regionShippingService?.shippingService.label ?? ''}`) }</strong>
        </p>
      </>
    )
  }, [order?.regionShippingService?.shippingService.label, order?.role, t])

  const returnsPolicy = useMemo(() => {
    if (!order) return

    return <>
      <hr />
      <ul className="organised">
        <li>
          <span>返品条件</span>
          {
            order.returns > 0
              ? order.returns === 2
                ? 'お客様都合での返品可 (一部返金)'
                : 'お客様都合での返品可 (全額返金)'
              : 'お客様都合での返品不可'
          }
        </li>
        <li>
          <span></span>
          <Link to='/about/returns'>詳細を確認する</Link>
        </li>
      </ul>
    </>
  }, [order])

  const feeSummary = useMemo(() => {
    if (!order || order.role !== 'seller') return

    const referralSummary = order.orderReferrals && order.orderReferrals.length > 0
      ? <>
        <h3>{ t('order.view.referral.title') }</h3>
        <table>
          <thead>
            <tr>
              <td>{ t('order.view.referral.headings.user') }</td>
              <td>{ t('order.view.referral.headings.status') }</td>
            </tr>
          </thead>
          <tbody>
            {
              order.orderReferrals.map((r) => {
                return (
                  <tr key={ r.id }>
                    <td>
                      {
                        r.referral.user
                          ? <Link to={ `/u/${r.referral.userId}` }>{ r.referral.user?.displayName ?? t('cards.item.default_display_name') }</Link>
                          : '?'
                      }
                    </td>
                    <td>{ t(`order.view.referral.status.${r.status}`) }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </>
      : null

    return <>
      <hr />
      { referralSummary }
      { /* <p>この注文の販売手数料は{ Number(order.feeJpy) }円です。</p> */ }
    </>
  }, [order, t])

  const actionRequired = useMemo(() => order?.role === 'seller' && order?.status === 'succeeded', [order?.role, order?.status])

  const statusColor = useMemo(() => {
    if (order?.status === 'shipped' || order?.status === 'active') {
      return 'green'
    } else if (order?.status === 'succeeded' && order?.role === 'buyer') {
      return 'yellow'
    } else if (actionRequired || order?.status === 'incomplete') {
      return 'red'
    }

    return ''
  }, [actionRequired, order?.role, order?.status])

  const actionExplanation = useMemo(() => {
    if (!actionRequired) return

    return (
      <div className="attention">
        <p><strong>{ t('order.view.advice.ship.title') }</strong></p>
        <p>{ t('order.view.advice.ship.body1') }</p>
        <p>{ t('order.view.advice.ship.body2') }</p>
        <p>{ t('order.view.advice.ship.body3') }</p>
      </div>
    )
  }, [actionRequired, t])

  const newFeedbackCallback = useCallback((f: Feedback) => {
    if (!order) return

    const newOrder: Order = {
      ...order,
      feedbacks: [f]
    }

    setOrder(newOrder)
  }, [order])

  const newMessageCallback = useCallback((m: Message) => {
    if (!order) return

    const mMine = { ...m, isMe: true }

    const newOrder: Order = {
      ...order,
      messages: order.messages?.concat([mMine])
    }

    setOrder(newOrder)
  }, [order])

  const feedback = useMemo(() => {
    if (
      (order?.role === 'seller' && (!order.feedbacks || order.feedbacks.length < 1)) ||
      (order?.role === 'buyer' && order.status !== 'shipped' && order.status !== 'active') ||
      !order
    ) return

    return <><hr /><OrderFeedback order={ order } callback={ (f) => newFeedbackCallback(f) } /></>
  }, [newFeedbackCallback, order])

  if (!isAuthenticated) return <Navigate to='/register' />

  if (orderLoading) {
    return <div>
      <h1>{ t('order.view.loading') }</h1>
    </div>
  }

  if (!order) {
    return <GenericNotFound />
  }

  return (
    <div>
      {
        showSubscriptionCancelIntent &&
          <SubscribeCancelIntentModal
            order={ order }
            onClose={ (status) => {
              setShowSubscriptionCancelIntent(false)
              if (status !== order.status) {
                getOrder()
              }
            } }
          />
      }
      {
        showPlanSettings &&
          <SubscriptionPlanSettingsModal
            setShowSubscriptionCancelIntent={ setShowSubscriptionCancelIntent }
            onClose={ () => setShowPlanSettings(false) }
          />
      }
      {
        showOrderCompleteModal &&
          <UnitBuyCompleteModal onClose={ () => setShowOrderCompleteModal(false) } unit={ order?.units?.[0] } />
      }
      <div className="narrow">
        <p>
          {
            order.role === 'buyer'
              ? <>{ t('order.view.my_account') } &gt; <Link to="/account/buy">{ t('order.view.buying') }</Link></>
              : <>{ t('order.view.my_account') } &gt; <Link to="/account/sell">{ t('order.view.selling') }</Link></>
          }
        </p>
        <h2>{ t('order.view.title', { number: order.id }) }</h2>
        <ul className="organised">
          <li>
            <span>
              { t('order.view.date_created') }
            </span>
            <span>
              { order.createdAt ? new Date(order.createdAt).toISOString().split('T')[0] : '' }
            </span>
          </li>
          {/* <li>
            <span>
              { t('order.view.date_updated') }
            </span>
            <span>
              { order.updatedAt ? new Date(order.updatedAt).toISOString().split('T')[0] : '' }
            </span>
          </li> */}
          {
            order.status === 'active' &&
              <>
                <li>
                  <span>
                    次回決済日：
                  </span>
                  <span>
                      { order.expiryDate ? new Date(order.expiryDate).toISOString().split('T')[0] : '' }
                  </span>
                </li>
              </>
          }
          <li>
            <span>
              { t('order.view.status') }
            </span>
            <span>
              <strong className={ statusColor }>{
                order.role === 'buyer'
                  ? t(`account.order_status_buyer.${order.status}`)
                  : t(`account.order_status_seller.${order.status}`)
              }</strong>
              {
                order.expiryDate
                  ? '定期購入'
                  : ''
              }
            </span>
          </li>
          {
            order.status === 'active' &&
              <>
                <li>
                  <span></span>
                  <span>
                    <button className='tool small' onClick={ () => setShowPlanSettings(true) }>プランの設定</button>
                  </span>
                </li>
              </>
          }
          {
            order.status === 'cancelled' &&
              isSubscription
                ? <li>
                  <span></span>
                  <span>
                    <button className='tool small' onClick={ () => setShowSubscriptionCancelIntent(true) }>自動更新を有効にする</button>
                  </span>
                </li>
                : <></>
          }
        </ul>
        {
          order.status === 'incomplete'
            ? <p className='center'>
              <Link to={ `/subscriptions/${order.id}` } className='button full'>{ t('order.view.complete_payment') }</Link>
            </p>
            : <></>
        }
        { actionExplanation }
        { feedback }
        <hr />
        <h3>{ t('order.view.contents') }</h3>
        <ul className="editable">
          { items }
          <li>
            <span>{ t('order.view.shipping_price') }</span>
            <span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(order.shippingPriceJpy ?? 0) }) }</span>
          </li>
          <li>
            <span>{ t('order.view.total_price') }</span><span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(totalPrice) }) }</span>
          </li>
        </ul>
        <h3>{ t('order.view.address.title') }</h3>
        <ul className="organised">
          { country && <li><span>{ t('order.view.address.country') }</span><span>{ t(`account.countries.${country.value}`) }</span></li> }
          { order.address?.zipCode && <li><span>{ t('order.view.address.zip_code') }</span><span>{ order.address.zipCode }</span></li> }
          { order.address?.state && <li><span>{ t('order.view.address.address') }</span><span>{ order.address.state }</span></li> }
          { order.address?.addressLine3 && <li><span>{ !order.address.addressLine1 && !order.address.addressLine2 && t('order.view.address.address') }</span><span>{ order.address.addressLine3 }</span></li> }
          { order.address?.addressLine2 && <li><span>{ !order.address.addressLine1 && t('order.view.address.address') }</span><span>{ order.address.addressLine2 }</span></li> }
          { order.address?.addressLine1 && <li><span></span><span>{ order.address.addressLine1 }</span></li> }
          { order.address?.name && <li><span>{ t('order.view.address.name') }</span><span>{ order.address.name }</span></li> }
          { order.address?.telephone && <li><span>{ t('order.view.address.phone') }</span><span>{ order.address.telephone }</span></li> }
        </ul>
        { shippingMethod }
        { returnsPolicy }
        { updateOrder }
        <hr />
        <OrderMessages orderId={ order.id ?? 0 } orderStatus={ order.status } messages={ order.messages } callback={ (m) => newMessageCallback(m) } role={ order.role ?? '' } />
        { support }
        { feeSummary }
      </div>
    </div>
  )
}

export { Orders }
