import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Pagination } from 'swiper'
import { useMemo } from 'react'
import clsx from 'clsx'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const ImageCarouselMobile = ({ imagesSorted, workingImage }) => {
  const imagesForCarousel = useMemo(() => {
    return imagesSorted?.map((im) => {
      if (!im) return null

      return <SwiperSlide key={ im.id }>
        <img
          data-src={ im.imageUrl }
          className={ clsx({ selected: im.id === workingImage?.id }, 'swiper-lazy') }
        />
        <div className='swiper-lazy-preloader'></div>
      </SwiperSlide>
    })
  }, [imagesSorted, workingImage?.id])

  if (!imagesForCarousel) return null

  return <Swiper
    className={ 'main' }
    modules={ [Lazy, Pagination] }
    slidesPerView={ 1 }
    simulateTouch={ true }
    pagination={ true }
    preloadImages={ false }
    watchSlidesProgress={ true }
    lazy={
      {
        enabled: true,
        loadPrevNext: true,
        loadPrevNextAmount: 1,
      }
    }
  >
    { imagesForCarousel }
  </Swiper>
}

export { ImageCarouselMobile }
