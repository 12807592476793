import { useCallback, useEffect, useMemo, useState, lazy } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Image } from '../types'
import clsx from 'clsx'

import { ImageCarouselDesktop } from './image_carousel_desktop'
import { ImageCarouselMobile } from './image_carousel_mobile'

const isTouchDevice = ('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  ((navigator as any).msMaxTouchPoints > 0)

type Props = Readonly<{
  images?: Image[]
  resolution: string
  link?: string
  navigationStyle: string
  carousel?: boolean
}>

const ItemImages = ({ carousel, images, resolution, link, navigationStyle }: Props) => {
  const { t } = useTranslation()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [workingImage, setWorkingImage] = useState<Image|undefined>()

  const imagesSorted = useMemo(() => {
    if (!images || images.length === 0) return

    return images.sort((a, b) => a.id - b.id)
  }, [images])

  useEffect(() => {
    setWorkingImage(imagesSorted?.[0])
  }, [imagesSorted])

  const toggleImage = useCallback((e: React.MouseEvent, direction = 'left', index = undefined) => {
    if (!imagesSorted || imagesSorted.length < 2) return

    e.preventDefault()

    const currentImageIndex = imagesSorted.findIndex(i => i.id === workingImage?.id)
    if (currentImageIndex === undefined) return

    if (typeof index === 'undefined') {
      index = direction === 'right'
        ? currentImageIndex === imagesSorted.length - 1 ? 0 : currentImageIndex + 1
        : currentImageIndex === 0 ? imagesSorted.length - 1 : currentImageIndex - 1
    }

    if (currentImageIndex === index) return

    setImageLoaded(false)
    setWorkingImage(imagesSorted[index])
  }, [workingImage, imagesSorted])

  const imageToggleButtonLeft = useMemo(() => {
    if (!imagesSorted || imagesSorted.length < 3) {
      if (imagesSorted?.length === 2) return <button className="selected change-image"></button>
      return
    }

    return <button onClick={ e => { toggleImage(e) } } className="selected change-image left"></button>
  }, [imagesSorted, toggleImage])

  const imageToggleButtonRight = useMemo(() => {
    if (!imagesSorted || imagesSorted.length < 2) return

    return <button onClick={ (e) => { toggleImage(e, 'right') } } className="selected change-image right"></button>
  }, [toggleImage, imagesSorted])

  const imageSrc = useMemo(() => {
    if (resolution === 'standard' && workingImage?.standard) {
      return workingImage.standard
    }

    return workingImage?.thumb2x ?? workingImage?.imageUrl
  }, [resolution, workingImage])

  const image = useMemo(() => {
    if (!workingImage) {
      return <span className='no-image'>{ t('sets.no_image') }</span>
    }

    return <img
      src={ imageSrc }
      alt=""
      width={ workingImage.width ?? '' }
      height={ workingImage.height ?? '' }
      onLoad={ () => setImageLoaded(true) }
      onClick={ (e) => { toggleImage(e, 'right') } }
    />
  }, [imageSrc, t, toggleImage, workingImage])

  useEffect(() => {
    setImageLoaded(!images || images?.length === 0)
  }, [images])

  const imageWithLink = useMemo(() => {
    if (!link) return <div>{ image }</div>

    return <Link to={ link }>{ image }</Link>
  }, [image, link])

  const imageNavigation = useMemo(() => {
    if (navigationStyle === 'none') return null

    if (!imagesSorted) return

    if (navigationStyle === 'image' && imagesSorted.length > 1) {
      return <ul className='navigation images'>
        {
          imagesSorted.map((im, index) => {
            return <li key={ im.id }>
              <img
                src={ im.thumb2x }
                onClick={ e => { toggleImage(e, '', index) } }
                className={ clsx({ selected: im.id === workingImage?.id }) }
              />
            </li>
          })
        }
      </ul>
    }

    if (navigationStyle === 'individual') {
      return <>
        {
          imagesSorted.map((im, index) => {
            return <button key={ index } onClick={ e => { toggleImage(e, '', index) } } className={ workingImage && clsx('change-image left', { selected: im.id === workingImage.id }) }></button>
          })
        }
      </>
    }

    return <>
      { imageToggleButtonLeft }
      { imageToggleButtonRight }
    </>
  }, [imageToggleButtonLeft, imageToggleButtonRight, imagesSorted, navigationStyle, toggleImage, workingImage])

  const carouselContainerDesktop = useMemo(() => {
    return <div className={ clsx('images desktop', { loaded: imageLoaded }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
      <ImageCarouselDesktop imagesSorted={ imagesSorted } workingImage={ workingImage } isTouchDevice={ isTouchDevice } toggleImage={ toggleImage } />
    </div>
  }, [imageLoaded, imagesSorted])

  const carouselContainerMobile = useMemo(() => {
    return <div className={ clsx('images mobile', { loaded: imageLoaded }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
      <ImageCarouselMobile imagesSorted={ imagesSorted } workingImage={ workingImage } />
    </div>
  }, [imageLoaded, imagesSorted])

  if (carousel) {
    return <>
      { carouselContainerDesktop }
      { carouselContainerMobile }
    </>
  }

  return (
    <>
      <div className={ clsx('images', 'loaded', { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
        { imageWithLink }
      </div>
      {
        imageNavigation
          ? <div className="flex image-navigation">
            { imageNavigation }
          </div>
          : null
      }
    </>
  )
}

export { ItemImages }
