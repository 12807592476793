import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Lazy, Thumbs, FreeMode } from 'swiper'
import { useMemo, useState } from 'react'
import clsx from 'clsx'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useViewportDimensions } from '../lib/use_viewport_dimensions'

const ImageCarouselDesktop = ({ imagesSorted, workingImage, isTouchDevice, toggleImage }) => {
  const imagesForCarousel = useMemo(() => {
    return imagesSorted?.map((im) => {
      if (!im) return null

      return <SwiperSlide key={ im.id }>
        <img
          data-src={ im.imageUrl }
          className={ clsx({ selected: im.id === workingImage?.id }, 'swiper-lazy') }
        />
        <div className='swiper-lazy-preloader'></div>
      </SwiperSlide>
    })
  }, [imagesSorted, workingImage?.id])

  const [thumbsDesktopSwiper, setThumbsDesktopSwiper] = useState<SwiperCore>()

  const viewportWidth = useViewportDimensions()[0]

  const thumbsCarouselDesktop = useMemo(() => {
    // if (viewportWidth < 750) return

    // return <Swiper
    //   className={ 'thumbs toptobottom' }
    //   onSwiper={ setThumbsDesktopSwiper }
    //   slidesPerView={ 5.5 }
    //   direction={ 'vertical' }
    //   spaceBetween={ 10 }
    //   // freeMode={ true }
    //   // navigation={ true }
    //   watchSlidesProgress={ true }
    //   modules={ [FreeMode, Navigation, Thumbs] }
    //   preloadImages={ false }
    // >
    //   {
    //     imagesSorted
    //       ? imagesSorted.map((im, index) => {
    //         if (!im) return null

    //         return <SwiperSlide key={ im.id }>
    //           <img
    //             src={ im.thumb2x }
    //             onClick={ e => { toggleImage(e, '', index) } }
    //             className={ clsx({ selected: im.id === workingImage?.id }) }
    //           />
    //         </SwiperSlide>
    //       })
    //       : []
    //   }
    // </Swiper>
  }, [imagesSorted, toggleImage, viewportWidth, workingImage?.id])

  if (!imagesForCarousel) return null

  return <>
    {/* { thumbsCarouselDesktop } */}
    <Swiper
      className={ 'main' }
      modules={ [Navigation, Thumbs, Lazy] }
      slidesPerView={ 1 }
      navigation={ !isTouchDevice }
      simulateTouch={ false }
      thumbs={ { swiper: thumbsDesktopSwiper } }
      preloadImages={ false }
      watchSlidesProgress={ true }
      lazy={
        {
          enabled: true,
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        }
      }
    >
      { imagesForCarousel }
    </Swiper>
  </>
}

export { ImageCarouselDesktop }
