import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import '../stylesheets/App.scss'

import { AuthContext } from '../contexts/auth_context'
import { SettingsContext } from '../contexts/settings_context'
import { BasketContext } from '../contexts/basket_context'
import { games } from '../lib/games'

import { UnitBasketModal } from './unit_basket_modal'
import { UnitBuyIntentModal } from './unit_buy_intent_modal'
import { UnitBuyLoadingModal } from './unit_buy_loading_modal'
import { UnitBuyCompleteModal } from './unit_buy_complete_modal'
import { UnitBuyErrorModal } from './unit_buy_error_modal'
import { useForm, useWatch } from 'react-hook-form'
import { SearchContext } from '../contexts/search_context'
import { FilterMenu } from './filter_menu'

const Header = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const pathSplit = location.pathname.split('/')
  const path = pathSplit.slice(1, 3).join('-') || 'top'
  const pathTop = pathSplit.length > 1 ? pathSplit[1] : ''

  const { isAuthenticated, currentUser } = useContext(AuthContext)
  const { endpointForExistingFilters, searchString, filters, filterMenuDisplayed, setFilterMenuDisplayed, searchboxPlaceholder, categoriesLink } = useContext(SearchContext)
  const { minimalNavigation, minimalUi, navigation } = useContext(SettingsContext)
  const { basketUnits, showBasket, setShowBasket, showOrderAddress, showOrderLoading, showOrderError, showOrderComplete } = useContext(BasketContext)

  // const toggleMenu = useCallback(() => {
  //   setFilterMenuDisplayed?.(!filterMenuDisplayed)
  // }, [filterMenuDisplayed, setFilterMenuDisplayed])

  const closeMenu = useCallback(() => {
    setFilterMenuDisplayed?.(false)
  }, [setFilterMenuDisplayed])

  const filterMenu = useMemo(() =>
    <nav className={ `nav-menu ${filterMenuDisplayed ? 'on' : 'off'}` }>
      <FilterMenu />
    </nav>
  , [filterMenuDisplayed])

  // const [didApplyFilters, setDidApplyFilters] = useState<boolean>(false)

  // const applyFilterQueryParams = useCallback(() => {
  //   if (!setFilters) return

  //   console.log('filterQueryString.cardTypes')
  //   console.log(filterQueryString.cardTypes)

  //   setFilters({
  //     ...filters,
  //     selectedView: filterQueryString.view,
  //     selectedCardTypes: filterQueryString.cardTypes,
  //     selectedEnergyTypes: filterQueryString.energyTypes,
  //     selectedRarities: filterQueryString.rarities,
  //     selectedConditions: filterQueryString.conditions,
  //     selectedStocks: filterQueryString.stocks,
  //     priceRange: {
  //       min: filterQueryString.minPrice,
  //       max: filterQueryString.maxPrice,
  //     }
  //   })

  //   setDidApplyFilters(true)
  // }, [filterQueryString, filters, setFilters])

  // useEffect(() => {
  //   if (didApplyFilters) return

  //   applyFilterQueryParams()
  // }, [didApplyFilters, applyFilterQueryParams])

  // const menuToggleListItem = useMemo(() =>
  //   <li className={ `menu ${filterMenuDisplayed ? 'on' : 'off'}` }>
  //     <button className="selected" onClick={ toggleMenu }>{ t('header.menu') }</button>
  //   </li>
  // , [filterMenuDisplayed, t, toggleMenu])

  const onCloseBuyComplete = useCallback(() => {
    setShowBasket?.(false)
  }, [setShowBasket])

  type SearchForm = Readonly<{
    search_mobile: string
    search_desktop: string
  }>

  const {
    register,
    control,
    setValue,
    reset,
  } = useForm<SearchForm>()

  const watchSearchDesktop = useWatch({
    control,
    name: 'search_desktop',
  })

  const watchSearchMobile = useWatch({
    control,
    name: 'search_mobile',
  })

  useEffect(() => {
    reset({
      search_desktop: searchString,
      search_mobile: searchString,
    })
  }, [reset, searchString])

  const [searchTextField, setSearchTextField] = useState<string>('')
  const [loadingSearchForm, setLoadingSearchForm] = useState<boolean>(false)

  useEffect(() => {
    if (loadingSearchForm) return

    setSearchTextField(watchSearchDesktop ?? '')
  }, [loadingSearchForm, watchSearchDesktop])

  useEffect(() => {
    if (loadingSearchForm) return

    setSearchTextField(watchSearchMobile ?? '')
  }, [loadingSearchForm, watchSearchMobile])

  const navigateSearch = useCallback((s: string) => {
    if (!endpointForExistingFilters) return

    setLoadingSearchForm(true)

    setValue('search_desktop', s)
    setValue('search_mobile', s)

    setLoadingSearchForm(false)

    const currentUrl = window.location.pathname + window.location.search

    const currentUrlPrefix = currentUrl.split('?')[0]

    let newUrl = endpointForExistingFilters(filters, s) ?? ''

    if (newUrl[0] === '?') {
      if (currentUrl.includes('/categories') || (currentUrl.includes('/u/') && !currentUrl.includes('/units/'))) {
        newUrl = `${currentUrlPrefix}${newUrl}`
      } else {
        newUrl = `/categories/${newUrl}`
      }
    }

    if (newUrl === currentUrl) return

    navigate(newUrl)
  }, [endpointForExistingFilters, filters, navigate, setValue])

  // const keyPressDelay = 0.3
  // const timer = useRef(setTimeout(() => {}, keyPressDelay * 1000))

  // const doSomething = useCallback((s: string) => {
  //   clearInterval(timer.current)

  //   if (!setSearchString || filterMenuDisplayed || s === searchString) return

  //   setSearchString(s)

  //   navigateSearch(s)
  // }, [filterMenuDisplayed, navigateSearch, searchString, setSearchString])

  // const setTimer = useCallback((s: string) => {
  //   clearInterval(timer.current)
  //   timer.current = setInterval(() => doSomething(s), keyPressDelay * 1000)
  // }, [doSomething])

  // useEffect(() => {
  //   const s = watchSearchDesktop ?? ''

  //   setTimer(s)
  // }, [filterMenuDisplayed, searchString, setTimer, watchSearchDesktop])

  // const searchFocus = useCallback(() => {
  //   if (filterMenuDisplayed) return

  //   if (!endpointForExistingFilters) return

  //   if (searchString.length < 1) return

  //   navigateSearch(searchString)
  // }, [endpointForExistingFilters, filterMenuDisplayed, navigateSearch, searchString])

  const searchSubmit = useCallback((e) => {
    e.preventDefault()

    if (searchTextField.length < 1) return

    navigateSearch(searchTextField)
  }, [navigateSearch, searchTextField])

  const searchClear = useCallback((e) => {
    e.preventDefault()

    if (searchString.length < 1) return

    if (searchTextField.length > 0) return

    navigateSearch('')
  }, [navigateSearch, searchString.length, searchTextField.length])

  const searchBlur = useCallback((e) => {
    e.preventDefault()

    if (!filterMenuDisplayed) return

    setLoadingSearchForm(true)

    setValue('search_desktop', searchTextField)
    setValue('search_mobile', searchTextField)

    setLoadingSearchForm(false)

    navigateSearch(searchTextField)
  }, [filterMenuDisplayed, navigateSearch, searchTextField, setValue])

  const myCollectionShown = useMemo(() => {
    if (!currentUser?.id) return false

    const currentUrl = location.pathname
    const myPath = `/u/${currentUser?.id}`

    return currentUrl === myPath || currentUrl.includes(`${myPath}/`) || currentUrl.includes(`${myPath}?`)
  }, [currentUser?.id, location.pathname])

  const topCategoriesLink = useMemo(() => {
    const game = games.find(g => g.default?.includes(i18n.language))?.id ?? games[0].id

    return categoriesLink?.(String(game)) ?? ''
  }, [categoriesLink, i18n.language])

  const collectionLink = useMemo(() => {
    const path = `/u/${currentUser ? currentUser.id : 'me'}`

    if (!endpointForExistingFilters) return path

    const newUrl = endpointForExistingFilters(filters, searchString) ?? ''

    return path + newUrl
  }, [currentUser, endpointForExistingFilters, filters, searchString])

  return (
    <header className={ clsx('App-header', { filterMenuDisplayed }) }>
      <div className={ clsx('nav-mobile', 'nav-container', path, pathTop, { myProfile: myCollectionShown }) }>
        <nav className={ clsx(['nav-top', isAuthenticated ? 'authenticated' : 'unauthenticated']) }>
          <h1 className='logo' onClick={ closeMenu }>
            <Link to= { topCategoriesLink }>{ t('header.home') }</Link>
          </h1>
          {
            navigation.style === 'search'
              ? <>
                <form onSubmit={ searchSubmit } onKeyUp={ searchClear } onBlur={ searchBlur } className={ clsx('search', { active: searchTextField.length > 0 && searchTextField !== searchString && !filterMenuDisplayed }) }>
                  {
                    /* <span className='filter water'>水</span>
                    <span className='filter fire'>炎</span>
                    <span className='filter rarity'>RR</span> */
                  }
                  <input { ...register('search_mobile') } name="search_mobile" type='text' placeholder={ searchboxPlaceholder } />
                  <button className='no-effect'></button>
                </form>
                <button className='filter no-effect' onClick={ _ => { setFilterMenuDisplayed?.(!filterMenuDisplayed) } }></button>
              </>
              : navigation.style === 'steps'
                ? <div className={ clsx('steps', { complete: navigation?.complete }) }>
                  <div className='outer'>
                    <div className='indicator'>
                      {
                        navigation?.currentStep !== navigation?.totalSteps
                          ? <div style={ { width: `${Math.ceil((navigation?.currentStep ?? 1) / (navigation?.totalSteps ?? 2) * 100)}%` } }></div>
                          : <div></div>
                      }
                    </div>
                    <div className='label'>
                      ステップ { navigation?.currentStep ?? 1 } / { navigation?.totalSteps ?? 2 }
                    </div>
                  </div>
                </div>
                : null
          }
          {
            minimalUi
              ? null
              : <>
                { isAuthenticated
                  // ? <ul>{ menuToggleListItem }</ul>
                  ? null
                  : (
                    <Link to='/register' className='no-decoration'>
                      <span className='button tool'>{ t('header.register') }</span>
                    </Link>
                    )
                }
              </>
          }
        </nav>
        { filterMenu }
        {
        minimalNavigation
          ? null
          : <nav className="nav-bottom">
            <ul>
              {
              /* <li className='categories'>
                <Link to={ tcgCategoriesLink }><span>{ t('header.tcg_categories') }</span></Link>
              </li> */
              }
              <li className='categories-all'>
                <Link to={ topCategoriesLink }><span>{ t('header.all_categories') }</span></Link>
              </li>
              {
                // isAuthenticated && currentUser
                //   ? <li className='collection'>
                //     <Link to={ collectionLink }><span>{ t('header.collection') }</span></Link>
                //   </li>
                //   : null
              }
              {
                // <li className='sell'>
                //   <Link to='/sell'><span>{ t('header.sell') }</span></Link>
                // </li>
              }
              <li className='basket'>
                <a onClick={ () => setShowBasket?.(true) }>
                  <span>{ t('header.bag') }</span>
                  {
                    basketUnits.length > 0
                      ? <span className='count'>{ basketUnits.reduce((sum, { quantity }) => sum + quantity, 0) }</span>
                      : null
                  }
                </a>
              </li>
              {
                isAuthenticated
                  ? <li className='account'>
                    <Link to='/account'><span>{ t('header.account') }</span></Link>
                  </li>
                  : null
              }
            </ul>
          </nav>
        }
      </div>
      <div className={ clsx('nav-desktop', 'nav-container', path, pathTop, { myProfile: myCollectionShown }) }>
        <nav className={ clsx(['nav-top', isAuthenticated ? 'authenticated' : 'unauthenticated']) }>
          <h1 className='logo'>
            <Link to={ topCategoriesLink }>{ t('header.home') }</Link>
          </h1>
          {
            minimalUi
              ? null
              : <>
                <ul>
                  {
                  /* <li className='categories icon'>
                    <Link to={ tcgCategoriesLink }>{ t('header.tcg_categories') }</Link>
                  </li> */
                  }
                  <li className='categories-all icon'>
                    <Link to={ topCategoriesLink }>{ t('header.all_categories') }</Link>
                  </li>
                  {
                    // isAuthenticated && currentUser
                    //   ? <li className='collection icon'>
                    //     <Link to={ collectionLink }>{ t('header.collection') }</Link>
                    //   </li>
                    //   : null
                  }
                </ul>
                <form onSubmit={ searchSubmit } onKeyUp={ searchClear } onBlur={ searchBlur } className={ clsx('search', { active: searchTextField.length > 0 && searchTextField !== searchString && !filterMenuDisplayed }) }>
                  {
                    /* <span className='filter water'>水</span>
                    <span className='filter fire'>炎</span>
                    <span className='filter rarity'>RR</span> */
                  }
                  <input { ...register('search_desktop') } name="search_desktop" type='text' placeholder={ searchboxPlaceholder } />
                  <button className='no-effect'></button>
                </form>
                <ul className='right'>
                  <li className='basket icon'>
                    <a onClick={ () => setShowBasket?.(true) }>
                      <span>{ t('header.bag') }</span>
                      {
                        basketUnits.length > 0
                          ? <span className='count'>{ basketUnits.reduce((sum, { quantity }) => sum + quantity, 0) }</span>
                          : null
                      }
                    </a>
                  </li>
                  { /* <li className='sell'>
                    <Link to='/sell' className={ clsx('button sell small', { lowlight: basketUnits.length > 0 }) }>{ t('header.sell') }</Link>
                  </li> */ }
                  {
                    isAuthenticated && currentUser
                      ? <li className='account icon'>
                        <Link to='/account'>{ t('header.account') }</Link>
                      </li>
                      : null
                  }
                  { isAuthenticated
                    // ? menuToggleListItem
                    ? null
                    : (
                      <li className='login'>
                        <Link to='/register' className='no-decoration'>
                          <span className='button tool'>{ t('header.register') }</span>
                        </Link>
                      </li>
                      )
                  }
                </ul>
              </>
          }
        </nav>
      </div>
      { showBasket && <UnitBasketModal /> }
      { showOrderAddress && <UnitBuyIntentModal /> }
      { showOrderLoading && <UnitBuyLoadingModal /> }
      { showOrderError && <UnitBuyErrorModal /> }
      { showOrderComplete && <UnitBuyCompleteModal onClose={ onCloseBuyComplete } /> }
    </header>
  )
}

export { Header }
