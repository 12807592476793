import { useEffect, useState, useCallback, useContext, useMemo } from 'react'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'

import { CardSingle } from './card_single'
import { CardsLoading } from './cards_loading'
import { SettingsContext } from '../contexts/settings_context'
import { tagsRepository } from '../repositories/tags_repository'
import { Filters, Item, Tag, TagGroup, Unit } from '../types'

import '../stylesheets/Sets.scss'
import spriteDefault from '../sprite-default.png'
import clsx from 'clsx'
import { ErrorsContext } from '../contexts/errors_context'
import { tagGroupsRepository } from '../repositories/tag_groups_repository'
import { ModalWrapper } from './modal_wrapper'
import { AuthContext } from '../contexts/auth_context'
import { SearchContext } from '../contexts/search_context'
import { SetsList } from './sets_list'
import { unitsRepository } from '../repositories/units_repository'
import { ListingSingle } from './listing_single'
import Select from 'react-select'
import { SelectedFilters } from './selected_filters'
import { games } from '../lib/games'

type SelectOption = Readonly<{
  value: string
  label: string
}>

const itemSorts: SelectOption[] = [
  {
    value: 'release_date_desc',
    label: '',
  },
  {
    value: 'release_date_asc',
    label: '',
  },
  {
    value: 'created_desc',
    label: '',
  },
  // {
  //   value: 'created_asc',
  //   label: '',
  // },
  {
    value: 'price_desc',
    label: '',
  },
  {
    value: 'price_asc',
    label: '',
  },
  {
    value: 'name_ja_asc',
    label: '',
  },
  {
    value: 'name_ja_desc',
    label: '',
  },
]

type FlavorText = Readonly<{
  id: number
  text: string
}>

const Categories = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { addError } = useContext(ErrorsContext)
  const { categoriesLink, filters, searchString, hasFilters, cachedTagRefs, setCachedTagRefs, filterMenuDisplayed, endpointForExistingFilters, setFilters } = useContext(SearchContext)
  const { isAuthenticated, currentUser, currentUserFollowing, setCurrentUserFollowing } = useContext(AuthContext)
  const { categoriesLayout } = useContext(SettingsContext)

  const path = useParams()
  const tagId = useMemo<number|undefined>(() => {
    const intId = parseInt(path.id ?? '')

    if (!isNaN(intId) && intId > 0) {
      return intId
    }

    // if game is selected, we need to use the game tag id
    if (filters.selectedGame) {
      const game = games.find(g => g.id === filters.selectedGame)
      if (game) return game.id
    }

    return games.find(g => g.default?.includes(i18n.language))?.id ?? games[0].id
  }, [filters.selectedGame, i18n.language, path.id])

  // category
  const [tagLoading, setTagLoading] = useState<boolean>(false)
  const [cardsLoading, setCardsLoading] = useState<boolean>(false)
  // const [cardsLoaded, setCardsLoaded] = useState<string|undefined>()
  const [tag, setTag] = useState<Tag>()

  // pagination
  const [currentItemPage, setCurrentItemPage] = useState<number>(1)
  const [totalItemPages, setTotalItemPages] = useState<number>(0)

  // child cards of this category
  const [childTagsLoading, setChildTagsLoading] = useState<boolean>(true)
  const [childTags, setChildTags] = useState<Tag[]>([])

  // select multiple cards at once
  // const [batchCondition, setBatchCondition] = useState<number>(0)
  // const [batchIsActive, setBatchIsActive] = useState<boolean>(false)
  // const [batchAllSelected, setBatchAllSelected] = useState<boolean>(false)
  // const [batchSelectedCards, setBatchSelectedCards] = useState<Item[]>([])

  // cards
  // can be items or listings
  const [items, setItems] = useState<Item[]>([])
  const [listings, setListings] = useState<Unit[]>([])

  // TODO: Is this constant?
  const cardSettings = useMemo(() => {
    return {
      conditionSelection: true,
      canCollect: true,
      imageResolution: '2x',
    }
  }, [])

  const [axiosCancelTokens, setAxiosCancelTokens] = useState<CancelTokenSource[]>([])

  const isPokemonCharacter = useMemo(() => {
    // below doesn't consider different forms (e.g. galarian) 😢
    // return (
    //   tag?.type === 'Character' &&
    //   tag?.parent?.slug === 'pokemon-monsters'
    // )

    return (
      tag?.type === 'Character'
    )
  }, [tag])

  const getChildren = useCallback((id: number|undefined, page = 1, axiosCancelToken) => {
    setChildTagsLoading(true)
    setChildTags([])

    let sort: string | undefined
    if (
      !id || // japanese top (tag not set)
      id === 4100 || // japanese top
      id === 1 || // japanese pokemon top
      id === 2150 || // engish top
      id === 1923 || // sword&shield jp
      id === 2164 // sword&shield en
    ) {
      sort = 'release_date_desc'
    }

    const game = games.find(g => g.default?.includes(i18n.language))?.id ?? games[0].id

    // get all children sets
    tagsRepository
      .index(
        {
          parentId: id ?? game,
          params: {
            page,
            mustHaveItems: !isPokemonCharacter,
            limit: 999,
            sort
          }
        }, axiosCancelToken.token)
      .then(({ tags }) => {
        setChildTags(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setChildTagsLoading(false)
      })
  }, [addError, i18n.language, isPokemonCharacter])

  useEffect(() => {
    const newTagsToAdd = childTags
      .filter(t => !cachedTagRefs.find(c => c.id === t.id))
      .map((tag) => {
        let title = tag.nameEn
        if (i18n.language === 'ja' && (tag.nameJaShort ?? tag.nameJa)) {
          title = tag.nameJaShort ?? tag.nameJa
        } else if (tag.nameEnShort) {
          title = tag.nameEnShort
        }

        return {
          id: tag.id,
          parentId: tag.parentId,
          nameShort: title ?? '',
          depth: tag.depth,
          childrenCount: tag.childrenCount,
          ordinality: tag.ordinality,
        }
      })

    if (newTagsToAdd.length === 0) return

    setCachedTagRefs?.(cachedTagRefs.concat(newTagsToAdd))
  }, [setCachedTagRefs, childTags, cachedTagRefs, i18n.language])

  const resetItems = useCallback(() => {
    for (const t of axiosCancelTokens) t.cancel()

    setCurrentItemPage(1)
    setTotalItemPages(0)
    setItems([])
    setListings([])
    setCardsLoading(true)
  }, [axiosCancelTokens])

  // useEffect(() => {
  //   resetItems()
  // }, [resetItems, searchString])

  const [followersCount, setFollowersCount] = useState<number>(0)
  const [followLoading, setFollowLoading] = useState<boolean>(false)

  // useEffect(() => {
  //   console.log('other trigger')

  //   if (pendingInitialFilterCategories.length > 0) return

  //   tagChangeEvent(tag)
  // }, [pendingInitialFilterCategories.length, tag, tagChangeEvent])

  const isFollowing = useMemo(() => {
    if (!currentUser || !tag) return

    return !!currentUserFollowing.find(t => t === tag.id)
  }, [currentUser, currentUserFollowing, tag])

  const findTopParent = useCallback((t: Tag) => {
    if (t.parent) {
      return findTopParent(t.parent)
    } else {
      return t
    }
  }, [])

  const categoriesForEndpoint = useCallback((tag?: Tag): number[] => {
    const deepestCategory = tag?.depth ?? 0

    const categories = [tag?.id ?? 0]
      .concat(filters.selectedCategories
        .filter(t => t.depth === deepestCategory && t.id !== tag?.id)
        .map(t => Number(t.id))
      )
      .filter(n => n > 0)

    return categories
  }, [filters.selectedCategories])

  const [sort, setSort] = useState<string>(itemSorts[0].value)

  const getItems = useCallback((tag: Tag|undefined, page = 1, axiosCancelToken) => {
    setCardsLoading(true)

    const game = games.find(g => g.default?.includes(i18n.language))?.id ?? games[0].id

    // default: selected tag id
    // then: tag id depends on language selection (for top level)
    // finally: subete/all tag id (4100)
    const id = tag ? tag.id : game ?? 4100

    tagsRepository
      .getItems(id, {
        sort,
        page,
        types: [],
        query: searchString,
        tagGroup1Ids: categoriesForEndpoint(tag),
        tagGroup2Ids: filters.selectedCardTypes,
        tagGroup3Ids: filters.selectedEnergyTypes,
        tagGroup4Ids: filters.selectedRarities,
        game: filters.selectedGame ? [filters.selectedGame] : undefined,
        sold: filters.selectedStocks.length === 1 ? filters.selectedStocks[0] : undefined,
        minPrice: filters.priceRange.min,
        maxPrice: filters.priceRange.max,
        conditions: filters.selectedConditions,
        userId: filters.selectedUserId,
      }, axiosCancelToken.token)
      .then(({ items, pagination }) => {
        setItems((prev) => prev.concat(items.filter((item) => !prev.find(p => p.id === item.id))))

        setTotalItemPages(pagination.totalPages)
        setCurrentItemPage(pagination.currentPage)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setCardsLoading(false)
        // setCardsLoaded('items')
      })
  }, [addError, categoriesForEndpoint, filters.priceRange.max, filters.priceRange.min, filters.selectedCardTypes, filters.selectedConditions, filters.selectedEnergyTypes, filters.selectedGame, filters.selectedRarities, filters.selectedStocks, filters.selectedUserId, i18n.language, searchString, sort])

  const getListings = useCallback((tag: Tag|undefined, page = 1, axiosCancelToken) => {
    setCardsLoading(true)

    unitsRepository
      .index({
        platform: 'holic',
        query: searchString,
        tagGroup1Ids: categoriesForEndpoint(tag),
        tagGroup2Ids: filters.selectedCardTypes,
        tagGroup3Ids: filters.selectedEnergyTypes,
        tagGroup4Ids: filters.selectedRarities,
        game: filters.selectedGame ? [filters.selectedGame] : undefined,
        sold: filters.selectedStocks.length === 1 ? filters.selectedStocks[0] : undefined,
        minPrice: filters.priceRange.min,
        maxPrice: filters.priceRange.max,
        conditions: filters.selectedConditions,
        userId: filters.selectedUserId,
        sort,
        page,
      }, axiosCancelToken.token)
      .then(({ listings, pagination }) => {
        setListings((prev) => prev.concat(listings.filter((listings) => !prev.find(p => p.id === listings.id))))

        setTotalItemPages(pagination.totalPages)
        setCurrentItemPage(pagination.currentPage)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setCardsLoading(false)
        // setCardsLoaded('listings')
      })
  }, [addError, categoriesForEndpoint, filters.priceRange.max, filters.priceRange.min, filters.selectedCardTypes, filters.selectedConditions, filters.selectedEnergyTypes, filters.selectedGame, filters.selectedRarities, filters.selectedStocks, filters.selectedUserId, searchString, sort])

  const [flavorTexts, setFlavorTexts] = useState<FlavorText[]>([])

  useEffect(() => {
    const seen = {}

    // filter out flavour texts only, in the language requested by the user
    const f = items
      .filter(i => {
        const t = i.itemDatas && i.itemDatas.length > 0 ? i.itemDatas[0].flavorText : null

        if (!t || t === '') return false

        const containsJapanese = t.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/)

        if (i18n.language === 'ja') return containsJapanese
        else return !containsJapanese
      })
      .map(i => {
        const t: FlavorText = {
          id: i.id,
          text: i.itemDatas?.[0].flavorText ?? '',
        }
        return t
      })
      .filter((t) => {
        // avoid duplicates
        const text = t.text ?? ''
        return seen[text] ? false : (seen[text] = true)
      })

    setFlavorTexts(f)
  }, [i18n.language, items])

  const handleLoadMore = useCallback((page) => {
    const newAxiosCancelToken = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

    if (filters.selectedView === 'items') {
      getItems(tag, page, newAxiosCancelToken)
    } else {
      getListings(tag, page, newAxiosCancelToken)
    }
  }, [filters.selectedView, getItems, getListings, setAxiosCancelTokens, tag])

  // const resetAxios = useCallback((axiosCancelTokens) => {
  //   for (const t of axiosCancelTokens) t.cancel()
  // }, [])

  // const getItemsOrListings = useCallback(() => {
  //   console.log('get items or listings')
  //   handleLoadMore(0)
  // }, [handleLoadMore])

  const [isTop, setIsTop] = useState<boolean>(false)

  const setTagToTop = useCallback(() => {
    if (isTop) return

    setIsTop(true)

    setTag(undefined)

    resetItems()

    const newAxiosCancelToken = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

    if (filters.selectedView === 'items') {
      getItems(undefined, 0, newAxiosCancelToken)
    } else {
      getListings(undefined, 0, newAxiosCancelToken)
    }

    const newAxiosCancelToken2 = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken2])

    getChildren(undefined, 0, newAxiosCancelToken2)
  }, [filters.selectedView, getChildren, getItems, getListings, isTop, resetItems])

  const setTagChanged = useCallback((t?: Tag) => {
    if (tag && tag.id === t?.id) return
    setIsTop(false)

    setTag(t)
    resetItems()

    const newAxiosCancelToken1 = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken1])

    if (filters.selectedView === 'items') {
      getItems(t, 0, newAxiosCancelToken1)
    } else {
      getListings(t, 0, newAxiosCancelToken1)
    }

    const newAxiosCancelToken2 = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken2])

    getChildren(t?.id, 0, newAxiosCancelToken2)
  }, [filters, getChildren, getItems, getListings, resetItems, tag])

  const [prevSort, setPrevSort] = useState<string>(itemSorts[0].value)

  useEffect(() => {
    if (!sort || prevSort === sort) return

    setPrevSort(sort)

    resetItems()

    const newAxiosCancelToken1 = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken1])

    if (filters.selectedView === 'items') {
      getItems(tag, 0, newAxiosCancelToken1)
    } else {
      getListings(tag, 0, newAxiosCancelToken1)
    }
  }, [filters.selectedView, getItems, getListings, prevSort, resetItems, sort, tag])

  const [initialFilters, setInitialFilters] = useState<Filters>(filters)
  const [initialSearchString, setInitialSearchString] = useState<string>(searchString)

  const filtersChanged = useMemo(() => JSON.stringify([initialFilters, initialSearchString]) !== JSON.stringify([filters, searchString]), [filters, initialFilters, initialSearchString, searchString])

  useEffect(() => {
    if (!filterMenuDisplayed && filtersChanged) {
      // need a new endpoint!
      resetItems()

      const newAxiosCancelToken1 = axios.CancelToken.source()
      setAxiosCancelTokens((t) => [...t, newAxiosCancelToken1])

      if (filters.selectedView === 'items') {
        getItems(tag, 0, newAxiosCancelToken1)
      } else {
        getListings(tag, 0, newAxiosCancelToken1)
      }

      // set filters
      setInitialFilters(filters)
      setInitialSearchString(searchString)
    }
  }, [filterMenuDisplayed, filters, filtersChanged, getItems, getListings, resetItems, searchString, tag])

  const [, setTagGroupLoading] = useState<boolean>(false)
  const [tagGroup, setTagGroup] = useState<TagGroup>()

  const getTagGroup = useCallback((id: number) => {
    setTagGroupLoading(true)

    tagGroupsRepository
      .get(id)
      .then(({ tagGroup }) => {
        setTagGroup(tagGroup)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setTagGroupLoading(false)
      })
  }, [addError])

  const getTag = useCallback((id: number) => {
    if (tag?.id === id) return

    setTagLoading(true)

    tagsRepository
      .get(id)
      .then(({ tag }) => {
        // resetItems()
        setTagChanged(tag)

        if (tag.tagGroupTags && tag.tagGroupTags.length > 0) {
          getTagGroup(tag.tagGroupTags[0].tagGroupId)
        }
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setTagLoading(false)
      })
  }, [addError, getTagGroup, setTagChanged, tag?.id])

  // useEffect(() => {
  //   if (filterMenuDisplayed) return

  //   console.log('filterMenuDisplayed trigger')
  //   // console.log(tagId)

  //   getItemsOrListings()
  // }, [filterMenuDisplayed, getItemsOrListings])

  // useEffect(() => {
  //   console.log('TEST:')
  //   console.log('tagId: ' + String(tag?.id))
  //   console.log('page: ' + String(page))

  //   if (!tag?.id) return

  //   console.log('tag id trigger:')
  //   console.log(tag?.id)
  //   // console.log(tagId)

  //   getItemsOrListings()

  //   const newAxiosCancelToken = axios.CancelToken.source()
  //   setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

  //   getChildren(tag?.id, page, newAxiosCancelToken)
  // }, [getChildren, getItemsOrListings, page, setAxiosCancelTokens, tag?.id])

  // useEffect(() => {
  //   console.log('test here:')
  //   console.log(tagId)
  //   console.log(page)
  //   console.log('-----')

  //   const newAxiosCancelToken = axios.CancelToken.source()
  //   setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

  //   getChildren(tagId, page, newAxiosCancelToken)
  // }, [page, getChildren, setAxiosCancelTokens, tagId])

  // useEffect(() => {
  //   if (!tag?.id && !isTopLevel) return

  //   console.log('test here:')
  //   console.log(tag?.id)
  //   console.log(page)
  //   console.log('-----')

  //   const newAxiosCancelToken = axios.CancelToken.source()
  //   setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

  //   getChildren(tag?.id, page, newAxiosCancelToken)
  // }, [getChildren, isTopLevel, page, setAxiosCancelTokens, tag?.id])

  useEffect(() => {
    // console.log('main useEffect trigger')

    if (tag && tag.id === tagId) return

    // console.log('do something')

    if (tagId) {
      getTag(tagId)
      setIsTop(false)
    } else if (!isTop) {
      setTagToTop()
    }
  }, [getTag, isTop, setTagToTop, tag, tagId])

  useEffect(() => {
    setFollowersCount(tag?.usersCount ?? 0)
  }, [tag?.usersCount])

  const parentForTag = useCallback((tag: Tag, parents: Tag[]) => {
    return parents.find(t => t.id === tag.parentId)
  }, [])

  const parentTags = useMemo(() => {
    if (!tag?.parents) return []

    tag.parents.find(t => t.parentId === t.id)

    const parents: Tag[] = []

    let workingTag: Tag|undefined = tag

    while (workingTag) {
      workingTag = parentForTag(workingTag, tag.parents)
      if (workingTag) parents.unshift(workingTag)
    }

    return parents
  }, [parentForTag, tag])

  useEffect(() => {
    if (!tag) return

    const series = parentTags.filter(c => c.depth !== 0).map(c => c.nameJa ?? '').join(' > ')

    let title = tag.nameJa ? tag.nameJa : tag.nameEn ?? ''

    title += ': ' + series

    document.title = title + ' • HOLIC'
  }, [parentTags, tag])

  const breadcrumb = useMemo(() => {
    if (!categoriesLink || parentTags.length === 0) return null

    const lang = t('sets.breadcrumb-' + i18n.language)

    if (!tag) return <p className='breadcrumbs'><strong>{ lang }</strong></p>

    const parents = parentTags.map((t) => {
      let title = t.nameEn
      if (i18n.language === 'ja' && (t.nameJaShort ?? t.nameJa)) {
        title = t.nameJaShort ?? t.nameJa
      } else if (t.nameEnShort) {
        title = t.nameEnShort
      }

      return <Link key={ t.id } to={ categoriesLink(String(t.id), 0) }>{ title }</Link>
    })

    let titleThisTag = tag.nameEn
    if (i18n.language === 'ja' && (tag.nameJaShort ?? tag.nameJa)) {
      titleThisTag = tag.nameJaShort ?? tag.nameJa
    } else if (tag.nameEnShort) {
      titleThisTag = tag.nameEnShort
    }

    return (
      <p className='breadcrumbs'>
        { parents }<strong>{ titleThisTag }</strong>
      </p>
    )
  }, [t, tag, parentTags, i18n.language, categoriesLink])

  // const toggleLayoutButton = useMemo(() => {
  //   if (childTags.length === 0) return null

  //   return (
  //     <p>
  //       <button
  //         className="tool"
  //         onClick={ () => { setCategoriesLayout?.(categoriesLayout === 'panels' ? 'list' : 'panels') } }
  //       >{
  //           categoriesLayout === 'panels'
  //             ? t('sets.show_list')
  //             : t('sets.show_tiles')
  //         }
  //       </button>
  //     </p>
  //   )
  // }, [childTags.length, setCategoriesLayout, t, categoriesLayout])

  const itemsListItems = useMemo(() => {
    return items.map((item) => {
      return <CardSingle key={ item.id } item={ item } cardSettings={ cardSettings } />
    })
  }, [items, cardSettings])

  const listingsListListings = useMemo(() => {
    return listings.map(listing => {
      if (!listing.items) return null

      return <ListingSingle key={ listing.id } editing={ false } items={ listing.items } unit={ listing } />
    })
  }, [listings])

  const hasMoreItems = useMemo(() => {
    return totalItemPages > currentItemPage
  }, [currentItemPage, totalItemPages])

  const itemsList = useMemo(() => {
    return (
      <InfiniteScroll
        pageStart={ currentItemPage }
        loadMore={ handleLoadMore }
        hasMore={ hasMoreItems }
        className={ 'cards-list' }
        element={ 'ul' }
        initialLoad={ false }
      >
        { itemsListItems }
      </InfiniteScroll>
    )
  }, [currentItemPage, handleLoadMore, hasMoreItems, itemsListItems])

  const listingsList = useMemo(() => {
    return (
      <InfiniteScroll
        pageStart={ currentItemPage }
        loadMore={ handleLoadMore }
        hasMore={ hasMoreItems }
        className={ 'listings' }
        element={ 'ul' }
        initialLoad={ false }
      >
        { listingsListListings }
      </InfiniteScroll>
    )
  }, [currentItemPage, handleLoadMore, hasMoreItems, listingsListListings])

  const sortMenu = useMemo(() => {
    const optionsTranslated = itemSorts.map((s) => {
      return {
        ...s,
        label: t(`collections.view.layout.${s.value}`) ?? '',
      }
    })

    return <Select
      options={ optionsTranslated }
      onChange={ (p) => setSort(p?.value ?? '') }
      value={ optionsTranslated.find(t => t.value === sort) }
      placeholder={ t('defaults.sort') }
      isClearable={ false }
      styles={ {
        menu: (styles) => {
          return {
            ...styles,
            zIndex: 10,
          }
        }
      } }
    />
  }, [sort, t])

  const navigate = useNavigate()

  const clearFilterCallback = useCallback((f: Filters, newSearchString?: string) => {
    if (!endpointForExistingFilters || !setFilters) return

    const filters = endpointForExistingFilters(f, newSearchString ?? searchString)

    const route = '/categories' + (tag?.id ? `/${String(tag?.id)}` : '')

    navigate(route + filters)

    setFilters(f)
  }, [endpointForExistingFilters, navigate, searchString, setFilters, tag?.id])

  const selectedFiltersUi = useMemo(() => {
    if (!hasFilters) return null

    return <SelectedFilters filters={ filters } searchString={ searchString } hide={ [] } callback={ clearFilterCallback } />
  }, [clearFilterCallback, filters, hasFilters, searchString])

  const cards = useMemo(() => {
    if (tagLoading) {
      if (childTagsLoading) return null

      return <p>{ t('sets.Loading') }</p>
    }

    const loadingCards = cardsLoading ? <CardsLoading number={ initialFilters.selectedView === 'listings' ? 8 : 6 } style={ initialFilters.selectedView } /> : null

    if (items.length === 0 && listings.length === 0 && cardsLoading) {
      return loadingCards
    }

    // if (!tag) return null

    const noCards = initialFilters.selectedView === 'listings'
      ? !cardsLoading && listings.length === 0 ? <p>{ t('sets.no_cards') }</p> : null
      : !cardsLoading && items.length === 0 ? <p>{ t('sets.no_cards') }</p> : null

    // if (loadingCards) {
    //   return <div>
    //     <div className="toolbar">
    //       { /* { batchActions } */ }
    //     </div>
    //     { loadingCards }
    //   </div>
    // }

    return (
      <div>
        { selectedFiltersUi }
        <div className='toolbar'>
          { sortMenu }
        </div>
        {
          initialFilters.selectedView === 'listings'
            ? listingsList
            : itemsList
        }
        { loadingCards }
        { noCards }
      </div>
    )
  }, [tagLoading, cardsLoading, initialFilters.selectedView, items.length, listings.length, t, selectedFiltersUi, sortMenu, listingsList, itemsList, childTagsLoading])

  const tagHeaderCategory = useMemo(() => {
    return <>
      { breadcrumb }
    </>
  }, [breadcrumb])

  const taxonomy = useMemo(() => {
    if (tag && tagGroup) {
      const parentIds = tagGroup.tagGroupTags.filter(tg => tg.tagId === tag.id && tg.parentTagId).map(mainTagGroup => {
        return mainTagGroup.parentTagId
      })

      const next = tagGroup.tagGroupTags.filter(tg => tg.parentTagId === tag.id)
      const last = next.length === 1 ? tagGroup.tagGroupTags.filter(tg => tg.parentTagId === next[0].tagId) : []

      // safe to assume there is only ever one previous/first pokemon
      const previous = parentIds.length > 0 ? [tagGroup.tagGroupTags.find(tg => parentIds.find(id => tg.tagId === id))] : []
      const first = previous.length > 0 && previous[0]?.parentTagId ? [tagGroup.tagGroupTags.find(tg => tg.tagId === previous[0]?.parentTagId)] : []

      return {
        first,
        previous,
        next,
        last,
      }
    }

    return {
      previous: [],
      next: [],
    }
  }, [tag, tagGroup])

  const [formTags, setFormTags] = useState<Tag[]>([])

  useEffect(() => {
    if (!tag || !isPokemonCharacter) return

    let basePokemonId = tag.id
    if (tag.parent && tag.parent.id !== 6) {
      basePokemonId = tag.parent.id
    }

    if (!tagGroup) return

    const f = tagGroup.tagGroupTags.filter(tgt => tgt.tagId === basePokemonId || tgt.tag.parentId === basePokemonId).map(tgt => tgt.tag)

    setFormTags(f)
  }, [childTags, isPokemonCharacter, tag, tagGroup])

  type Evolution = Readonly<{
    id: number
    name: string
    sprite: string
  }>

  const [showEvolutions, setShowEvolutions] = useState<Evolution[]>([])

  const evolutionGroup = useCallback((g) => {
    if (!g) return

    const evolutionSprite = g[Math.floor(Math.random() * g.length)]?.tag?.images.find(i => i.sprite)

    const image = evolutionSprite
      ? evolutionSprite.imageUrl
      : spriteDefault

    if (g.length > 1) {
      const evolutions: Evolution[] = g.map((t) => {
        const name = (i18n.language === 'ja' && t.tag?.nameJa) || !t.tag?.nameEn ? t.tag?.nameJa : t.tag?.nameEn
        const im = t?.tag?.images.find(i => i.sprite)

        return {
          id: t?.tag?.id,
          name: name,
          sprite: im ? im.imageUrl : spriteDefault,
        }
      })

      return <>
        <span onClick={ _ => setShowEvolutions(evolutions) } className="multiple"><img src={ image } /> { g.length } evolutions</span>
      </>
    }

    if (g.length === 1) {
      if (!categoriesLink) return

      const t = g[0].tag
      const title = (i18n.language === 'ja' && t.nameJa) || !t.nameEn ? t.nameJa : t.nameEn

      return <Link to={ categoriesLink(String(t?.id)) }><img src={ image } /> { title }</Link>
    }

    return null
  }, [categoriesLink, i18n.language])

  const firstEvolution = useMemo(() => {
    return evolutionGroup(taxonomy.first)
  }, [evolutionGroup, taxonomy.first])

  const previousEvolution = useMemo(() => {
    return evolutionGroup(taxonomy.previous)
  }, [evolutionGroup, taxonomy.previous])

  const nextEvolution = useMemo(() => {
    return evolutionGroup(taxonomy.next)
  }, [evolutionGroup, taxonomy.next])

  const lastEvolution = useMemo(() => {
    return evolutionGroup(taxonomy.last)
  }, [evolutionGroup, taxonomy.last])

  const clickFollow = useCallback(() => {
    if (!tag || followLoading) return

    if (!isAuthenticated) {
      alert('Please log in')
      return
    }

    setFollowLoading(true)

    if (isFollowing) {
      // unfollow
      tagsRepository
        .unfollow(tag.id)
        .then(() => {
          setFollowersCount(f => f - 1)
          setCurrentUserFollowing?.(currentUserFollowing.filter(t => t !== tag.id))
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setFollowLoading(false)
        })
    } else {
      // follow
      tagsRepository
        .follow(tag.id)
        .then(() => {
          setFollowersCount(f => f + 1)
          setCurrentUserFollowing?.(currentUserFollowing.concat([tag.id]))
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setFollowLoading(false)
        })
    }
  }, [addError, currentUserFollowing, followLoading, isAuthenticated, isFollowing, setCurrentUserFollowing, tag])

  const evolutionsModal = useMemo(() => {
    if (!categoriesLink) return

    if (showEvolutions.length === 0) return

    return <ModalWrapper>
      <h1>Evolutions</h1>
      <ul>
        {
          showEvolutions.map((t) => {
            return <li key={ t.id } onClick={ _ => setShowEvolutions([]) }><Link to={ categoriesLink(String(t.id)) }><img src={ t.sprite } /> { t.name }</Link></li>
          })
        }
      </ul>
      <p>
        <button className="full" onClick={ _ => setShowEvolutions([]) }>
          Close
        </button>
      </p>
    </ModalWrapper>
  }, [categoriesLink, showEvolutions])

  const evolutionsList = useMemo(() => {
    return <div className="evolutions-outer">
      <div className="evolutions previous">
        {
          firstEvolution
            ? <div className='evolution previous first'>{ firstEvolution }</div>
            : null
        }
        {
          previousEvolution
            ? <div className={ `evolution previous ${!firstEvolution ? 'first' : ''}` }>{ previousEvolution }</div>
            : null
        }
      </div>
      <div className="evolutions next">
        {
          nextEvolution
            ? <div className={ `evolution next ${!lastEvolution ? 'last' : ''}` }>{ nextEvolution }</div>
            : null
        }
        {
          lastEvolution
            ? <div className='evolution next last'>{ lastEvolution }</div>
            : null
        }
      </div>
    </div>
  }, [firstEvolution, lastEvolution, nextEvolution, previousEvolution])

  const formsList = useMemo(() => {
    if (!categoriesLink) return

    if (formTags.length <= 1 || !tag) return

    const nameShortest = (formTags.map(t => i18n.language === 'ja' ? t.nameJa : t.nameEn).reduce((a, b) => (a ? a.length : 0) <= (b ? b.length : 0) ? a : b)) ?? ''

    return <ul className='forms'>
      {
        formTags.map((t) => {
          const selected = t.id === tag.id

          let titleForm = (i18n.language === 'ja' && t.nameJa) ?? !t.nameEn ? t.nameJa : t.nameEn
          titleForm = titleForm?.replace(nameShortest, '')

          if (!titleForm || titleForm.length < 1) {
            titleForm = 'Standard'
          }

          return <li key={ t.id } className={ selected ? 'selected' : '' }>
            {
              !selected
                ? <Link to={ categoriesLink(String(t.id)) }>{ titleForm }</Link>
                : <span>{ titleForm }</span>
            }
          </li>
        })
      }
    </ul>
  }, [categoriesLink, formTags, i18n.language, tag])

  const pokemonStats = useMemo(() => {
    if (!tag) return

    const tagData = tag.tagData?.[0]

    let height: string|undefined
    let weight: string|undefined

    height = tagData?.heightEn ? `${tagData?.heightEn}"` : undefined
    weight = tagData?.weightEn ? tagData?.weightEn < 1 ? `${tagData?.weightEn}lbs.` : `${Math.round(tagData?.weightEn)}lbs.` : undefined

    if (i18n.language === 'ja' || !height) {
      height = tagData?.heightJa ? tagData?.heightJa < 1 ? `${tagData?.heightJa * 100}cm` : `${tagData?.heightJa}m` : height
    }
    if (i18n.language === 'ja' || !weight) {
      weight = tagData?.weightJa ? tagData?.weightJa < 1 ? `${tagData?.weightJa * 1000}g` : `${tagData?.weightJa}kg` : weight
    }

    return [
      height,
      height && weight ? ', ' : '',
      weight,
    ]
  }, [i18n.language, tag])

  const flavortext = useMemo(() => {
    if (flavorTexts.length === 0) return
    return flavorTexts[Math.floor(Math.random() * flavorTexts.length)].text
  }, [flavorTexts])

  const tagImage = useMemo(() => {
    if (!tag) return

    const im = tag.images.filter(i => !i.sprite)?.[0]

    if (!im) return

    // console.log('hi there')

    return <img className='background-image' src={ im.imageUrl } />
  }, [tag])

  const changeGame = useCallback((selectedGame: number) => {
    if (!endpointForExistingFilters || !setFilters) return

    const newFilters = {
      ...initialFilters,
      selectedGame,
    }

    const filters = endpointForExistingFilters(newFilters, searchString)

    const route = `/categories/${String(selectedGame)}`

    navigate(route + filters)

    setFilters(newFilters)
  }, [endpointForExistingFilters, initialFilters, navigate, searchString, setFilters])

  const isTopLevel = useMemo(() => {
    return !tag || tag.depth === 0
  }, [tag])

  const gameSelect = useMemo(() => {
    // only if at the top level
    if (!isTopLevel) return

    return (
      <ul className='game-select'>
        { games.map(game => <>
          {
            !game.displayOnTopPage
              ? null
              : <li className={ clsx(game.name, { selected: tag?.id === game.id }) }>
                <a onClick={ () => changeGame(game.id) }>
                  <img src={ game.logo } />
                  <h3>{ t(`cards.game.${game.name}`) }</h3>
                </a>
              </li>
          }
        </>) }
      </ul>
    )
  }, [changeGame, isTopLevel, t, tag?.id])

  const tagHeaderPokemon = useMemo(() => {
    if (!tag) return

    const tagData = tag.tagData?.[0]

    const color = tagData?.color ? tagData?.color : 'white'

    let title = tag.nameEn ?? tag.nameJa
    let category = tagData?.categoryEn ?? tagData?.categoryJa

    if (i18n.language === 'ja') {
      title = tag.nameJa ?? title
      category = tagData?.categoryJa ?? category
    }

    const titleArray = title?.split(/(アローラ|ガラル|ヒスイ)/gi) ?? []

    return <>
      <div className={ `header-pokemon ${color}` }>
        { evolutionsModal }
        <div className='inner'>
          { tagImage }
          { evolutionsList }
          <div className="content">
            <h1>{ titleArray.map((t, k) => <span key={ `title-${k}` }>{ t }</span>) } <span className="number">#{ String(tag.ordinality).padStart(3, '0') }</span></h1>
            <h2>{ category }</h2>
            <h2>{ pokemonStats }</h2>
            <p className="follow-area">
              <button className="tool" onClick={ clickFollow }>{ isFollowing ? 'Following' : 'Follow' }</button><span className="button-count">{ followersCount }</span>
            </p>
            <p className="flavortext desktop">{ flavortext }</p>
          </div>
        </div>
        { formsList }
      </div>
      {
        flavortext
          ? <div className="header-pokemon-below">
            <p className="flavortext mobile">{ flavortext }</p>
          </div>
          : null
      }
    </>
  }, [tag, i18n.language, evolutionsModal, evolutionsList, pokemonStats, tagImage, clickFollow, isFollowing, followersCount, flavortext, formsList])

  const tagHeader = useMemo(() => {
    if (isPokemonCharacter) {
      // pokemon
      return tagHeaderPokemon
    }

    return tagHeaderCategory
  }, [isPokemonCharacter, tagHeaderCategory, tagHeaderPokemon])

  // // include language tiles in childTags
  // const tagsWithChildrenAndLanguage = useMemo(() => {
  //   if (!categoriesLink || (tag && !languageTags.find(t => t.id === tag.id))) return childTags

  //   const languageTagsForChildren: Tag[] = languageTags.filter(t => t.id !== categoryLanguage.id).map((tag) => {
  //     const imageUrl = tag.language === 'en'
  //       ? setEnImage
  //       : setJaImage

  //     return {
  //       id: tag.id,
  //       nameEn: t(`sets.tabtitle-${tag.language}`),
  //       nameJa: t(`sets.tabtitle-${tag.language}`),
  //       releaseDate: '',
  //       ordinality: 0,
  //       depth: 0,
  //       slug: '',
  //       childrenCount: 1,
  //       usersCount: 0,
  //       images: [{
  //         id: 0,
  //         imageUrl,
  //         thumb2x: imageUrl,
  //       }],
  //       type: 'Category',
  //     }
  //   })

  //   return [...childTags, ...languageTagsForChildren]
  // }, [categoriesLink, categoryLanguage, childTags, t, tag])

  const tagListUi = useMemo(() => {
    if (isPokemonCharacter || parentTags.length === 0) return null

    return (
      <div className={ categoriesLayout !== 'panels' && childTags.length ? 'narrow' : '' }>
        <div className={ clsx('sets-container', categoriesLayout) }>
          { /* toggleLayoutButton */ }
          <SetsList carousel={ true } tags={ childTags } />
        </div>
      </div>
    )
  }, [categoriesLayout, childTags, isPokemonCharacter, parentTags.length])

  return (
    <div className={ isPokemonCharacter ? 'pokemon' : '' }>
      { gameSelect }
      { tagHeader }
      { tagListUi }
      <div>
        { cards }
      </div>
    </div>
  )
}

export { Categories }
