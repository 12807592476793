import { useCallback, useContext } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { cardTypes } from '../lib/card_types'
import { energyTypes } from '../lib/energy_types'
import { rarities } from '../lib/rarities'
import { Filters } from '../types'
import { conditions } from '../lib/conditions'
import { stocks } from '../lib/stocks'
import { SearchContext } from '../contexts/search_context'
import { games } from '../lib/games'

type Props = Readonly<{
  callback: (f: Filters, newSearchString?: string) => void
  filters: Filters
  searchString: string
  hide: string[]
}>

const SelectedFilters = ({ callback, filters, searchString, hide }: Props) => {
  const { t } = useTranslation()

  const { defaultFilters, filterMenuDisplayed, setFilterMenuDisplayed } = useContext(SearchContext)

  const clearFilter = useCallback((filterType, value?) => {
    if (filterType === 'search') {
      return callback(filters, '')
    }

    if (filterType === 'all') {
      const newFilters = {
        ...defaultFilters,
        selectedView: filters.selectedView,
      }

      return callback(newFilters, '')
    }

    const newFilters = {
      ...filters,
      [filterType]: value === 'all' ? [] : filters[filterType].filter(f => f !== value),
    }

    callback(newFilters)
  }, [callback, defaultFilters, filters])

  const selectedGame = games.find(g => g.id === filters.selectedGame)

  const availableConditions = conditions.filter(c => c.displayDefault !== false)

  return <div className={ clsx('selected-filters', { filterMenuDisplayed }) }>
    <strong>現在の検索条件</strong>
    {
      searchString
        ? <span className='filter canclear search' onClick={ _ => clearFilter('search', 'all') }>{ searchString }</span>
        : null
    }
    {
      selectedGame
        ? <span className='filter game' onClick={ _ => setFilterMenuDisplayed?.(true) }>{ t(`cards.game.${selectedGame.name}`) }</span>
        : null
    }
    {
      filters.selectedCardTypes.length === cardTypes.length
        ? <span className={ clsx('filter canclear cardType all') } onClick={ _ => clearFilter('selectedCardTypes', 'all') }>{ t('cards.layouts.title') }：{ t('cards.filter_menu.all') }</span>
        : filters.selectedCardTypes.map((f) => {
          const r = cardTypes.find(t => t.id === f)

          if (!r) return null

          return <span key={ f } className='filter canclear cardType' onClick={ _ => clearFilter('selectedCardTypes', f) }>{ t(`cards.layouts.${r.name}`) }</span>
        })
    }
    {
      filters.selectedEnergyTypes.length === energyTypes.length
        ? <span className={ clsx('filter canclear energyType all') } onClick={ _ => clearFilter('selectedEnergyTypes', 'all') }>{ t('cards.types.title') }：{ t('cards.filter_menu.all') }</span>
        : filters.selectedEnergyTypes.map((f) => {
          const r = energyTypes.find(t => t.id === f)

          if (!r) return null

          return <span key={ f } className={ clsx('filter canclear energyType', r.name) } onClick={ _ => clearFilter('selectedEnergyTypes', f) }>{ t(`cards.types.${r.name}`) }</span>
        })
    }
    {
      filters.selectedRarities.length === rarities.length
        ? <span className={ clsx('filter canclear rarity all') } onClick={ _ => clearFilter('selectedRarities', 'all') }>{ t('cards.rarities.title') }：{ t('cards.filter_menu.all') }</span>
        : filters.selectedRarities.map((f) => {
          const r = rarities.find(t => t.id === f)

          if (!r) return null

          return <span key={ f } className={ clsx('filter canclear rarity', r.name) } onClick={ _ => clearFilter('selectedRarities', f) }>{ t(`cards.rarities.${r.name}`) }</span>
        })
    }
    {
      !!filters.priceRange.min || !!filters.priceRange.max
        ? <span className='filter canclear priceRange' onClick={ _ => clearFilter('priceRange', 'all') }>
          { filters.priceRange.min ?? 0 }
          {
            !filters.priceRange.max ? '円' : ''
          } ~ { filters.priceRange.max }
          {
            filters.priceRange.max ? '円' : ''
          }
        </span>
        : null
    }
    {
      filters.selectedConditions.length === availableConditions.length
        ? <span className={ clsx('filter canclear condition all') } onClick={ _ => clearFilter('selectedConditions', 'all') }>{ t('cards.filter_menu.titles.rank') }：{ t('cards.filter_menu.all') }</span>
        : filters.selectedConditions.map((f) => {
          return <span key={ f } className={ clsx('filter canclear condition', f) } onClick={ _ => clearFilter('selectedConditions', f) }>{ t(`collections.conditions.short.${f}`) }</span>
        })
    }
    {
      hide.includes('stocks')
        ? null
        : filters.selectedStocks.length === stocks.length
          ? <span className={ clsx('filter canclear stock all') } onClick={ _ => clearFilter('selectedStocks', 'all') }>{ t('card_stocks.title') }：{ t('cards.filter_menu.all') }</span>
          : filters.selectedStocks.map((f) => {
            return <span key={ f } className={ clsx('filter canclear stock', f) } onClick={ _ => clearFilter('selectedStocks', f) }>{ t(`card_stocks.${f}`) }</span>
          })
    }
    {
      // are any filters selected?
      // except game, which cannot be "reset"
      !!filters.priceRange.min ||
      !!filters.priceRange.max ||
      // !!filters.selectedGame ||
      filters.selectedCardTypes.length > 0 ||
      filters.selectedConditions.length > 0 ||
      filters.selectedEnergyTypes.length > 0 ||
      filters.selectedRarities.length > 0 ||
      filters.selectedStocks.length > 0 ||
      searchString !== ''
        ? <button onClick={ _ => clearFilter('all') } className='tool'>リセット</button>
        : null
    }
  </div>
}

export { SelectedFilters }
