import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BasketContext } from '../contexts/basket_context'
import { ModalWrapper } from './modal_wrapper'

const UnitBuyErrorModal = (): JSX.Element => {
  const { t } = useTranslation()
  const { closeOrderError } = useContext(BasketContext)

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('checkout.error.title') }</h1>
        <p>{ t('checkout.error.description1') }</p>
        <p>{ t('checkout.error.description2') }</p>
        <p>
          <button className='full' onClick={ closeOrderError }>{ t('checkout.error.button') }</button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { UnitBuyErrorModal }
