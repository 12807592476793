import { AxiosError } from 'axios'
import { useMemo, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { AuthContext } from '../contexts/auth_context'
import { sessionsRepository } from '../repositories/sessions_repository'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import queryString from 'query-string'
import { ErrorsContext } from '../contexts/errors_context'

const Login = () => {
  const location = useLocation()
  const { addError } = useContext(ErrorsContext)
  const qS = queryString.parse(location.search)

  const itemIdParam = qS.item_id as string
  const itemId = useMemo<number|undefined>(() => {
    if (!itemIdParam) return

    const id = parseInt(itemIdParam ?? '')
    if (id) return id
  }, [itemIdParam])

  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = useCallback(async (data) => {
    if (loading) return

    // TODO: Validation
    const params = {
      email: data.email,
      password: data.password,
      itemId,
    }

    setLoading(true)

    sessionsRepository
      .create(params)
      .then(() => {
        setIsAuthenticated?.(true)
        const storedLocation = sessionStorage.getItem('storedLocation')
        sessionStorage.removeItem('storedLocation')

        if (storedLocation) {
          navigate(storedLocation)
        }
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })

    // TODO: Implement following logic

    // return await axiosRailsApi.post('/session', params).then()
    //   .CustomRequest.req(
    //     this.props.errors,
    //     'POST',
    //     '/session',
    //     {
    //       email,
    //       password,
    //     },
    //   )
    //   .then(res => {
    //     if (this.props.collection.id && this.props.collection.result.length > 0) {
    //       // user added cards to a guest collection and then logged in
    //       // ask to keep or delete
    //       this.props.global_functions.callModal('collection_add_login', { collectionId: this.props.collection.id })
    //     }

    //     this.props.auth.handle(navigate, res, 'login', '/')
    //   })
    //   .catch(err => {})
  }, [loading, itemId, setIsAuthenticated, navigate, addError])

  const loginForm = useMemo(() => {
    return (
      <form onSubmit={ handleSubmit(onSubmit) }>
        <p>
          <label>{ t('login.email') }</label>
          <input type="email" className="full" { ...register('email') } autoFocus />
        </p>
        <p>
          <label>{ t('login.password') }</label>
          <input type="password" className="full" { ...register('password') } />
        </p>
        <p>
          <button className="full" disabled={ loading }>
            { t('login.button') }
          </button>
        </p>
      </form>
    )
  }, [handleSubmit, loading, onSubmit, register, t])

  // TODO: useReducer 等で状態を管理
  return isAuthenticated
    ? (
      <div>
        <h1>{ t('login.title_authenticated') }</h1>
      </div>
      )
    : (
      <div className="narrow">
        <h1>{ t('login.title') }</h1>
        { loginForm }
        <hr />
        <h3>{ t('login.register.title') }</h3>
        <p>
          <Link to="/register" className='button full white'>{ t('login.register.button') }</Link>
        </p>
      </div>
      )
}

export { Login }
