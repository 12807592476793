import clsx from 'clsx'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchContext } from '../contexts/search_context'
import { FilterPriceRange, Filters } from '../types'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { games } from '../lib/games'
import { views } from '../lib/views'
import { cardTypes } from '../lib/card_types'
import { energyTypes } from '../lib/energy_types'
import { rarities } from '../lib/rarities'
import { stocks } from '../lib/stocks'
import { useFilterQueryString } from '../hooks/use_filter_query_string'

const conditions: number[] = [90, 70, 50, 20, 10, 0]

type MenuHeaderProps = Readonly<{
  label: string
  menuTree: string[]
  depth: number
  backCallback: (string, number) => void
}>

const MenuHeader = ({ label, menuTree, depth, backCallback }: MenuHeaderProps) => {
  return <li className='header'>
    <span className='back' onClick={ _ => { backCallback(menuTree, depth - 1) } }>←</span>
    <span className='title'>{ label }</span>
  </li>
}

type MenuItemProps = Readonly<{
  id: string|number
  ordinality?: number
  label: string
  selected: boolean
  callback?: (number, boolean) => void
}>

const MenuItem = ({ id, ordinality, label, selected, callback }: MenuItemProps) => {
  return <li style={ { order: ordinality } } className={ clsx({ selected }) } onClick={ _ => { callback?.(id, !selected) } }>
    <span className='checkbox'></span>
    <span className='option'>{ label }</span>
  </li>
}

type SelectAllProps = Readonly<{
  selectCallback: () => void
}>

const SelectAll = ({ selectCallback }: SelectAllProps) => {
  const { t } = useTranslation()

  return <li className='selectall' onClick={ selectCallback }>
    <span>{ t('cards.filter_menu.select_all') }</span>
  </li>
}

type SubviewProps = Readonly<{
  filters: Filters
  setChangedFiltersCallback: (filters: Filters) => void
  toggleViewCallback: (arg0: string[], arg1: number) => void
  submenu: string[]
}>

// type CategorySubMenuProps = Readonly<{
//   filters: Filters
//   setChangedFiltersCallback: (filters: Filters) => void
//   setCategoriesPath: (arg0: TagRef, arg1: number) => void
//   getTags: (tagId: number) => void
//   loading: boolean
//   tag: TagRef
//   deepestSelectedCategoryDepth: number
//   parentTagSelected: boolean
// }>

// const mapTagToTagRef = (t: Tag, language: string): TagRef => {
//   return {
//     id: t.id,
//     parentId: t.parentId,
//     nameShort: TagTitle(t, language),
//     depth: t.depth,
//     childrenCount: t.childrenCount,
//     ordinality: t.ordinality,
//   }
// }

// const CategorySubMenu = ({ filters, setChangedFiltersCallback, setCategoriesPath, tag, deepestSelectedCategoryDepth, parentTagSelected, getTags, loading }: CategorySubMenuProps) => {
//   const { cachedTagRefs, setCachedTagRefs } = useContext(SearchContext)

//   const toggleOption = useCallback((id: number, select: boolean) => {
//     const workingTag = cachedTagRefs.find(t => t.id === id)

//     if (!workingTag) return

//     const tagAndParents = tagWithParents([workingTag], cachedTagRefs)

//     let newFilters = filters

//     if (!select) {
//       // user wants to deselect this tag

//       const thisTagWasSelected = filters.selectedCategories.find(t => t.id === workingTag.id)
//       const sameDepthSelected = filters.selectedCategories.find(t => t.id !== workingTag.id && t.depth === workingTag.depth)

//       const workingTagParent = tagAndParents.find(t => t.id === workingTag.parentId)

//       if (!thisTagWasSelected) {
//         // this tag was not selected, meaning their parent was selected
//         // 1. select this tag's parents (ignore this tag)
//         // 2. select everything on this level (ignore this tag)

//         if (!workingTag.parentId) return // should never happen

//         const thisTagParents = tagAndParents.filter(t => t.id !== workingTag.id)

//         const sameDepthTags = cachedTagRefs.filter(t => t.parentId === workingTag.parentId && t.id !== workingTag.id)

//         newFilters = {
//           ...filters,
//           selectedCategories: thisTagParents.concat(sameDepthTags),
//         }
//       } else if (!workingTagParent?.parentId && !sameDepthSelected) {
//         // this is a top level tag and nothing else on the same level was selected
//         // deselect everything

//         newFilters = {
//           ...filters,
//           selectedCategories: [],
//         }
//       } else if (!sameDepthSelected) {
//         // nothing else on this level was selected
//         // deselect everything

//         // note: this is a repeat of the above (obviously), but keeping it here just in case

//         newFilters = {
//           ...filters,
//           selectedCategories: [],
//         }
//       } else {
//         // at least one other tag on this level was selected
//         // deselect:
//         // 1. this tag
//         // 2. anything deeper than this tag (we don't need to care about children of other tags)
//         const newSelectedCategories = filters.selectedCategories.filter(t => t.id !== workingTag.id && t.depth <= workingTag.depth)

//         newFilters = {
//           ...filters,
//           selectedCategories: newSelectedCategories,
//         }
//       }

//       setChangedFiltersCallback(newFilters)
//     } else {
//       // add this tag to everything else at this depth
//       const thisDepthSelectedCategories = filters.selectedCategories.filter(t => t.depth === workingTag.depth && t.parentId === workingTag.parentId)

//       const thisDepthAllCategories = cachedTagRefs.filter(t => t.depth === workingTag.depth && t.parentId === workingTag.parentId)

//       const newSelectedTagsLength = thisDepthSelectedCategories.length + 1

//       if (newSelectedTagsLength === thisDepthAllCategories.length) {
//         // oh wait! all tags in this depth will be selected; we can just select the parents
//         const parentsOnly = tagAndParents.filter(t => t.id !== workingTag.id)

//         newFilters = {
//           ...filters,
//           selectedCategories: parentsOnly,
//         }
//       } else {
//         newFilters = {
//           ...filters,
//           selectedCategories: thisDepthSelectedCategories.concat(tagAndParents),
//         }
//       }
//     }

//     setChangedFiltersCallback(newFilters)
//   }, [cachedTagRefs, filters, setChangedFiltersCallback])

//   const childTags = cachedTagRefs.filter(t => t.parentId === tag.id)

//   useEffect(() => {
//     if ((childTags.length === 0 || childTags.length !== tag.childrenCount) && typeof tag.shouldGetChildren === 'undefined') {
//       const newCachedTags = cachedTagRefs
//         .filter(t => t.id !== tag.id)

//       const thisTagNoChildren: TagRef = {
//         ...tag,
//         shouldGetChildren: true,
//         childrenCount: 0,
//       }

//       newCachedTags.push(thisTagNoChildren)

//       setCachedTagRefs?.(newCachedTags)

//       getTags(tag.id)
//     }
//   }, [cachedTagRefs, childTags.length, getTags, setCachedTagRefs, tag])

//   const childTagsUi = useMemo(() => {
//     if (loading) return <li>Loading...</li>

//     if (!childTags || childTags.length === 0) return null

//     return childTags.map((t) => {
//       let allSelected = false

//       // should be selected if:
//       let selected = false
//       if (t.depth <= deepestSelectedCategoryDepth && filters.selectedCategories.find(c => c.id === t.id)) {
//         // A. this specific tag is included in filters.selectedCategories
//         selected = true

//         const selectedChildrenLength = filters.selectedCategories.filter(c => c.parentId === t.id).length
//         const totalChildrenLength = cachedTagRefs.filter(c => c.parentId === t.id).length

//         // allSelected is true if one of the following conditions are met:
//         // ALL children are selected (should not happen since usually the parent only will be selected if so)
//         // NO children are selected (the parent is selected, so all children are selected by default)
//         allSelected = selectedChildrenLength === totalChildrenLength || selectedChildrenLength === 0
//       } else if (t.depth > deepestSelectedCategoryDepth && parentTagSelected) {
//         // B. this tag is deeper than the deepest-selected tag and its parent tag is included in filters.selectedCategories
//         selected = true
//         allSelected = true
//       }

//       if (t.childrenCount > 0) {
//         return <li style={ { order: t.ordinality } } className={ clsx('choices-1', { selected }, { some: selected && !allSelected }) } key={ t.id }>
//           <span className='checkbox' onClick={ _ => { toggleOption(t.id, !selected) } }></span>
//           <span className='choice enabled' onClick={ _ => { toggleOption(t.id, !selected) } }>
//             <span>{ t.nameShort }</span>
//           </span>
//           <span className='next' onClick={ _ => { setCategoriesPath(t, tag.depth + 2) } }>▸</span>
//         </li>
//       }

//       return <MenuItem
//           ordinality= { t.ordinality }
//           key={ t.id }
//           id={ t.id }
//           label={ t.nameShort }
//           selected={ selected }
//           callback={ toggleOption }
//         />
//     })
//   }, [cachedTagRefs, childTags, deepestSelectedCategoryDepth, filters.selectedCategories, loading, parentTagSelected, setCategoriesPath, tag, toggleOption])

//   return <>
//     { childTagsUi }
//   </>
// }

// const tagWithParents = (tagsList: TagRef[], cachedTagRefs: TagRef[]): TagRef[] => {
//   const workingTag = cachedTagRefs.find(t => t.id === Number(tagsList[0].parentId))

//   if (!workingTag) {
//     return tagsList
//   }

//   tagsList.unshift(workingTag)

//   if (workingTag.parentId) {
//     return tagWithParents(tagsList, cachedTagRefs)
//   } else {
//     return tagsList
//   }
// }

// type CategorySubviewProps = SubviewProps & Readonly<{
//   getTags: (tagId: number) => void
//   getTag: (tagId: number) => void
//   loading: boolean
// }>

// const CategoryMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback, submenu, loading, getTag, getTags }: CategorySubviewProps) => {
//   const { t } = useTranslation()
//   const { deepestSelectedCategoryDepth, cachedTagRefs, setCachedTagRefs } = useContext(SearchContext)

//   const setCategoriesPath = useCallback((tag: TagRef, depth) => {
//     if (!submenu.includes(String(tag.id))) {
//       // switching to a new submenu
//       const parents = tagWithParents([tag], cachedTagRefs)
//       const newSubmenu = parents.map(t => String(t.id))
//       newSubmenu.unshift('category')

//       toggleViewCallback?.(newSubmenu, depth)
//     } else {
//       toggleViewCallback?.(submenu, depth)
//     }
//   }, [cachedTagRefs, submenu, toggleViewCallback])

//   useEffect(() => {
//     cachedTagRefs.forEach((tag) => {
//       if (tag.shouldGetChildren) {
//         // update shouldGetChildren to false
//         // update didgetchildren to true

//         const newCachedTags = cachedTagRefs
//           .filter(t => t.id !== tag.id)

//         const thisTag: TagRef = {
//           ...tag,
//           shouldGetChildren: false,
//           didGetChildren: true,
//         }

//         newCachedTags.push(thisTag)

//         setCachedTagRefs?.(newCachedTags)

//         getTags(tag.id)
//       }
//     })
//   }, [cachedTagRefs, getTags, setCachedTagRefs])

//   type ChildTags = Readonly<{
//     id: number
//     children: number
//   }>

//   useEffect(() => {
//     // cachedTagRefs updated
//     // update childrencount (if necessary)
//     let childrenTagCounts: ChildTags[] = []

//     cachedTagRefs.forEach((tag) => {
//       if (!tag.parentId) return

//       const parentTag = childrenTagCounts.find(t => t.id === tag.parentId)

//       if (!parentTag) {
//         childrenTagCounts.push({
//           id: tag.parentId,
//           children: 1,
//         })
//       } else {
//         childrenTagCounts = childrenTagCounts.filter(t => t.id !== tag.parentId).concat([{
//           id: tag.parentId,
//           children: parentTag.children + 1,
//         }])
//       }
//       // calculcate the amount of children for each
//       // if the value changed, update the parent's childrenCount
//     })

//     let didChange = false

//     const newCachedTags = cachedTagRefs.map(t => {
//       if (!t.shouldGetChildren && !t.didGetChildren) return t

//       const childrenCount = childrenTagCounts.find(c => c.id === t.id)?.children ?? 0

//       if (childrenCount !== t.childrenCount) {
//         didChange = true
//       }

//       return {
//         ...t,
//         childrenCount: childrenCount,
//       }
//     })

//     if (didChange) {
//       setCachedTagRefs?.(newCachedTags)
//     }
//   }, [cachedTagRefs, getTags, setCachedTagRefs])

//   const submenuForMapping = useMemo(() => {
//     if (submenu.length === 1) {
//       return submenu.concat(['1'])
//     }

//     // render only one of each depth
//     const depths = new Set()

//     const filteredSubmenu = submenu
//       .filter(s => {
//         const tagId = Number(s)

//         const tag = cachedTagRefs.find(t => t.id === tagId)
//         let tagDepth = tag?.depth

//         if (!tag) {
//           // if another cached tag has a parent which matches this id, create a space for this tag
//           const childTag = cachedTagRefs.find(t => t.parentId === tagId)

//           if (childTag) {
//             tagDepth = childTag.depth - 1
//           } else {
//             return false
//           }
//         }

//         const isNew = !depths.has(tagDepth)
//         depths.add(tagDepth)

//         if (isNew && !tag) {
//           getTag(tagId)
//         }

//         return isNew
//       })

//     return ['category'].concat(filteredSubmenu)
//   }, [cachedTagRefs, getTag, submenu])

//   const selectAll = useCallback((tag?: TagRef) => {
//     if (!tag) return

//     const selectedSiblings = filters.selectedCategories.filter(t => t.parentId === tag.id).length
//     const totalSiblings = cachedTagRefs.filter(t => t.parentId === tag.id).length

//     const tagIsSelectedAsParent = filters.selectedCategories.find(t => t.id === tag.id)

//     let newFilters: Filters

//     if (
//       // 1. nothing at all is selected
//       filters.selectedCategories.length === 0 ||
//       // 2. some but not all children are selected
//       (selectedSiblings > 0 && selectedSiblings !== totalSiblings) ||
//       // 3. this tag is not selected (as a parent)
//       //    ↳ means another category is selected
//       !tagIsSelectedAsParent
//     ) {
//       // simply select this tag (and its parents)
//       newFilters = {
//         ...filters,
//         selectedCategories: tagWithParents([tag], cachedTagRefs),
//       }
//     } else {
//       // must deselect all tags
//       // if we only deselect all of the children, the parent will remain selected, meaning the children tags are auto-selected
//       newFilters = {
//         ...filters,
//         selectedCategories: [],
//       }
//     }

//     setChangedFiltersCallback(newFilters)
//   }, [cachedTagRefs, filters, setChangedFiltersCallback])

//   const parentTagSelected = useMemo(() => filters.selectedCategories.length > 0, [filters.selectedCategories.length])

//   useEffect(() => {
//     if (loading) return

//     submenuForMapping.forEach((categoryString) => {
//       if (isNaN(+categoryString)) return
//       const tag: TagRef|undefined =
//         cachedTagRefs.find(t => t.id === Number(categoryString)) ??
//         filters.selectedCategories.find(t => t.id === Number(categoryString))

//       if (tag) return

//       getTag(Number(categoryString))
//     })
//   }, [cachedTagRefs, filters.selectedCategories, getTag, getTags, loading, submenuForMapping])

//   const deepestCategory = useMemo(() => {
//     if (!deepestSelectedCategoryDepth) return 0

//     return deepestSelectedCategoryDepth(filters)
//   }, [deepestSelectedCategoryDepth, filters])

//   const submenuUi = useMemo(() => {
//     let hasParent = parentTagSelected

//     return submenuForMapping.map((categoryString) => {
//       if (isNaN(+categoryString)) return null

//       const tag = cachedTagRefs.find(t => t.id === Number(categoryString))

//       // if (!tag) {
//       //   tag = filters.selectedCategories.find(t => t.id === Number(categoryString))
//       // }

//       if (!tag) {
//         return <ul key={ `category-${categoryString}` } className='filter-menu menu-sub'>
//           <li>Loading...</li>
//         </ul>
//       }

//       if (hasParent && deepestCategory >= tag?.depth) {
//         hasParent = !!filters.selectedCategories.find(c => c.id === tag?.id)
//       }

//       return <ul key={ `category-${categoryString}` } className='filter-menu menu-sub'>
//         <MenuHeader label={ tag.nameShort ?? '' } menuTree={ submenuForMapping } depth={ tag.depth + 1 } backCallback={ toggleViewCallback } />
//         {
//           tag.childrenCount > 0
//             ? <>
//               <li className='selectall' onClick={ _ => { selectAll(tag) } }>
//                 <span>{ t('cards.filter_menu.all') }</span>
//               </li>
//               <CategorySubMenu filters={ filters } setChangedFiltersCallback={ setChangedFiltersCallback } setCategoriesPath={ setCategoriesPath } tag={ tag } deepestSelectedCategoryDepth={ deepestCategory } parentTagSelected={ hasParent } getTags={ getTags } loading={ loading } />
//             </>
//             : null
//         }
//       </ul>
//     })
//   }, [cachedTagRefs, deepestCategory, filters, getTags, loading, parentTagSelected, selectAll, setCategoriesPath, setChangedFiltersCallback, submenuForMapping, t, toggleViewCallback])

//   return <>{ submenuUi }</>
// }

const GameMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const { t } = useTranslation()

  const toggleView = useCallback((e) => {
    const newFilters: Filters = {
      ...filters,
      selectedGame: e,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('cards.game.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    {
      games.map((e) => <MenuItem
        key={ e.id }
        id={ e.id }
        label={ t(`cards.game.${e.name}`) }
        selected={ e.id === filters.selectedGame }
        callback={ toggleView }
      />)
    }
  </ul>
}

const ViewMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const { t } = useTranslation()

  const toggleView = useCallback((e) => {
    const newFilters: Filters = {
      ...filters,
      selectedView: e,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('card_views.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    {
      views.map((e) => <MenuItem
        key={ e }
        id={ e }
        label={ t(`card_views.${e}`) }
        selected={ e === filters.selectedView }
        callback={ toggleView }
      />)
    }
  </ul>
}

const StockMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const workingMenu = 'selectedStocks'

  const { t } = useTranslation()
  const [selection, setSelection] = useState<string[]>(filters[workingMenu])

  const toggleOption = useCallback((id, select) => {
    setSelection(select ? selection.concat([id]) : selection.filter(e => e !== id))
  }, [selection])

  const selectAll = useCallback(() => {
    setSelection(selection.length === stocks.length ? [] : stocks)
  }, [selection.length])

  useEffect(() => {
    if (selection === filters[workingMenu]) return

    const newFilters: Filters = {
      ...filters,
      [workingMenu]: selection,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, selection, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('card_stocks.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    <SelectAll selectCallback={ selectAll } />
    {
      stocks.map((e) => <MenuItem
        key={ e }
        id={ e }
        label={ t(`card_stocks.${e}`) }
        selected={ !!selection.find(s => e === s) }
        callback={ toggleOption }
      />)
    }
  </ul>
}

const CardTypeMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const workingMenu = 'selectedCardTypes'

  const { t } = useTranslation()
  const [selection, setSelection] = useState<number[]>(filters[workingMenu])

  const toggleOption = useCallback((id, select) => {
    setSelection(select ? selection.concat([id]) : selection.filter(e => e !== id))
  }, [selection])

  const selectAll = useCallback(() => {
    setSelection(selection.length === cardTypes.length ? [] : cardTypes.map(e => e.id))
  }, [selection.length])

  useEffect(() => {
    if (selection === filters[workingMenu]) return

    const newFilters: Filters = {
      ...filters,
      [workingMenu]: selection,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, selection, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('cards.layouts.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    <SelectAll selectCallback={ selectAll } />
    {
      cardTypes.map((e) => <MenuItem
        key={ e.id }
        id={ e.id }
        label={ t(`cards.layouts.${e.name}`) }
        selected={ !!selection.find(s => e.id === s) }
        callback={ toggleOption }
      />)
    }
  </ul>
}

const EnergyTypeMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const workingMenu = 'selectedEnergyTypes'

  const { t } = useTranslation()
  const [selection, setSelection] = useState<number[]>(filters[workingMenu])

  const toggleOption = useCallback((id, select) => {
    setSelection(select ? selection.concat([id]) : selection.filter(e => e !== id))
  }, [selection])

  const selectAll = useCallback(() => {
    setSelection(selection.length === energyTypes.length ? [] : energyTypes.map(e => e.id))
  }, [selection.length])

  useEffect(() => {
    if (selection === filters[workingMenu]) return

    const newFilters: Filters = {
      ...filters,
      [workingMenu]: selection,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, selection, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('cards.types.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    <SelectAll selectCallback={ selectAll } />
    {
      energyTypes.map((e) => <MenuItem
        key={ e.id }
        id={ e.id }
        label={ t(`cards.types.${e.name}`) }
        selected={ !!selection.find(s => e.id === s) }
        callback={ toggleOption }
      />)
    }
  </ul>
}

const RarityMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const workingMenu = 'selectedRarities'

  const { t } = useTranslation()
  const [selection, setSelection] = useState<number[]>(filters[workingMenu])

  const toggleOption = useCallback((id, select) => {
    setSelection(select ? selection.concat([id]) : selection.filter(e => e !== id))
  }, [selection])

  const selectAll = useCallback(() => {
    setSelection(selection.length === rarities.length ? [] : rarities.map(e => e.id))
  }, [selection.length])

  useEffect(() => {
    if (selection === filters[workingMenu]) return

    const newFilters: Filters = {
      ...filters,
      [workingMenu]: selection,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, selection, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('cards.rarities.title') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    <SelectAll selectCallback={ selectAll } />
    {
      rarities.map((e) => <MenuItem
        key={ e.id }
        id={ e.id }
        label={ t(`cards.rarities.${e.name}`) }
        selected={ !!selection.find(s => e.id === s) }
        callback={ toggleOption }
      />)
    }
  </ul>
}

const PriceMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const { t } = useTranslation()

  const viewTree = useMemo(() => ['main'], [])
  const viewDepth = 1

  const {
    register,
    control,
    setValue,
  } = useForm<FilterPriceRange>({
    defaultValues: {
      min: filters.priceRange.min,
      max: filters.priceRange.max,
    },
  })

  const min = useWatch({
    control,
    name: 'min',
  })

  const max = useWatch({
    control,
    name: 'max',
  })

  useEffect(() => {
    if (min === filters.priceRange.min && max === filters.priceRange.max) return

    const newFilters: Filters = {
      ...filters,
      priceRange: {
        min,
        max,
      },
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, max, min, setChangedFiltersCallback])

  const goBack = useCallback(() => {
    if (min && max && Number(min) > Number(max)) {
      setValue('min', 0)
    }

    toggleViewCallback(viewTree, viewDepth - 1)
  }, [max, min, setValue, toggleViewCallback, viewTree])

  return <form className='filter-menu menu-sub'>
    <ul>
      <MenuHeader label={ t('cards.price_range.title') } menuTree= { viewTree } depth={ viewDepth } backCallback={ goBack } />
      <li className='input-menu'>
        <span>Min</span>
        <input type="number" step='1' min='0' max={ max } { ...register('min') } />
      </li>
      <li className='input-menu'>
        <span>Max</span>
        <input type="number" step='1' min={ min } { ...register('max') } />
      </li>
    </ul>
  </form>
}

const ConditionMenu = ({ filters, setChangedFiltersCallback, toggleViewCallback }: SubviewProps) => {
  const workingMenu = 'selectedConditions'

  const { t } = useTranslation()
  const [selection, setSelection] = useState<number[]>(filters[workingMenu])

  const toggleOption = useCallback((id, select) => {
    setSelection(select ? selection.concat([id]) : selection.filter(e => e !== id))
  }, [selection])

  const selectAll = useCallback(() => {
    setSelection(selection.length === conditions.length ? [] : conditions)
  }, [selection.length])

  useEffect(() => {
    if (selection === filters[workingMenu]) return

    const newFilters: Filters = {
      ...filters,
      [workingMenu]: selection,
    }

    setChangedFiltersCallback(newFilters)
  }, [filters, selection, setChangedFiltersCallback])

  return <ul className='filter-menu menu-sub'>
    <MenuHeader label={ t('cards.filter_menu.titles.rank') } menuTree= { ['main'] } depth={ 1 } backCallback={ toggleViewCallback } />
    <SelectAll selectCallback={ selectAll } />
    {
      conditions.map((e) => <MenuItem
        key={ e }
        id={ e }
        label={ t(`collections.conditions.short.${e}`) }
        selected={ selection.includes(e) }
        callback={ toggleOption }
      />)
    }
  </ul>
}

const FilterMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { endpointForExistingFilters, defaultFilters, filters, setFilterMenuDisplayed, filterMenuDisplayed, cachedTagRefs, searchString, setPendingInitialFilterCategories, isUserProfile } = useContext(SearchContext)

  const [submenuDepth, setSubmenuDepth] = useState<number>(0)
  const [submenu, setSubmenu] = useState<string[]>([])

  // these filters stay unmodified as the original state when the menu is opened
  const [initialFilters, setInitialFilters] = useState<Filters>(filters)
  const [initialSearchString, setInitialSearchString] = useState<string>(searchString)

  // these filters are modified each time the user changes a filter in the menu
  const [changedFilters, setChangedFilters] = useState<Filters>(filters)
  const [changedSearchString, setChangedSearchString] = useState<string>(searchString)

  // we can then compare the two and set the new filters
  const filtersChanged = useMemo(() => JSON.stringify([initialFilters, initialSearchString]) !== JSON.stringify([changedFilters, changedSearchString]), [changedFilters, initialFilters, initialSearchString, changedSearchString])

  const generateEndpointAfterClose = useCallback(() => {
    if (!endpointForExistingFilters) return

    if (filtersChanged) {
      // need a new endpoint!
      const currentUrl = window.location.pathname + window.location.search

      const currentUrlPrefix = currentUrl.split('?')[0]

      let newUrl = endpointForExistingFilters(changedFilters, changedSearchString) ?? ''

      if (newUrl[0] === '?') {
        if (currentUrl.includes('/categories') || (currentUrl.includes('/u/') && !currentUrl.includes('/units/'))) {
          newUrl = currentUrlPrefix + newUrl
        } else {
          newUrl = '/categories/' + newUrl
        }
      }

      if (newUrl === currentUrl) return

      navigate(newUrl)

      // set filters to their new states
      setInitialFilters(changedFilters)
      setInitialSearchString(changedSearchString)
    }
  }, [changedFilters, changedSearchString, endpointForExistingFilters, filtersChanged, navigate])

  const closeMenu = useCallback(() => {
    setFilterMenuDisplayed?.(false)

    generateEndpointAfterClose()
  }, [generateEndpointAfterClose, setFilterMenuDisplayed])

  const setChangedFiltersCallback = useCallback((f: Filters) => {
    if (!filterMenuDisplayed) return

    setChangedFilters(f)
  }, [filterMenuDisplayed])

  // const deepestSelectedCategoryDepth = useMemo(() => {
  //   if (changedFilters.selectedCategories.length === 0) return 0

  //   return changedFilters.selectedCategories.reduce((prev, curr) => {
  //     return prev.depth > curr.depth ? prev : curr
  //   }).depth
  // }, [changedFilters.selectedCategories])

  useEffect(() => {
    if (!filterMenuDisplayed) {
      // filters or searchstring changed in the background
      setInitialFilters(filters)
      setInitialSearchString(searchString)
      setChangedFilters(filters)
      setChangedSearchString(searchString)
    }
  }, [filterMenuDisplayed, filters, searchString])

  const toggleView = useCallback((view: string[], depth: number) => {
    setSubmenuDepth(depth)

    setSubmenu(view)
  }, [])

  useEffect(() => {
    if (!filterMenuDisplayed) {
      // filter menu not displayed
      // if on main screen and search string didn't change, wgaf
      if (submenuDepth === 0 && initialSearchString === searchString) return

      // filter menu was closed; reset view
      setSubmenuDepth(0)
    }
  }, [filterMenuDisplayed, initialSearchString, searchString, submenuDepth])

  const filterQueryString = useFilterQueryString()

  const filtersQueryString = useMemo((): Filters => {
    return {
      ...changedFilters,
      selectedGame: filterQueryString.game,
      selectedView: filterQueryString.view,
      selectedCardTypes: filterQueryString.cardTypes,
      selectedEnergyTypes: filterQueryString.energyTypes,
      selectedRarities: filterQueryString.rarities,
      selectedConditions: filterQueryString.conditions,
      selectedStocks: filterQueryString.stocks,
      priceRange: {
        min: filterQueryString.minPrice,
        max: filterQueryString.maxPrice,
      },
      selectedUserId: filterQueryString.userId,
    }
  }, [changedFilters, filterQueryString.game, filterQueryString.view, filterQueryString.cardTypes, filterQueryString.energyTypes, filterQueryString.rarities, filterQueryString.conditions, filterQueryString.stocks, filterQueryString.minPrice, filterQueryString.maxPrice, filterQueryString.userId])

  const filtersEqual = useMemo(() => {
    return JSON.stringify([filters, filters.selectedCategories.map(t => t.id)]) === JSON.stringify([filtersQueryString])
  }, [filters, filtersQueryString])

  // const { addError } = useContext(ErrorsContext)

  // const appendTags = useCallback((tags: Tag[]) => {
  //   // cachedTagRefs may be outdated here

  //   const newTags: TagRef[] = []

  //   tags.forEach(tag => {
  //     if (cachedTagRefs.find(c => c.id === tag.id)) return
  //     newTags.push(mapTagToTagRef(tag, i18n.language))
  //   })

  //   appendCachedTagRefs?.(newTags)
  // }, [appendCachedTagRefs, cachedTagRefs, i18n.language])

  // const getTags = useCallback((tagId) => {
  //   // if (loading) return

  //   setCategoriesLoading(true)

  //   tagsRepository
  //     .index({ parentId: tagId, params: { page: 0, mustHaveItems: true, limit: 999, sort: 'release_date_desc' } })
  //     .then(({ tags }) => {
  //       appendTags(tags)
  //     })
  //     .catch((err: AxiosError) => {
  //       addError?.(err)
  //     })
  //     .finally(() => {
  //       setCategoriesLoading(false)
  //     })
  // }, [addError, appendTags])

  // const getTag = useCallback((id) => {
  //   // if (loading) return

  //   setCategoriesLoading(true)

  //   tagsRepository
  //     .get(id)
  //     .then(({ tag }) => {
  //       appendTags([tag])
  //     })
  //     .catch((err: AxiosError) => {
  //       addError?.(err)
  //     })
  //     .finally(() => {
  //       setCategoriesLoading(false)
  //     })
  // }, [addError, appendTags])

  const [didSetInitialFilters, setDidSetInitialFilters] = useState<boolean>(false)

  // useEffect(() => {
  //   if (pendingInitialFilterCategories.length === 0 || !setPendingInitialFilterCategories) return

  //   if (changedFilters.selectedCategories.filter(t => t.depth === deepestSelectedCategoryDepth).length > 1) {
  //     // user began selecting categories; bail out
  //     setPendingInitialFilterCategories([])
  //   }

  //   const tagsFromQueryParams = cachedTagRefs.filter(t => pendingInitialFilterCategories.includes(t.id))

  //   if (tagsFromQueryParams.length === pendingInitialFilterCategories.length) {
  //     // found all of the categories set from query params
  //     setPendingInitialFilterCategories([])

  //     setChangedFilters({
  //       ...changedFilters,
  //       selectedCategories: tagsFromQueryParams,
  //     })
  //   }
  // }, [cachedTagRefs, deepestSelectedCategoryDepth, changedFilters, changedFilters.selectedCategories.length, pendingInitialFilterCategories, setPendingInitialFilterCategories])

  useEffect(() => {
    if (!filterMenuDisplayed && !filtersEqual && !didSetInitialFilters && setPendingInitialFilterCategories) {
      // set initial filters (based on query strings from address bar)
      setDidSetInitialFilters(true)

      // setPendingInitialFilterCategories(filterQueryString.categories)
      // filterQueryString.categories.forEach((tagId) => {
      //   getTag(tagId)
      // })

      // // set everything except for categories (we will apply them separately)
      // console.log('set 3')
      // setInitialFilters(filtersQueryString)
      // setChangedFilters(filtersQueryString)
    }
  }, [didSetInitialFilters, filterMenuDisplayed, filtersEqual, setPendingInitialFilterCategories])

  useEffect(() => {
    if (filterMenuDisplayed) return

    setSubmenu([])
  }, [changedFilters, filterMenuDisplayed])

  const resetFilters = useCallback(() => {
    setSubmenu([])

    setChangedFilters({
      ...defaultFilters,
      selectedView: filters.selectedView,
      selectedGame: filters.selectedGame,
      // selectedCategories: changedFilters.selectedCategories,
    })

    toggleView(['main'], 0)
  }, [defaultFilters, filters.selectedGame, filters.selectedView, toggleView])

  // useEffect(() => {
  //   if (submenu[0] === 'category' && submenu.length === 1 && changedFilters.selectedCategories.length > 0) {
  //     const sortedCategories = changedFilters.selectedCategories.sort((a, b) => a.depth < b.depth ? -1 : 1)

  //     setSubmenu(['category'].concat(sortedCategories.map(t => String(t.id))))
  //   }
  // }, [changedFilters.selectedCategories, submenu])

  // const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false)

  const submenuUi = useMemo(() => {
    // if (submenu[0] === 'category') {
    //   return <CategoryMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } loading={ categoriesLoading } getTag={ getTag } getTags={ getTags } />
    // }
    if (submenu[0] === 'game') {
      return <GameMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'view') {
      return <ViewMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'cardtype') {
      return <CardTypeMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'energytype') {
      return <EnergyTypeMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'rarity') {
      return <RarityMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'price') {
      return <PriceMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'condition') {
      return <ConditionMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
    if (submenu[0] === 'stock') {
      return <StockMenu filters={ changedFilters } setChangedFiltersCallback={ setChangedFiltersCallback } toggleViewCallback={ toggleView } submenu={ submenu } />
    }
  }, [changedFilters, setChangedFiltersCallback, submenu, toggleView])

  // const listCategory = useMemo(() => {
  //   let selectedCategoryUi = <span>{ t('cards.filter_menu.not_selected') }</span>

  //   let depth = 1

  //   if (changedFilters.selectedCategories.length > 0) {
  //     const categories = changedFilters.selectedCategories.filter((t) => t.depth === deepestSelectedCategoryDepth)

  //     selectedCategoryUi = <>{ categories.map((tag, i) => {
  //       if (categories.length >= 6) {
  //         if (i === 4) return <span className='truncate'>{ t('cards.filter_menu.and_n_more', { n: categories.length - i }) }</span>
  //         if (i > 4) return null
  //       }

  //       return <span key={ tag.id }>{ tag.nameShort }</span>
  //     }) }</>

  //     // if there is more than one category selected, show the parent category
  //     // if there is only one and the deepest-selected category has children, show them
  //     // if not, show the parent category
  //     if (categories.length > 1) {
  //       depth = deepestSelectedCategoryDepth
  //     } else {
  //       const t = categories[0]

  //       if (t.childrenCount > 0) {
  //         depth = +t.depth + 1
  //       } else {
  //         depth = t.depth
  //       }
  //     }
  //   }

  //   return <li onClick={ _ => { toggleView(['category'], depth) } } className={ clsx(changedFilters.selectedCategories.length > 0 ? 'choices-1' : 'choices-none') }>
  //     <span className='title'>{ t('cards.filter_menu.titles.series') }</span>
  //     <span className='forward bold'>
  //       { selectedCategoryUi }
  //     </span>
  //     <span className='next'>▸</span>
  //   </li>
  // }, [deepestSelectedCategoryDepth, changedFilters.selectedCategories, t, toggleView])

  const listGame = useMemo(() => {
    return <li onClick={ _ => { toggleView(['game'], 1) } } className={ clsx(changedFilters.selectedGame ? 'choices-1' : 'choices-none') }>
      <span className='title'>{ t('cards.game.title') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedGame
            ? <span key={ changedFilters.selectedGame }>{ t(`cards.game.${games.find(e => e.id === changedFilters.selectedGame)?.name ?? ''}`) }</span>
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedGame, t, toggleView])

  const listView = useMemo(() => {
    return <li onClick={ _ => { toggleView(['view'], 1) } } className='choices-1'>
      <span className='title'>{ t('card_views.title') }</span>
      <span className='forward bold'>
        <span>{ t(`card_views.${changedFilters.selectedView}`) }</span>
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedView, t, toggleView])

  const listCardType = useMemo(() => {
    return <li onClick={ _ => { toggleView(['cardtype'], 1) } } className={ clsx(changedFilters.selectedCardTypes.length > 0 ? `choices-${changedFilters.selectedCardTypes.length}` : 'choices-none') }>
      <span className='title'>{ t('cards.layouts.title') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedCardTypes.length > 0
            ? changedFilters.selectedCardTypes.length === cardTypes.length
              ? <span>{ t('cards.filter_menu.all') }</span>
              : changedFilters.selectedCardTypes.map((id, i) => {
                if (changedFilters.selectedCardTypes.length >= 6) {
                  if (i === 4) return <span className='truncate'>{ t('cards.filter_menu.and_n_more', { n: changedFilters.selectedCardTypes.length - i }) }</span>
                  if (i > 4) return null
                }

                return <span key={ id }>{ t(`cards.layouts.${cardTypes.find(e => e.id === id)?.name ?? ''}`) }</span>
              })
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedCardTypes, t, toggleView])

  const listEnergyType = useMemo(() => {
    return <li onClick={ _ => { toggleView(['energytype'], 1) } } className={ clsx(changedFilters.selectedEnergyTypes.length > 0 ? `choices-${changedFilters.selectedEnergyTypes.length}` : 'choices-none') }>
      <span className='title'>{ t('cards.types.title') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedEnergyTypes.length > 0
            ? changedFilters.selectedEnergyTypes.length === energyTypes.length
              ? <span>{ t('cards.filter_menu.all') }</span>
              : changedFilters.selectedEnergyTypes.map(id => {
                return <span key={ id }>{ t(`cards.types.${energyTypes.find(e => e.id === id)?.name ?? ''}`) }</span>
              })
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedEnergyTypes, t, toggleView])

  const listRarity = useMemo(() => {
    return <li onClick={ _ => { toggleView(['rarity'], 1) } } className={ clsx(changedFilters.selectedRarities.length > 0 ? `choices-${changedFilters.selectedRarities.length}` : 'choices-none') }>
      <span className='title'>{ t('cards.rarities.title') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedRarities.length > 0
            ? changedFilters.selectedRarities.length === rarities.length
              ? <span>{ t('cards.filter_menu.all') }</span>
              : changedFilters.selectedRarities.map((id, i) => {
                if (changedFilters.selectedRarities.length >= 10) {
                  if (i === 8) return <span className='truncate'>{ t('cards.filter_menu.and_n_more', { n: changedFilters.selectedRarities.length - i }) }</span>
                  if (i > 8) return null
                }

                return <span key={ id }>{ t(`cards.rarities.${rarities.find(e => e.id === id)?.name ?? ''}`) }</span>
              })
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedRarities, t, toggleView])

  const formatNumber = (n: number): string => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const listPrice = useMemo(() => {
    const priceSet = !(!changedFilters.priceRange.min && !changedFilters.priceRange.max)

    return <li onClick={ _ => { toggleView(['price'], 1) } } className={ clsx(priceSet ? 'choices-1' : 'choices-none') }>
      <span className='title'>{ t('cards.price_range.title') }</span>
      <span className='forward bold'>
        {
          priceSet
            ? <span>{ changedFilters.priceRange.min ? formatNumber(changedFilters.priceRange.min) : 0 } ~ { changedFilters.priceRange.max ? formatNumber(changedFilters.priceRange.max) : '' }円</span>
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.priceRange.max, changedFilters.priceRange.min, t, toggleView])

  const listCondition = useMemo(() => {
    return <li onClick={ _ => { toggleView(['condition'], 1) } } className={ clsx(changedFilters.selectedConditions.length > 0 ? `choices-${changedFilters.selectedConditions.length}` : 'choices-none') }>
      <span className='title'>{ t('cards.filter_menu.titles.rank') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedConditions.length > 0
            ? changedFilters.selectedConditions.length === conditions.length
              ? <span>{ t('cards.filter_menu.all') }</span>
              : changedFilters.selectedConditions.map(id => {
                return <span key={ id }>{ t(`collections.conditions.short.${id}`) }</span>
              })
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedConditions, t, toggleView])

  const listStock = useMemo(() => {
    if (isUserProfile) return null

    return <li onClick={ _ => { toggleView(['stock'], 1) } } className={ clsx(changedFilters.selectedStocks.length > 0 ? `choices-${changedFilters.selectedStocks.length}` : 'choices-none') }>
      <span className='title'>{ t('card_stocks.title') }</span>
      <span className='forward bold'>
        {
          changedFilters.selectedStocks.length > 0
            ? changedFilters.selectedStocks.map(id => {
              return <span key={ id }>{ t(`card_stocks.${id}`) }</span>
            })
            : <span>{ t('cards.filter_menu.not_selected') }</span>
        }
      </span>
      <span className='next'>▸</span>
    </li>
  }, [changedFilters.selectedStocks, isUserProfile, t, toggleView])

  const submenuLength = useMemo(() => {
    // categories are a specical case
    if (submenu[0] !== 'category') return submenu.length

    const deepestTag = submenu
      .map(tagId => cachedTagRefs.find(t => t.id === Number(tagId)))
      .reduce((previous, current) => {
        if (!current) return previous
        if (!previous || current.depth > previous.depth) return current
        return previous
      })

    // minimum menu depth is always 1
    if (!deepestTag) return 1

    return deepestTag.depth + 1
  }, [cachedTagRefs, submenu])

  const maxSubMenuDepth = useMemo(() => {
    // anything larger than 4 will cause the CSS to be off, but that is better than an application crash
    return Math.max(4, submenuLength)
  }, [submenuLength])

  const compareFiltersToDefault = useCallback((filters: Filters): boolean => {
    return JSON.stringify(filters) === JSON.stringify({
      ...defaultFilters,
      selectedView: filters.selectedView,
    })
  }, [defaultFilters])

  const filtersAreDefault = useMemo(() => {
    return compareFiltersToDefault(changedFilters)
  }, [changedFilters, compareFiltersToDefault])

  const menu = useMemo(() => {
    return <div className={ clsx('filter-container', { submenuActive: submenuDepth > 1 }, `subview-show-${submenuDepth}`) }>
      <div className='filter-inner'>
        <ul className='filter-menu menu-main'>
          { /* <li className='choices-none'>
            <span className='title'>{ t('cards.filter_menu.titles.game') }</span>
            <span className='forward bold'>
              <span>{ t('cards.filter_menu.pokemon') }</span>
            </span>
          </li> */ }
          { /* { listCategory } */ }
          { listGame }
          { listView }
          { listCardType }
          { listEnergyType }
          { listRarity }
          { listPrice }
          { listCondition }
          { listStock }
        </ul>
        { submenuUi }
        { [...Array(maxSubMenuDepth - submenuLength)].map((_, i) => <ul className='filter-menu empty' key={ `empty-${i}` }></ul>) }
      </div>
      <div className='buttons'>
        <button onClick={ resetFilters } className='reset white' disabled={ filtersAreDefault }>{ t('cards.filter_menu.reset') }</button>
        <button onClick={ closeMenu }>{ t('cards.filter_menu.apply') }</button>
      </div>
    </div>
  }, [closeMenu, filtersAreDefault, listCardType, listCondition, listEnergyType, listGame, listPrice, listRarity, listStock, listView, maxSubMenuDepth, resetFilters, submenuDepth, submenuLength, submenuUi, t])

  return <>
    <div className='close-filter-menu' onClick={ closeMenu }></div>
    { menu }
  </>
}

export { FilterMenu }
