import React, { useContext, useEffect, Suspense, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import queryString from 'query-string'

import { GoogleAnalytics } from './googleanalytics'

import { Account } from './account'
import { Categories } from './categories'
import { GenericNotFound } from './generic_not_found'
import { Items } from './items'
import { ItemOnboard } from './item_onboard'
import { ListingCreate } from './listing_create'
import { Login } from './login'
import { Orders } from './orders'
import { Photos } from './photos'
import { Register } from './register'
import { Subscriptions } from './subscriptions'
import { UserProfile } from './user_profile'

import { ConditionCalculator } from './condition_calculator'
import { Errors } from './errors'
import { BasketContext } from '../contexts/basket_context'
import { PageActions } from './pageactions'

type Props = Readonly<{
  gitSha: string
}>

const Router = ({ gitSha }: Props) => {
  const { setReferral, referral } = useContext(BasketContext)
  const Admin = lazy(() => import('./admin'))
  const Sell = lazy(() => import('./sell'))
  const Static = lazy(() => import('./static'))

  const location = useLocation()
  const pathSplit = location.pathname.split('/')
  const path = pathSplit.slice(1, 3).join('-') || 'top'

  const pathTop = pathSplit.length > 1 ? pathSplit[1] : ''

  const qS = queryString.parse(location.search)

  const ref = qS.ref as string

  useEffect(() => {
    if (referral) return

    if (ref && ref !== '') {
      setReferral?.(ref)
    }
  }, [ref, referral, setReferral])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [path])

  return (
    <main className={ clsx(path, pathTop) }>
      <React.StrictMode>
        <Errors />
      </React.StrictMode>
      <Routes>
        <Route path={ '/' } element={ <React.StrictMode><Categories /></React.StrictMode> } />
        <Route path={ '/categories' } element={ <React.StrictMode><Categories /></React.StrictMode> }>
          <Route path={ ':id' } element={ <React.StrictMode><Categories /></React.StrictMode> }>
          </Route>
        </Route>
        <Route path={ '/categories/:id/items/:item_id' } element={ <React.StrictMode><Items /></React.StrictMode> }>
          <Route path={ 'ob' } element={ <React.StrictMode><ItemOnboard /></React.StrictMode> } />
        </Route>
        <Route path={ '/account' } element={ <React.StrictMode><Account gitSha={ gitSha } /></React.StrictMode> }>
          <Route path={ ':path' } element={ <React.StrictMode><Account gitSha={ gitSha } /></React.StrictMode> }>
            <Route path={ ':id' } element={ <React.StrictMode><Account gitSha={ gitSha } /></React.StrictMode> } />
          </Route>
        </Route>
        <Route path={ '/admin' } element={
          <React.StrictMode>
            <Suspense fallback={<div>読み込み中…</div>}>
              <Admin />
            </Suspense>
          </React.StrictMode>
        }>
          <Route path={ ':path' }>
            <Route path={ ':subpath' } element={
              <React.StrictMode>
                <Suspense fallback={<div>読み込み中…</div>}>
                  <Admin />
                </Suspense>
              </React.StrictMode>
              }>
              <Route path={ ':id' } element={
                <React.StrictMode>
                  <Suspense fallback={<div>読み込み中…</div>}>
                    <Admin />
                  </Suspense>
                </React.StrictMode>
                } />
            </Route>
          </Route>
        </Route>
        <Route path={ '/items/:item_id' }>
          <Route path={ 'listing' } element={ <React.StrictMode><ListingCreate /></React.StrictMode> } />
          <Route path={ 'unit/:unit_id/listing' } element={ <React.StrictMode><ListingCreate /></React.StrictMode> } />
        </Route>
        <Route path={ '/item/:id/condition' } element={ <React.StrictMode><ConditionCalculator /></React.StrictMode> } />
        <Route path={ '/login' } element={ <React.StrictMode><Login /></React.StrictMode> } />
        <Route path={ '/orders/:id' } element={ <React.StrictMode><Orders /></React.StrictMode> } />
        <Route path={ '/subscriptions/:id' } element={ <React.StrictMode><Subscriptions /></React.StrictMode> } />
        <Route path={ '/photos/:id' } element={ <React.StrictMode><Photos /></React.StrictMode> } />
        <Route path={ '/photos' } element={ <React.StrictMode><Photos /></React.StrictMode> } />
        <Route path={ '/register/:nologin' } element={ <React.StrictMode><Register /></React.StrictMode> } />
        <Route path={ '/register' } element={ <React.StrictMode><Register /></React.StrictMode> } />
        <Route path={ '/sell' } element={
          <React.StrictMode>
            <Suspense fallback={<div>読み込み中…</div>}>
              <Sell />
            </Suspense>
          </React.StrictMode>
        } />
        <Route path={ '/about/:page' } element={
          <React.StrictMode>
            <Suspense fallback={<div>読み込み中…</div>}>
              <Static />
            </Suspense>
          </React.StrictMode>
        } />
        <Route path={ '*' } element={ <React.StrictMode><GenericNotFound /></React.StrictMode> } />
        {
          ['/u/:id', '/collections/:id'].map((p) => (
            <Route key={ p } path={ p }>
              <Route path={ 'about' } element={ <UserProfile screen='about' /> } />
              <Route path={ 'blog' }>
                <Route path={ 'new' } element={ <UserProfile screen='blog-create' /> } />
                <Route path={ ':blog_id' }>
                  <Route path={ 'edit' } element={ <UserProfile screen='blog-edit' /> } />
                  <Route path={ '' } element={ <UserProfile screen='blog' /> } />
                </Route>
                <Route path={ '' } element={ <UserProfile screen='blog' /> } />
              </Route>
              <Route path={ 'reviews' } element={ <UserProfile screen='reviews' /> } />
              <Route path={ 'units/:item_id' } element={ <UserProfile screen='cards' /> } />
              <Route path={ '*' } element={ <UserProfile screen='cards' /> } />
              <Route path={ '' } element={ <UserProfile screen='cards' /> } />
            </Route>
          ))
        }
      </Routes>
      <React.StrictMode>
        <GoogleAnalytics />
        <PageActions />
      </React.StrictMode>
    </main>
  )
}

export { Router }
