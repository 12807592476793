import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SettingsContext } from '../contexts/settings_context'

const Footer = () => {
  const { minimalUi } = useContext(SettingsContext)

  const { t } = useTranslation()

  if (minimalUi) return null

  const ChangeLanguageButton = () => {
    const { t, i18n } = useTranslation()

    const toggleLanguage = useCallback((e) => {
      e.preventDefault()

      if (i18n.language === 'ja') {
        i18n.changeLanguage('en')
      } else {
        i18n.changeLanguage('ja')
      }
    }, [i18n])

    return (
      <a
        href=""
        className='tool'
        onClick={ toggleLanguage }
      >
        { t('header.language') }
      </a>
    )
  }

  return (
    <footer>
      <p>
        { t('footer.madeintokyo') } &copy; { t('footer.companyname') }
      </p>
      <ul>
        <li><Link to="/about/company">{ t('footer.company') }</Link></li>
        <li><a href="mailto:support@holic.net">{ t('footer.support') }</a></li>
      </ul>
      <ul>
        <li><Link to="/about/terms">{ t('footer.terms') }</Link></li>
        <li><Link to="/about/privacy">{ t('footer.privacy') }</Link></li>
        <li><Link to="/about/returns">{ t('footer.returns') }</Link></li>
        <li><Link to="/about/fees">{ t('footer.fees') }</Link></li>
        <li><Link to="/about/tokushouhou">特定商取引法に基づく表記</Link></li>
      </ul>
      <p>
        <a rel="noreferrer" href='https://twitter.com/holicapp' target='_blank'>{ t('header.twitter') }</a> • <ChangeLanguageButton />
      </p>
      <p>
      </p>
      <p>
        { t('footer.disclaimer') }
      </p>
    </footer>
  )
}

export { Footer }
