import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState, Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { ErrorsContext } from '../contexts/errors_context'
import { usersRepository } from '../repositories/users_repository'
import { Blog, Feedback, Filters, User } from '../types'
import { GenericNotFound } from './generic_not_found'
import { UserBanner } from './user_banner'

import '../stylesheets/User.scss'
import clsx from 'clsx'
import { Units } from './units'
import { feedbacksRepository } from '../repositories/feedbacks_repository'
import { FeedbackView } from './feedback'
import { SearchContext } from '../contexts/search_context'
import Select from 'react-select'
import { SelectedFilters } from './selected_filters'
import BlogsIndex from './blog_index'
import { AuthContext } from '../contexts/auth_context'

type SelectOption = Readonly<{
  value: string
  label: string
}>

const itemSorts: SelectOption[] = [
  {
    value: 'ordinality_asc',
    label: '',
  },
  {
    value: 'created_desc',
    label: '',
  },
  // {
  //   value: 'created_asc',
  //   label: '',
  // },
  {
    value: 'price_desc',
    label: '',
  },
  {
    value: 'price_asc',
    label: '',
  },
  {
    value: 'release_date_desc',
    label: '',
  },
  {
    value: 'release_date_asc',
    label: '',
  },
  {
    value: 'name_ja_asc',
    label: '',
  },
  {
    value: 'name_ja_desc',
    label: '',
  },
]

type FeedbacksProps = Readonly<{
  userId: number
  currentPage: number
}>

const Feedbacks = ({ userId, currentPage }: FeedbacksProps) => {
  const { addError } = useContext(ErrorsContext)

  const [feedbacks, setFeedbacks] = useState<Feedback[]>([])
  const [feedbacksLoading, setFeedbacksLoading] = useState<boolean>(false)

  const getFeedbacks = useCallback((uid: number, page: number) => {
    setFeedbacksLoading(true)

    feedbacksRepository
      .index({ userId: uid, params: { page } })
      .then(({ feedbacks }) => {
        setFeedbacks(feedbacks)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setFeedbacksLoading(false)
      })
  }, [addError])

  useEffect(() => {
    getFeedbacks(userId, currentPage)
  }, [currentPage, getFeedbacks, userId])

  return <>
    <h1>評価一覧</h1>
    {
      feedbacksLoading
        ? 'Loading'
        : feedbacks.map((feedback) => <FeedbackView feedback={ feedback } key={ feedback.id } />)
    }
  </>
}

type UserProfileProps = Readonly<{
  screen: string
}>

const UserProfile = ({ screen }: UserProfileProps) => {
  const { addError } = useContext(ErrorsContext)
  const { endpointForExistingFilters, searchString, setSearchboxPlaceholder, setIsUserProfile, filters, hasFilters, setFilters, defaultFilters } = useContext(SearchContext)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const BlogCreate = lazy(() => import('./blog_create'))
  const BlogView = lazy(() => import('./blog_view'))

  const [profileUser, setProfileUser] = useState<User>()
  const [profileUserLoading, setProfileUserLoading] = useState<boolean>(true)

  const path = useParams()

  const [sort, setSort] = useState<string>(itemSorts[0].value)
  const [profileUserId, setProfileUserId] = useState<string|undefined>()

  useEffect(() => {
    const intId = parseInt(path.id ?? '')

    if (!isNaN(intId) && intId > 0) {
      setProfileUserId(String(intId))
      return
    }

    if (path.id === 'me') {
      setProfileUserId('me')
    }
  }, [path])

  useEffect(() => {
    setProfileUser(undefined)
  }, [profileUserId])

  const unitId = useMemo<number|undefined>(() => {
    const intId = parseInt(path.item_id ?? '')

    if (!isNaN(intId) && intId > 0) {
      return intId
    }
  }, [path])

  const blogId = useMemo<number|undefined>(() => {
    const intId = parseInt(path.blog_id ?? '')

    if (!isNaN(intId) && intId > 0) {
      return intId
    }
  }, [path])

  const getUser = useCallback((userId: string) => {
    setProfileUserLoading(true)

    if (userId === 'me') {
      usersRepository
        .getSelf()
        .then(({ user }) => {
          setProfileUserId(String(user.id))
          setProfileUser(user)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setProfileUserLoading(false)
        })
    } else {
      usersRepository
        .getUser(Number(userId))
        .then(({ user }) => {
          setProfileUser(user)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setProfileUserLoading(false)
        })
    }
  }, [addError])

  useEffect(() => {
    if (!profileUserId) return

    if (!profileUser) {
      getUser(profileUserId)
    }
  }, [getUser, profileUserId, profileUser])

  const collectionName = useMemo(() => {
    if (!profileUser?.displayName) return t('cards.item.default_display_name')

    return profileUser.displayName
  }, [t, profileUser])

  const blogCreateCallback = useCallback((blog: Blog) => {
    if (!blog.id) return
    if (!profileUserId) return

    navigate(`/u/${profileUserId}/blog/${blog.id}`)
  }, [navigate, profileUserId])

  const { isAuthenticated, currentUser } = useContext(AuthContext)

  const isMine = useMemo(() => {
    if (!isAuthenticated || !profileUserId) return false

    if (profileUserId === 'me') return true

    const intId = parseInt(profileUserId)

    return intId === currentUser?.id
  }, [isAuthenticated, profileUserId, currentUser?.id])

  const organiseModeAvailable = useMemo(() => {
    if (!isMine) return false

    // compare filters to default filters using stringified JSON
    if (JSON.stringify(filters) === JSON.stringify(defaultFilters)) {
      // must be sorting by ordinality_asc
      return sort === 'ordinality_asc'
    }

    return false
  }, [defaultFilters, filters, isMine, sort])

  const [organiseMode, setOrganiseMode] = useState(false)

  const toggleOrganiseMode = useCallback(() => {
    setOrganiseMode(!organiseMode)
  }, [organiseMode])

  useEffect(() => {
    if (!organiseModeAvailable) {
      setOrganiseMode(false)
    }
  }, [organiseModeAvailable])

  const currentView = useMemo(() => {
    if (!profileUserId || profileUserId === 'me') {
      return
    }

    if (screen === 'reviews') {
      return <div className='narrow'>
        <Feedbacks userId={ Number(profileUserId) } currentPage={ 0 } />
      </div>
    }

    if (screen === 'blog-create') {
      return <div className='narrow'>
        <Suspense fallback={<div>読み込み中…</div>}>
          <BlogCreate callback={ blogCreateCallback } />
        </Suspense>
      </div>
    }

    if (screen === 'blog' || screen === 'blog-edit') {
      if (blogId) {
        return <div className={ clsx({ narrow: !!(screen === 'blog-edit'), 'semi-narrow': !(screen === 'blog-edit') }) }>
          <Suspense fallback={<div>読み込み中…</div>}>
            <BlogView id={ Number(blogId) } userId={ Number(profileUserId) } editMode={ !!(screen === 'blog-edit') } />
          </Suspense>
        </div>
      }

      return <BlogsIndex userId={ Number(profileUserId) } canEdit={ currentUser?.id === Number(profileUserId) } />
    }

    return <Units userId={ profileUserId } user={ profileUser } unitId={ unitId } sort={ sort } organising={ organiseMode } setOrganising={ setOrganiseMode } />
  }, [blogCreateCallback, blogId, currentUser?.id, organiseMode, screen, sort, profileUser, profileUserId, unitId])

  const allCardsEndpoint = useMemo(() => {
    if (!endpointForExistingFilters) return ''

    const f: Filters = {
      ...filters,
      selectedStocks: [],
      priceRange: {
        ...filters.priceRange,
        min: undefined,
      }
    }

    return endpointForExistingFilters(f, searchString) ?? ''
  }, [endpointForExistingFilters, filters, searchString])

  const forSaleCardsEndpoint = useMemo(() => {
    if (!endpointForExistingFilters) return ''

    const f: Filters = {
      ...filters,
      selectedStocks: ['for_sale'],
      priceRange: {
        ...filters.priceRange,
        min: 1,
      }
    }

    return endpointForExistingFilters(f, searchString) ?? ''
  }, [endpointForExistingFilters, filters, searchString])

  const sortMenu = useMemo(() => {
    if (screen !== 'cards') return null

    const optionsTranslated = itemSorts.map((s) => {
      return {
        ...s,
        label: t(`collections.view.layout.${s.value}`) ?? '',
      }
    })

    return <Select
      options={ optionsTranslated }
      onChange={ (p) => setSort(p?.value ?? '') }
      value={ optionsTranslated.find(t => t.value === sort) }
      placeholder={ t('defaults.sort') }
      isClearable={ false }
      isDisabled={ organiseMode }
      styles={ {
        menu: (styles) => {
          return {
            ...styles,
            zIndex: 10,
          }
        }
      } }
    />
  }, [organiseMode, screen, sort, t])

  const clearFilterCallback = useCallback((f: Filters, newSearchString?: string) => {
    if (!endpointForExistingFilters || !profileUserId || !setFilters) return

    const filters = endpointForExistingFilters(f, newSearchString ?? searchString)

    const route = `/u/${profileUserId}`

    navigate(route + filters)

    setFilters(f)
  }, [endpointForExistingFilters, navigate, searchString, setFilters, profileUserId])

  const selectedFiltersUi = useMemo(() => {
    if (!hasFilters) return null

    return <SelectedFilters filters={ filters } searchString={ searchString } hide={ ['stocks'] } callback={ clearFilterCallback } />
  }, [clearFilterCallback, filters, hasFilters, searchString])

  const organiseButtons = useMemo(() => {
    if (!isMine) return null

    if (!organiseMode) {
      return <div className='organise-buttons'>
        <button className='tool' onClick={ toggleOrganiseMode } disabled={ !organiseModeAvailable }>
          { t('collections.view.layout_organise.button_start') }
        </button>
      </div>
    }

    return null
  }, [isMine, organiseMode, organiseModeAvailable, t, toggleOrganiseMode])

  const headerFilters = useMemo(() => {
    if (screen !== 'cards') return null

    return <>
      { selectedFiltersUi }
      <div className='toolbar'>
        { sortMenu }
        { organiseButtons }
      </div>
    </>
  }, [organiseButtons, screen, selectedFiltersUi, sortMenu])

  const header = useMemo(() => {
    if (unitId ?? !profileUserId) return null

    return <>
      <div className='user-header'>
        <UserBanner clickable={ screen !== 'cards' } userId={ String(profileUserId) } displayName={ collectionName } salesLevel={ profileUser?.salesLevel } salesRating={ profileUser?.salesRating } />
        <div className='tabs'>
          <Link to={ `/u/${profileUserId}${allCardsEndpoint}` } className={ clsx({ selected: screen === 'cards' && !filters.priceRange.min }) }>すべて</Link>
          <Link to={ `/u/${profileUserId}${forSaleCardsEndpoint}` } className={ clsx({ selected: screen === 'cards' && filters.priceRange.min }) }>販売中</Link>
          <Link to={ `/u/${profileUserId}/blog` } className={ clsx({ selected: ['blog', 'blog-create'].find(s => s === screen) }) }>ブログ</Link>
          { /* { followButton } */ }
        </div>
      </div>
      { headerFilters }
    </>
  }, [allCardsEndpoint, collectionName, filters.priceRange.min, forSaleCardsEndpoint, headerFilters, screen, profileUser?.salesLevel, profileUser?.salesRating, profileUserId, unitId])

  useEffect(() => {
    if (!profileUser) return

    if (unitId) {
      setSearchboxPlaceholder?.()
      setIsUserProfile?.(false)
    } else {
      const displayName = profileUser.displayName ?? t('cards.item.default_display_name') ?? ''
      setSearchboxPlaceholder?.(`${displayName}の商品を探す`)
      setIsUserProfile?.(true)
    }

    return () => {
      setSearchboxPlaceholder?.()
      setIsUserProfile?.(false)
    }
  }, [setIsUserProfile, setSearchboxPlaceholder, t, profileUser, unitId])

  if (profileUserLoading) {
    return <>Loading...</>
  }

  if (!profileUserId) {
    return <GenericNotFound />
  }

  return (
    <>
      { header }
      { currentView }
    </>
  )
}

export { UserProfile }
