import { useRef, useEffect, useCallback } from 'react'
import { Match } from '../types'

type Props = Readonly<{
  width: number
  height: number
  src: string
  data?: Match[]
}>

const Canvas = ({ width, height, src, data }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const draw = useCallback((ctx) => {
    ctx.fillStyle = '#000000'
    ctx.beginPath()
    ctx.arc(50, 100, 20, 0, 2 * Math.PI)
    ctx.fill()
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')

    if (!canvas || !context) return

    const img = new Image()
    img.onload = function () {
      const imageWidth = width
      const imageHeight = height

      let imageWidthScaled = 0
      let imageHeightScaled = 0

      if (imageWidth > imageHeight) {
        imageWidthScaled = canvas.width
        imageHeightScaled = canvas.width * (imageHeight / imageWidth)
      } else {
        imageWidthScaled = canvas.height * (imageWidth / imageHeight)
        imageHeightScaled = canvas.height
      }

      context.drawImage(img, 0, 0, imageWidthScaled, imageHeightScaled)

      // context.globalAlpha = 0.5
      context.fillStyle = 'rgba(238,57,66,.5)'
      context.strokeStyle = 'rgb(238,57,66)'

      if (data) {
        for (let i = 0; i < data.length; ++i) {
          // const data = data.card

          // if (data.score < 0.99) continue

          context.rect(data[i].card.x * width, data[i].card.y * height, (data[i].card.w) * width, (data[i].card.h) * height)

          // context.fill()
          context.stroke()
        }
      }
    }

    img.src = src

    // Our draw come here
    // draw(context)
  }, [data, draw, height, src, width])

  return (
    <canvas
      ref={ canvasRef }
      width={ width }
      height={ height }
      //   src ={ src }
      // data={ data }
    />
  )
}

export { Canvas }
