import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'
import { Link } from 'react-router-dom'

import { Unit } from '../types'

type Props = Readonly<{
  onClose: () => void
  removeItemById: (Unit) => void
  units: Unit[] | undefined
}>

const SelectUnitModal = ({ onClose, units, removeItemById }: Props): JSX.Element => {
  const { t } = useTranslation()

  const description = useCallback((value: string | undefined) => {
    return value ? <span>{ value }</span> : ''
  }, [])

  const buyDate = useCallback((value: string | undefined) => {
    return value ? <span className="buy-date">{ `bought ${value}` }</span> : ''
  }, [])

  const buyPrice = useCallback((value: number | undefined) => {
    return value ? <span className="buy-price">{ `-$${value}` }</span> : ''
  }, [])

  const sellDate = useCallback((value: string | undefined) => {
    return value ? <span className="sell-date">{ `sold ${value}` }</span> : ''
  }, [])

  const sellPrice = useCallback((value: number | undefined) => {
    return value ? <span className="sell-price">{ `+$${value}` }</span> : ''
  }, [])

  const cardList = useMemo(() => {
    const platforms = {
      holic: t('listings.platform_title_holic'),
      ebay: t('listings.platform_title_ebay'),
    }

    return (
      <>
        <h1>{ t('cards.select_unit_modal.title') }</h1>
        <ul className="remove-card-list">
          {
            units?.map((unit) => {
              return <li key={ unit.id }>
                <span><Link to={ `/u/${unit.userId ?? 0}/units/${unit.id ?? 0}` }>#{ unit.id }</Link></span>
                { [
                  description(unit.description),
                  buyDate(unit.buyDate),
                  buyPrice(unit.buyPriceJpy),
                  sellDate(unit.sellDate),
                  sellPrice(unit.sellPriceJpy),
                ] }
                {
                  unit.listings
                    ? unit.listings.map(l => {
                      return <span key={ l.id }>{ platforms[l.platform] }: { t('listings.price.price_with_currency_symbol', { price: Math.round(l.priceJpy) }) }</span>
                    })
                    : null
                }
                <button onClick={ () => { removeItemById(unit) } } className="tool">{ t('cards.select_unit_modal.button_remove') }</button>
              </li>
            })
          }
        </ul>
        <p>
          <button className="full" onClick={ onClose }>
            { t('cards.select_unit_modal.button_finished') }
          </button>
        </p>
      </>
    )
  }, [buyDate, buyPrice, description, onClose, removeItemById, sellDate, sellPrice, t, units])

  return (
    <ModalWrapper>
      { cardList }
    </ModalWrapper>
  )
}

export { SelectUnitModal }
