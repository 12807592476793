import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import '../stylesheets/Error.scss'
import { usersRepository } from '../repositories/users_repository'

import '../stylesheets/Unit.scss'
import { ErrorsContext } from '../contexts/errors_context'
import clsx from 'clsx'
import { SettingsContext } from '../contexts/settings_context'
import { AuthContext } from '../contexts/auth_context'

type BasicForm = Readonly<{
  displayName: string
}>

type EmailForm = Readonly<{
  email: string
  password: string
}>

type PasswordForm = Readonly<{
  password: string
  passwordConfirmation: string
  passwordNew: string
}>

type ChangeLanguageProps = Readonly<{
  debug?: boolean
}>

const ChangeLanguageButton = ({ debug }: ChangeLanguageProps) => {
  const { t, i18n } = useTranslation()

  const toggleLanguage = useCallback(() => {
    if (debug && i18n.language !== 'cimode') {
      i18n.changeLanguage('cimode')
      return
    }

    if (i18n.language === 'ja') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('ja')
    }
  }, [debug, i18n])

  return (
    <button
      className='tool'
      onClick={ toggleLanguage }
    >
      { debug ? 'Debug mode' : t('header.language') }
    </button>
  )
}

const ChangeThemeButton = () => {
  const { t } = useTranslation()
  const { dark, setDark } = useContext(SettingsContext)

  const toggleTheme = useCallback(() => {
    setDark?.(!dark)
  }, [dark, setDark])

  return (
    <button
      className={ clsx('tool') }
      onClick={ toggleTheme }
    >
      { dark ? t('header.toggle-light') : t('header.toggle-dark') }
    </button>
  )
}

const LogoutButton = () => {
  const { t } = useTranslation()
  const { logout } = useContext(AuthContext)

  const onClickLogoutButton = useCallback(async () => {
    await logout?.()
  }, [logout])

  return (
    <button
      type='button'
      className="tool"
      onClick={ onClickLogoutButton }
    >
      { t('header.logout') }
    </button>
  )
}

type AccountProps = Readonly<{
  gitSha: string
}>

const AccountGeneral = ({ gitSha }: AccountProps) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const {
    register: registerBasic,
    reset: resetBasic,
    handleSubmit: handleSubmitBasic,
  } = useForm<BasicForm>()

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
  } = useForm<EmailForm>()

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    reset: resetPassword,
  } = useForm<PasswordForm>()

  const [userLoading, setUserLoading] = useState<boolean>(true)

  const getUser = useCallback(() => {
    setUserLoading(true)

    usersRepository
      .getSelf()
      .then(({ user }) => {
        resetBasic({ displayName: user.displayName })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setUserLoading(false)
      })
  }, [addError, resetBasic])

  useEffect(() => {
    getUser()
  }, [getUser])

  const onSubmitBasic = useCallback((params) => {
    // TODO: Validation

    if (userLoading) return

    usersRepository
      .updateBasic({ id: 'me', params })
      .then(() => {
        alert('更新しました')
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, userLoading])

  const onSubmitEmail = useCallback((params) => {
    // TODO: Validation

    usersRepository
      .updateEmail({ id: 'me', params })
      .then(() => {
        alert('更新しました')
        resetEmail()
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, resetEmail])

  const onSubmitPassword = useCallback((params) => {
    // TODO: Validation

    usersRepository
      .updatePassword({ id: 'me', params })
      .then(() => {
        alert('更新しました')
        resetPassword()
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, resetPassword])

  return (
    <div>
      <div className="narrow">
        <h2>{ t('account.basic_subtitle') }</h2>
        <form key={ 'basic' } onSubmit={ handleSubmitBasic(onSubmitBasic) }>
          <p>
            <label>{ t('account.display_name') }</label>
            <input type="text" className="full" required { ...registerBasic('displayName') } disabled={ userLoading } />
          </p>
          <p>
            <button className="full" disabled={ userLoading }>
              { t('account.submit_basic') }
            </button>
          </p>
        </form>
        <hr />
        <h2>{ t('account.email_subtitle') }</h2>
        <form key={ 'email' } onSubmit={ handleSubmitEmail(onSubmitEmail) }>
          <p>
            <label>{ t('account.email') }</label>
            <input type="email" className="full" required { ...registerEmail('email') } />
          </p>
          <p>
            <label>{ t('account.email_password') }</label>
            <input type="password" className="full" required minLength={ 8 } { ...registerEmail('password') } />
          </p>
          <p>
            <button className="full">
              { t('account.submit_email') }
            </button>
          </p>
        </form>
        <hr />
        <h2>{ t('account.password_subtitle') }</h2>
        <form key={ 'password' } onSubmit={ handleSubmitPassword(onSubmitPassword) }>
          <p>
            <label>{ t('account.password_old') }</label>
            <input type="password" className="full" required { ...registerPassword('password') } />
          </p>
          <p>
            <label>{ t('account.password') }</label>
            <input type="password" className="full" minLength={ 8 } required { ...registerPassword('passwordNew') } />
          </p>
          <p>
            <label>{ t('account.password_confirmation') }</label>
            <input type="password" className="full" minLength={ 8 } required { ...registerPassword('passwordConfirmation') } />
          </p>
          <p>
            <button className="full">
              { t('account.submit_password') }
            </button>
          </p>
        </form>
        <hr />
        <p>
          <ChangeThemeButton />
        </p>
        <p>
          <ChangeLanguageButton debug={ false } />
        </p>
        <p>
          <ChangeLanguageButton debug={ true } />
        </p>
        <hr />
        <p>
          { t('header.beta') } { gitSha } <a rel="noreferrer" href={ `https://docs.google.com/forms/d/e/1FAIpQLSe_7nNVHnjNpC63ENT9iSAFddIsyM-3cARezuad951Nv7fZVw/viewform?usp=pp_url&entry.589630247=${gitSha}` } target='_blank'>{ t('header.feedback') }</a>
        </p>
        <p>
          <LogoutButton />
        </p>
      </div>
    </div>
  )
}

export { AccountGeneral }
