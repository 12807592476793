import { useCallback, useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { usersRepository } from '../repositories/users_repository'
import { AuthContext } from '../contexts/auth_context'
import { AxiosError } from 'axios'

import '../stylesheets/Register.scss'
import queryString from 'query-string'
import { ErrorsContext } from '../contexts/errors_context'

const Register = () => {
  const location = useLocation()
  const qS = queryString.parse(location.search)

  const itemIdParam = qS.item_id as string
  const itemId = useMemo<number|undefined>(() => {
    if (!itemIdParam) return

    const id = parseInt(itemIdParam ?? '')
    if (id) return id
  }, [itemIdParam])

  const { t } = useTranslation()
  const { register, handleSubmit, setValue } = useForm()
  const { addError } = useContext(ErrorsContext)
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = useCallback((data) => {
    if (loading) return

    // TODO: Validation
    const params = {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      itemId,
    }

    setLoading(true)

    usersRepository
      .create(params)
      .then(() => {
        setIsAuthenticated?.(true)
        // this.props.auth.handle(null, res)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, itemId, loading, setIsAuthenticated])

  const inputDefaultAccount = useCallback(() => {
    const d = new Date()
    const email = String(`edward+${d.getFullYear()}${String(d.getMonth()).padStart(2, '0')}${String(d.getDate()).padStart(2, '0')}${String(d.getHours()).padStart(2, '0')}${String(d.getMinutes()).padStart(2, '0')}@holic.net`)

    setValue('email', email)
    setValue('password', email)
    setValue('password_confirmation', email)
  }, [setValue])

  const registerForm = useMemo(() => {
    return <>
      <div>
        <h1>{ t('register.title2') }</h1>
        { /* <p>
          <Trans i18nKey="register.registerExplanation1">
            t1<br /><strong>t2</strong>t3
          </Trans>
        </p> */ }
        <p>
          { t('register.registerExplanation2') }
        </p>
      </div>
      {
        process.env.REACT_APP_RAILS_ENV !== 'production'
          ? <p><button onClick={ inputDefaultAccount } className="tool">Use disposable account</button></p>
          : null
      }
      <form onSubmit={ handleSubmit(onSubmit) }>
        <p>
          <label>{ t('register.email') }</label>
          <input type="email" className="full" { ...register('email') } autoFocus />
        </p>
        <p>
          <label>{ t('register.password') }</label>
          <input type="password" className="full" { ...register('password') } />
        </p>
        <p>
          <label>{ t('register.password_confirmation') }</label>
          <input type="password" className="full" { ...register('password_confirmation') } />
        </p>
        <p>
          <button className="full" disabled={ loading }>
            { t('register.button1') }
          </button>
        </p>
        <p className='small center'>
          <Trans i18nKey="register.terms">
            上のボタンをクリックした場合、<Link to="/about/terms">利用規約</Link>に同意したものとみなします。
          </Trans>
        </p>
      </form>
      <hr />
      <h3>{ t('register.login.title') }</h3>
      <p>
        <Link to="/login" className='button full white'>{ t('register.login.button') }</Link>
      </p>
    </>
  }, [t, inputDefaultAccount, handleSubmit, onSubmit, register, loading])

  if (isAuthenticated) {
    return <RegisterComplete />
  }

  return <div className="narrow">
    { registerForm }
  </div>
}

const RegisterComplete = () => {
  const { t } = useTranslation()
  // return (
  //   <div>
  //     <h1>{ t('register.complete.title') }</h1>
  //     <p>{ t('register.complete.explanation') }</p>
  //     <p><Link to='/'>{ t('register.complete.link') }</Link></p>
  //   </div>
  // )

  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <></>

  return (
    <div className="register-complete">
      <div>
        <h1>{ t('register.complete.title') }</h1>
        <div className="art"></div>
        <p className="text">
          { t('register.complete.explanation') }
        </p>
        {
        /*
          <p>
            <Link to={ '/sell' } className="button">最初のカードを出品</Link>
          </p>
        */
        }
        <p>
          <Link to={ '/' } className="button white">{ t('register.complete.link') }</Link>
        </p>
      </div>
    </div>
  )
}

export { Register }
