import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useMatch } from 'react-router-dom'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import { imagesRepository } from '../repositories/images_repository'
import { CardSettings, Image, ImageUnit, Match } from '../types'
import { ImageUploadModalGcp } from './image_upload_modal_gcp'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { CardSingle } from './card_single'
import { Canvas } from './canvas'

import '../stylesheets/Photos.scss'
import { imageUnitsRepository } from '../repositories/image_units_repository'

const Photos = () => {
  const navigate = useNavigate()

  const qS = queryString.parse(location.search)
  const page = qS.page as string
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(true)

  useEffect(() => {
    if (page) setCurrentPage(parseInt(page))
  }, [page])

  const { t } = useTranslation()

  const { addError } = useContext(ErrorsContext)

  const match = useMatch('/photos/:id')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [images, setImages] = useState<Image[]>([])
  const [imageId, setImageId] = useState<number|undefined>()
  const [currentImage, setCurrentImage] = useState<Image|undefined>()
  const [matchesConfirmed, setMatchesConfirmed] = useState<ImageUnit[]>([])

  const paramsId = useMemo<number|undefined>(() => {
    if (!match) return undefined

    const { id } = match.params
    const intId = parseInt(id ?? '')

    return isNaN(intId) ? undefined : intId
  }, [match])

  useEffect(() => {
    setImageId(paramsId)
  }, [paramsId])

  const getImages = useCallback(() => {
    setIsLoadingImages(true)

    imagesRepository.index({
      page: currentPage,
    })
      .then(({ images, pagination }) => {
        if (images) setImages(images)
        setCurrentPage(pagination.currentPage)
        setTotalPages(pagination.totalPages)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setIsLoadingImages(false)
      })
  }, [addError, currentPage])

  useEffect(() => {
    getImages()
  }, [getImages])

  useEffect(() => {
    if (!imageId) {
      setCurrentImage(undefined)
      return
    }

    setIsLoading(true)

    imagesRepository.get(imageId)
      .then(image => {
        setCurrentImage(image)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [addError, imageId])

  const closeUploadImageModal = useCallback(() => {}, [])

  const photosPagination = useMemo(() => {
    if (images.length === 0 || totalPages <= 1) return null

    return (
      <div className="pagination">
        <span>{ t('sets.pagination_page') }</span>
        {
          [...Array(totalPages)].map((p, i) => {
            const link = imageId
              ? `/photos/${imageId}?page=${i + 1}`
              : `/photos?page=${i + 1}`

            return (
              <Link
                key={ i }
                className={ `button ${i + 1 === currentPage ? 'selected' : ''}` }
                to={ link }
              >
                { i + 1 }
              </Link>
            )
          })
        }
      </div>
    )
  }, [images.length, totalPages, t, imageId, currentPage])

  const photosView = useMemo(() => {
    if (isLoadingImages) {
      return <p>Loading...</p>
    }

    if (images.length === 0) {
      return <p>You have not uploaded any photos yet.</p>
    }

    return <>
      <ul className={ 'past-matches' }>
        {
          images.map((image) =>
            <li key={ image.id } className={ image.id === imageId ? 'selected' : '' }>
              <Link to={ `/photos/${image.id}?page=${currentPage}` }>
                <span className={ image.totalMatches && image.totalMatches > 0 ? '' : 'none' }>{ image.totalMatches ?? 0 }</span>
                <img src={ image.thumb2x } />
              </Link>
            </li>
          )
        }
      </ul>
      { photosPagination }
    </>
  }, [currentPage, imageId, images, isLoadingImages, photosPagination])

  const uploadImagesCallback = useCallback((image: Image) => {
    if (images.length === 30) setTotalPages(p => p + 1)

    if (currentPage === 1) {
      setImages(im => {
        const imBumpLast = im.length === 30 ? im.slice(0, -1) : im
        return [image, ...imBumpLast ?? []]
      })
    } else {
      // TODO: return to page 1
    }

    navigate(`/photos/${image.id}`)
  }, [currentPage, navigate, images.length])

  return (
    <div>
      <div className='intro'>
        <div className='left'>
          <h1>Magic Match</h1>
          <p>
            Upload a photo of your Pokémon cards and Magic Match will detect any cards it finds in the photo.
          </p>
          {
            !currentImage
              ? <>
                <ol className='explanation'>
                  <li>
                    <div className='image camera'></div>
                    <span>Upload a photo</span>
                  </li>
                  <li>
                    <div className='image wand'></div>
                    <span>HOLIC magic</span>
                  </li>
                  <li>
                    <div className='image cards'></div>
                    <span>Select your cards</span>
                  </li>
                </ol>
                <p>
                  <strong>This is a beta feature.</strong> We are continuously improving Magic Match, so if your cards can&apos;t be found, check back soon to try again.
                </p>
              </>
              : null
          }
        </div>
        {
          !currentImage
            ? <div className='right'></div>
            : null
        }
      </div>
      <hr />
      {
        isLoading
          ? <p>Loading...</p>
          : currentImage
            ? (
              <>
                <PhotoIndividual image={ currentImage } matchesConfirmed={ matchesConfirmed } setMatchesConfirmed={ setMatchesConfirmed } />
                { /* <p>
                  <Link to="/photos" className={ 'button tool' }>All photos</Link>
                </p> */ }
                <hr />
              </>
              )
            : null
      }
      <h2>Start here!</h2>
      <ImageUploadModalGcp
        callback= { uploadImagesCallback }
        onClose={ closeUploadImageModal }
        showCancelButton={ false }
      />
      <hr />
      <h2>Compatibility</h2>
      <ul className='compatibility'>
        <li className='ja96'>
          <div className='image'></div>
          <div className='text'>
            <span className='language'>JA</span>
            <span className='date'>&apos;96 - &apos;02</span>
          </div>
        </li>
        <li className='en99'>
          <div className='image'></div>
          <div className='text'>
            <span className='language'>EN</span>
            <span className='date'>&apos;99 - &apos;02</span>
          </div>
        </li>
        <li>
          <div className='image'></div>
          <div className='text'>
            <span>More coming soon!</span>
          </div>
        </li>
      </ul>
      <hr />
      <h2>Tips</h2>
      <ul className='tips'>
        <li className='ok img1'>
          <div className='image'></div>
          <span>Top-down</span>
        </li>
        <li className='ok img2'>
          <div className='image'></div>
          <span>Multiple cards</span>
        </li>
        <li className='ng img3'>
          <div className='image'></div>
          <span>Rotated</span>
        </li>
        <li className='ng img4'>
          <div className='image'></div>
          <span>Skewed</span>
        </li>
        <li className='ng img5'>
          <div className='image'></div>
          <span>Out of focus</span>
        </li>
      </ul>
      <hr />
      <h2>Past Magic Matches</h2>
      { photosView }
    </div>
  )
}

type MatchesProps = Readonly<{
  match: Match
  matchesLength: number
  index: number
  cardSettings: CardSettings
  callback: (number) => void
  matchesConfirmed: ImageUnit[]
}>

const Matches = ({ match, matchesLength, index, cardSettings, callback, matchesConfirmed }: MatchesProps) => {
  const confirmedMatch = useMemo(() => {
    const m = matchesConfirmed.find(m => m.matchId === match.id)

    if (m) {
      return m
    }

    return null
  }, [match.id, matchesConfirmed])

  const confirmedMatchItem = useMemo(() => {
    if (confirmedMatch) {
      return match.items.find(i => i.id === confirmedMatch.itemId)
    }

    return null
  }, [confirmedMatch, match.items])

  const addRemoveCallback = useCallback((c) => {
    const mc: ImageUnit = {
      matchId: match.id,
      itemId: c.itemId,
      unitId: c.id,
    }

    callback(mc)
  }, [callback, match.id])

  const matchTitle = useMemo(() => {
    return matchesLength > 1
      ? <>Match #{ (index + 1) }</>
      : <>Match</>
  }, [index, matchesLength])

  const undoMatch = useCallback(() => {
    if (!confirmedMatch) return

    if (confirm('Are you sure? This will remove 1x this card from your collection.')) {
      const mc: ImageUnit = {
        ...confirmedMatch,
        itemId: undefined,
      }

      callback(mc)
    }
  }, [callback, confirmedMatch])

  const undoMatchButton = useMemo(() => {
    if (!confirmedMatch) return

    return <button onClick={ undoMatch } className={ 'tool' }>Change selection</button>
  }, [confirmedMatch, undoMatch])

  if (confirmedMatch) {
    return <p>
      <strong>{ matchTitle }</strong>: {
        confirmedMatchItem
          ? <>You selected <strong>{ confirmedMatchItem.nameEn }</strong>. { undoMatchButton }</>
          : <>No match found. { undoMatchButton }</>
      }
    </p>
  }

  return <>
    <h2>{ matchTitle }</h2>
    {
      match.items.length > 1
        ? <p>
          Magic Match found <strong>{ match.items.length }</strong> possible matches for this card. Add the closest match. <button className="tool" onClick={ () => { addRemoveCallback({ itemId: null }) } }>My match is not here</button>
        </p>
        : match.items.length === 1
          ? <p>Got a match! Add it to your collection if it seems correct.</p>
          : <p>Found a card, but couldn&apos;t find a close match.</p>
    }
    <ul className={ 'cards-list matches' }>
      {
        match.items.map((item) => {
          return <CardSingle key={ item.units && item.units.length > 0 ? item.units?.[0].id : `card-${String(item.id)}` } item={ item } cardSettings={ cardSettings } addRemoveCallback={ (c) => { addRemoveCallback(c) } } />
        })
      }
    </ul>
  </>
}

type PhotoIndividualProps = Readonly<{
  image: Image
  matchesConfirmed: ImageUnit[]
  setMatchesConfirmed: (number) => void
}>

const PhotoIndividual = ({ image, matchesConfirmed, setMatchesConfirmed }: PhotoIndividualProps) => {
  const { addError } = useContext(ErrorsContext)

  useEffect(() => {
    if (!image.imageUnits) return

    const m = image.imageUnits.map((imageUnit) => {
      const mc: ImageUnit = {
        matchId: imageUnit.matchId, // todo: replaceme
        itemId: imageUnit.itemId,
        unitId: imageUnit.unitId,
        id: imageUnit.id,
      }

      return mc
    })

    setMatchesConfirmed(m)
  }, [image, setMatchesConfirmed])

  const createImageUnit = useCallback((matchConfirmation: ImageUnit) => {
    imageUnitsRepository
      .create({
        imageId: image.id,
        params: {
          matchId: matchConfirmation.matchId,
          unitId: matchConfirmation.unitId,
        },
      })
      .then((params) => {
        setMatchesConfirmed((m) => m.map((m2) => {
          return {
            ...m2,
            id: m2.matchId === matchConfirmation.matchId ? params.id : m2.id,
          }
        }))
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        // set loading to false?
      })
  }, [addError, image.id, setMatchesConfirmed])

  const callback = useCallback((matchConfirmation: ImageUnit) => {
    setMatchesConfirmed((m) => {
      const matchIndex = m.findIndex(x => x.matchId === matchConfirmation.matchId)

      if (matchConfirmation.id && !matchConfirmation.itemId) {
        if (m[matchIndex]?.id) {
          imageUnitsRepository
            .delete({
              imageId: image.id,
              id: m[matchIndex].id ?? 0,
            })
            .catch((err: AxiosError) => {
              addError?.(err)
            })
            .finally(() => {
              // set loading to false?
            })
        }

        return m.filter((a) => a.matchId !== matchConfirmation.matchId)
      }

      // if (matchIndex >= 0) {
      //   return m.splice(matchIndex, 1, matchConfirmation)
      // }

      createImageUnit(matchConfirmation)

      return [...m, matchConfirmation]
    })
  }, [addError, createImageUnit, image.id, setMatchesConfirmed])

  const cardSettings: CardSettings = useMemo(() => {
    return {
      conditionSelection: false,
      defaultCondition: 0,
      canCollect: true,
      imageResolution: '2x',
    }
  }, [])

  const imageArea = useMemo(() => {
    let canvasWidth = 400
    const canvasHeight = 400
    if (image.width && image.height) {
      canvasWidth = canvasHeight * image.width / image.height
    }

    return <>
      <Canvas width={ canvasWidth } height={ canvasHeight } src={ image.thumb2x ?? '' } key={ image.id } data={ image.matches } />
    </>
  }, [image.height, image.id, image.matches, image.thumb2x, image.width])

  const matches = useMemo(() => {
    const matches = image.matches

    if (!matches || matches.length === 0) {
      return (
        <>
          <h2>No matches found</h2>
          <p>
            Magic Match couldn&apos;t find any cards in this photo.
          </p>
          <p>
            Please check below to make sure your cards are compatible, and try with a new photo.
          </p>
          <p><Link to='/photos' className='button tool'>Close</Link></p>
        </>
      )
    }

    return <>
      {
        matches.map((match, i) => <Matches key={ match.id } match={ match } matchesLength={ matches?.length } index={ i } cardSettings={ cardSettings } callback={ callback } matchesConfirmed={ matchesConfirmed } />)
      }
      {
        matchesConfirmed.length === matches?.length
          ? <>
            <hr />
            <p>Magic Match is complete.</p>
            <p><Link to='/photos' className='button tool'>Close</Link></p>
          </>
          : null
      }
    </>
  }, [callback, cardSettings, image.matches, matchesConfirmed])

  return <div className='current-matches'>
    <div className='left'>
      { imageArea }
    </div>
    <div className='right'>
      { matches }
    </div>
  </div>
}

export { Photos }
