import { AxiosError } from 'axios'
import { useMemo, useCallback, useContext, useState, useEffect, Suspense, lazy } from 'react'
import { useMatch, Link } from 'react-router-dom'
import { ErrorsContext } from '../contexts/errors_context'
import { t } from 'i18next'
import { Image, Item, Listing, Tag, Unit } from '../types'
import { unitsRepository } from '../repositories/units_repository'
// import { itemsRepository } from '../repositories/items_repository'
import { ModalWrapper } from './modal_wrapper'
import { GenericNotFound } from './generic_not_found'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { SettingsContext } from '../contexts/settings_context'
import { ListingSingle } from './listing_single'
import Select from 'react-select'
import { conditions } from '../lib/conditions'
import { useTranslation } from 'react-i18next'

import {
  LineShareButton,
  TwitterShareButton,
} from 'react-share'

import '../stylesheets/Sell.scss'
import { listingsRepository } from '../repositories/listings_repository'
import clsx from 'clsx'
import { unitImagesRepository } from '../repositories/unit_images_repository'
import { AuthContext } from '../contexts/auth_context'

type ListingHolicForm = Readonly<{
  id?: number
  platform: string
  priceJpy: number
  title?: string
}>

type UnitForm = Readonly<{
  condition: number
  description: string
}>

const DirtyModal = ({ children }) => {
  return <ModalWrapper>
    <h1>変更を破棄しますか？</h1>
    <p>変更を適用するには編集画面から行ってください。</p>
    { children }
  </ModalWrapper>
}

type SavedModalProps = Readonly<{
  active: boolean
  children: any
}>

const SavedModal = ({ active, children }: SavedModalProps) => {
  return <ModalWrapper>
    <h1>変更されました</h1>
    {
      active
        ? <>
          <p>出品情報の更新は完了していません。</p>
          <p><strong>「出品情報を更新する」</strong>を押して出品情報の更新を完了してください。</p>
        </>
        : <>
          <p>出品は完了していません。</p>
          <p>出品の準備ができましたら<strong>「出品する」</strong>を押してください。</p>
        </>
    }
    { children }
  </ModalWrapper>
}

type ListingPriceEntryProps = Readonly<{
  makeDirty: (dirty: boolean) => void
  callback: (listing: Listing, s?: string) => void
  item: Item
  listing: Listing
  loading: boolean
  tag?: Tag
}>

const ListingPriceEntry = ({ callback, makeDirty, item, loading, listing, tag }: ListingPriceEntryProps) => {
  const [showPriceAdvice, setShowPriceAdvice] = useState<boolean>(false)

  const {
    register,
    control,
    handleSubmit,
  } = useForm<ListingHolicForm>({
    defaultValues: {
      ...listing,
      priceJpy: listing.priceJpy < 1 ? undefined : listing.priceJpy,
    },
  })

  const priceJpy = useWatch({
    control,
    name: 'priceJpy',
  })

  useEffect(() => {
    const dirty = listing.priceJpy !== priceJpy

    makeDirty(dirty)
  }, [listing.priceJpy, makeDirty, priceJpy])

  const [showSavedScreen, setShowSavedScreen] = useState<boolean>(false)

  const onSubmit = useCallback((params) => {
    const listing: Listing = {
      ...params,
    }

    makeDirty(false)

    if (showSavedScreen) {
      // saved from desktop
      callback(listing, 'price')
    } else {
      // saved from mobile
      callback(listing)
    }
  }, [callback, makeDirty, showSavedScreen])

  const feeRate = 0.064
  const minFee = 50

  const fee = useMemo(() => {
    if (!priceJpy) return 0

    const f = Math.ceil(priceJpy * feeRate)
    return f < minFee ? minFee : f
  }, [priceJpy])

  const priceLessFee = useMemo(() => {
    if (!priceJpy || priceJpy < fee) {
      return 0
    }

    return priceJpy - fee
  }, [fee, priceJpy])

  return <form onSubmit={ handleSubmit(onSubmit) } className='full-height'>
    {
      !showSavedScreen
        ? null
        : <SavedModal active={ !!listing.id }>
          <p>
            <button className='full' onClick={ _ => setShowSavedScreen(false) }>OK!</button>
          </p>
        </SavedModal>
    }
    {
      !showPriceAdvice
        ? null
        : <ModalWrapper>
          <h1>How to choose a price for your card</h1>
          <p>text</p>
          <button className='full' onClick={ _ => setShowPriceAdvice(false) }>Got it!</button>
        </ModalWrapper>
    }
    <h1 className='hide-mobile'>値段</h1>
    <p className='match-title hide-desktop'>
      <strong>{ item.nameJa }</strong>
      {
        tag
          ? <span>{ tag.nameJa }</span>
          : null
      }
    </p>
    <ul className='organised'>
      <li>
        <label>値段を設定する</label>
        <input className='half' type='number' { ...register('priceJpy') } min={ 0 } max={ 999999999 } />
      </li>
    </ul>
    { /* <p>Price recommendations coming soon!</p> */ }
    { /* <p>
      <a href='#' onClick={ e => { e.preventDefault(); setShowPriceAdvice(true) } }>値段の決め方を見る →</a>
    </p> */ }
    <div className='bottom-element'>
      <strong>商品が購入されたとき</strong>
      <ul className='editable'>
        <li>
          <div>
            <span>販売手数料</span>
          </div>
          <span>{ fee.toLocaleString() }円</span>
        </li>
        { /* <li>
          <div>
            <strong>新規ユーザーキャンペーン！</strong><br />
            販売利益が1万円に達するまで販売手数料はかかりません。<a>詳しく見る</a>
          </div>
        </li> */ }
        <li>
          <span>販売利益</span>
          <span>{ priceLessFee.toLocaleString() }円</span>
        </li>
      </ul>
      <p className='hide-mobile'>
        <button
          className={ clsx('full', { white: priceJpy === listing.priceJpy }) }
          disabled={ !priceJpy || priceJpy < 1 || loading }
          onClick={ _ => { setShowSavedScreen(true) } }
        >
          { Number(priceJpy ?? 0).toLocaleString() }円に設定する
        </button>
      </p>
      <p className='hide-desktop'>
        <button className={ clsx('full') } disabled={ !priceJpy || priceJpy < 1 || loading } onClick={ _ => { setShowSavedScreen(false) } }>{ Number(priceJpy ?? 0).toLocaleString() }円に設定する</button>
      </p>
    </div>
  </form>
}

type ListingDescriptionProps = Readonly<{
  callback: (unit: Unit, s?: string) => void
  loading: boolean
  isDirty: boolean
  unit: Unit
  listing: Listing
  makeDirty: (dirty: boolean) => void
}>

const ListingDescription = ({ callback, loading, isDirty, makeDirty, unit, listing }: ListingDescriptionProps) => {
  const { t } = useTranslation()

  const [showSavedScreen, setShowSavedScreen] = useState<boolean>(false)

  const {
    register,
    control,
    handleSubmit,
    getValues,
  } = useForm<UnitForm>({
    defaultValues: unit,
  })

  const condition = useWatch({
    control,
    name: 'condition',
  })

  const description = useWatch({
    control,
    name: 'description',
  })

  const onSubmit = useCallback((params) => {
    const ui: Unit = {
      ...unit,
      condition: params.condition,
      description: params.description,
    }

    makeDirty(false)

    if (showSavedScreen) {
      // saved from desktop
      callback(ui, 'description')
    } else {
      // saved from mobile
      callback(ui)
    }
  }, [callback, makeDirty, showSavedScreen, unit])

  const conditionsForForm = useMemo(() => {
    const c = conditions.filter(c => c.displayDefault).map(c => {
      return {
        value: c.id,
        label: t(`collections.conditions.long.${c.id}`),
      }
    })

    return c
  }, [t])

  const [showDirtyScreen, setShowDirtyScreen] = useState<boolean>(false)

  const goBack = useCallback(() => {
    if (!isDirty) {
      callback(unit)
      return
    }

    setShowDirtyScreen(true)
  }, [callback, isDirty, unit])

  const unDirty = useCallback(() => {
    makeDirty(false)

    callback(unit)

    setShowDirtyScreen(false)
  }, [callback, makeDirty, unit])

  useEffect(() => {
    const dirty = (
      condition !== unit.condition ||
      description !== unit.description
    )

    makeDirty(dirty)
  }, [condition, description, makeDirty, unit.condition, unit.description])

  return <>
    <form onSubmit={ handleSubmit(onSubmit) } className='full-height'>
      {
        !showSavedScreen
          ? null
          : <SavedModal active={ !!listing.id }>
            <p>
              <button className='full' onClick={ _ => setShowSavedScreen(false) }>OK!</button>
            </p>
          </SavedModal>
      }
      {
        !showDirtyScreen
          ? null
          : <DirtyModal>
            <p>
              <button className='full white' onClick={ unDirty }>変更を破棄する</button>
            </p>
            <p>
              <button className='full' onClick={ _ => setShowDirtyScreen(false) }>編集を続ける</button>
            </p>
          </DirtyModal>
      }
      <h1>説明と状態</h1>
      <div className='select'>
        <label>状態</label>
        <Controller
          control={ control }
          name='condition'
          render={ ({ field }) => (
            <Select
              options={ conditionsForForm }
              onChange={ val => field.onChange(val?.value) }
              placeholder={ t('defaults.select') }
              value={ conditionsForForm.find(s => s.value === getValues('condition')) }
            />
          ) }
        />
      </div>
      <p>
        <label>説明</label>
        <textarea { ...register('description') } rows={ 8 } />
      </p>
      <div className='bottom-element'>
        <p className='hide-mobile'>
          <button className={ clsx('full', { white: !isDirty }) } disabled={ loading } onClick={ _ => { setShowSavedScreen(true) } }>変更する</button>
        </p>
        <p className='hide-desktop'>
          <button className={ clsx('full', { white: !isDirty }) } disabled={ loading } onClick={ _ => { setShowSavedScreen(false) } }>変更する</button>
        </p>
        <p className='hide-desktop'>
          <button className='full white' disabled={ loading } onClick={ e => { e.preventDefault(); goBack() } }>← 戻る</button>
        </p>
      </div>
    </form>
  </>
}

type ListingImagesProps = Readonly<{
  callback: (unit: Unit, s?: string) => void
  loading: boolean
  unit: Unit
}>

const ListingImages = ({ callback, unit }: ListingImagesProps) => {
  // const { t } = useTranslation()
  const ImageUploadModal = lazy(() => import('./image_upload_modal'))

  const [images, setImages] = useState<Image[]>([])

  const goBack = useCallback(() => {
    const newUnit: Unit = {
      ...unit,
      images,
    }

    callback(newUnit, undefined)
  }, [callback, images, unit])

  useEffect(() => {
    if (images.length > 0) return

    setImages(unit?.images ?? [])
  }, [images.length, unit.images])

  const afterImageUploaded = useCallback((im: Image) => {
    const newImages = images.concat([im])
    setImages(newImages)

    const newUnit: Unit = {
      ...unit,
      images: newImages,
    }

    callback(newUnit, 'images')
  }, [callback, images, unit])

  const onClose = useCallback(() => {
  }, [])

  const { addError } = useContext(ErrorsContext)

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [imageToDelete, setImageToDelete] = useState<Image|undefined>()

  const deleteImage = useCallback((imageId: number) => {
    if (loadingDelete) return
    if (!unit?.id) return

    setImageToDelete(undefined)
    setLoadingDelete(true)

    unitImagesRepository
      .delete(unit.id, imageId)
      .then(() => {
        const newImages = images.filter(im => im.id !== imageId)
        setImages(newImages)

        const newUnit: Unit = {
          ...unit,
          images: newImages,
        }

        callback(newUnit, 'images')

        setImageToDelete(undefined)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }, [addError, callback, images, loadingDelete, unit])

  const imagesCarousel = useMemo(() => {
    if (!images ?? images.length === 0) return

    return <div className='selling-images'>
      {
        images.map((image) => {
          return <div key={ image.id } className='image'>
            <img src={ image.thumb2x } />
            <button className='tool' onClick={ _ => setImageToDelete(image) }>削除</button>
          </div>
        })
      }
    </div>
  }, [images])

  return <div className='full-height'>
    {
      !imageToDelete
        ? null
        : <ModalWrapper>
          <h1>画像の削除</h1>
          <p>本当にこの画像を削除しますか？</p>
          <img className='image-to-delete' src={ imageToDelete.thumb2x } />
          <p>
            <button className='full white' onClick={ _ => deleteImage(imageToDelete.id) } disabled={ loadingDelete }>削除する</button>
          </p>
          <p>
            <button className='full' onClick={ _ => setImageToDelete(undefined) } disabled={ loadingDelete }>キャンセル</button>
          </p>
        </ModalWrapper>
    }
    <h1>画像</h1>
    {
      images.length > 0
        ? imagesCarousel
        : null
    }
    <Suspense fallback={<div>読み込み中…</div>}>
      <ImageUploadModal unit={ unit } onClose={ onClose } callback={ afterImageUploaded } showCancelButton={ false } />
    </Suspense>
    <div className='bottom-element hide-desktop'>
      <p>
        <button className='full white' onClick={ goBack }>← 戻る</button>
      </p>
    </div>
  </div>
}

type ListingPreviewProps = Readonly<{
  changeScreenCallback: (screen: string) => void
  showConfirmScreenCallback: () => void
  showCancelModalCallback: () => void
  item: Item
  loading: boolean
  listing: Listing
  unit: Unit
}>

const ListingPreview = ({ changeScreenCallback, showConfirmScreenCallback, showCancelModalCallback, item, listing, unit }: ListingPreviewProps) => {
  const setScreen = useCallback((screen: string) => {
    changeScreenCallback(screen)
  }, [changeScreenCallback])

  return <>
    <p className='top-left'>
      <button className='small white' onClick={ _ => setScreen('price') }>← 値段を編集</button>
      {
        listing.id
          ? <button className='small top-right white' onClick={ showCancelModalCallback }>出品をキャンセルする</button>
          : null
      }
    </p>
    <div className='preview-area hide-desktop'>
      <ul className='listings single'>
        <ListingSingle items={ [item] } unit={ unit } editing={ false } />
      </ul>
    </div>
    <div className='bottom-element'>
      <p>
        <button className='full white' onClick={ _ => setScreen('images') }>画像を編集する</button>
      </p>
      <p>
        <button className='full white' onClick={ _ => setScreen('description') }>説明と状態を編集する</button>
      </p>
      <p>
        <button className='full' onClick={ showConfirmScreenCallback }>
          {
            listing.id
              ? '出品情報を更新する'
              : '出品する'
          }
        </button>
      </p>
      {
        <div className='desktop'>
          <hr />
          <p>
            <strong>このカードは出品中です</strong>
          </p>
          <p>
            <button className='small white' onClick={ showCancelModalCallback }>出品をキャンセルする</button>
          </p>
        </div>
      }
    </div>
  </>
}

type ListingCancelledProps = Readonly<{
  unit: Unit
}>

const ListingCancelled = ({ unit }: ListingCancelledProps) => {
  const { t } = useTranslation()

  return <div className='full-height'>
    <h1>{ t('listings.cancel_modal.title') }</h1>
    <p>{ t('listings.cancel_modal.body') }</p>
    <div className='bottom-element'>
      <p>
        <Link to={ `/u/${unit.userId ?? ''}/units/${unit.id ?? ''}` } className={ clsx('button full') }>{ t('listings.cancel_modal.link1') }</Link>
      </p>
      <p>
        <Link to={ `/u/${unit.userId ?? ''}` } className={ clsx('button full white') }>{ t('listings.cancel_modal.link2') }</Link>
      </p>
    </div>
  </div>
}

type ListingFinishedProps = Readonly<{
  unit: Unit
  item: Item
  listing: Listing
  isNew: boolean
  series?: Tag
}>

const ListingFinished = ({ item, listing, isNew, unit, series }: ListingFinishedProps) => {
  const { t } = useTranslation()

  const shareButtonProps = useCallback((snsName: string) => {
    const seriesText = series ? `(${series.nameJa ?? ''})` : ''

    return {
      className: `share share-${snsName} tool`,
      resetButtonStyle: false,
      quote: `HOLICで${item.nameJa}${seriesText}を${listing.priceJpy}円で販売しています`,
      url: `https://holic.net/u/${unit.userId ?? 0}/units/${unit.id ?? 0}`,
    }
  }, [item.nameJa, listing.priceJpy, series, unit.id, unit.userId])

  return <div className='full-height'>
    <h1>{
      isNew
        ? '出品が完了しました！'
        : '出品情報が更新されました！'
    }</h1>
    <div className='information'>
      <h2>カードは以下の2ヶ所に出品されています</h2>
      <p>
        <strong>1. HOLICマーケット</strong><br />
        <p>HOLICマーケットでは、どなたでもカードを買ったり売ったりできます。</p>
      </p>
      <p>
        <strong>2. マイショップ</strong><br />
        <p>あなた専用のショップです。下記「マイショップを見る」ボタンからご確認ください。</p>
      </p>
    </div>
    <div className='bottom-element'>
      <p className='multiple-buttons'>
        <LineShareButton { ...shareButtonProps('line') } className='full white'>
          { t('modal.share_collection.line') }
        </LineShareButton>
        <TwitterShareButton { ...shareButtonProps('twitter') } className='full white'
          hashtags={ [
            t('collections.share.twitter_hashtag1'),
            t('collections.share.twitter_hashtag2')
          ] }>
          { t('modal.share_collection.twitter') }
        </TwitterShareButton>
      </p>
      <p>
        <Link to={ `/u/${unit.userId ?? 'me'}` } className='button full'>マイショップを見る</Link>
      </p>
      { /* <p>
        <Link to={ '/match' } className='button full'>もう一回マッチする</Link>
      </p> */ }
    </div>
  </div>
}

type ListingWrapperProps = Readonly<{
  changeScreenCallback: (screen: string) => void
  showConfirmScreenCallback: () => void
  makeDirty: (dirty: boolean) => void
  unit: Unit
  item: Item
  listing: Listing
  tag?: Tag
  children: any
  screen: string
  isDirty: boolean
}>

const ListingWrapper = ({ changeScreenCallback, showConfirmScreenCallback, makeDirty, isDirty, unit, item, tag, listing, children, screen }: ListingWrapperProps) => {
  const [showDirtyScreen, setShowDirtyScreen] = useState<string|undefined>()

  const nav = useCallback((s: string) => {
    if (isDirty) {
      setShowDirtyScreen(s)
      return
    }

    changeScreenCallback(s)
  }, [changeScreenCallback, isDirty])

  const unDirty = useCallback(() => {
    if (!showDirtyScreen) return

    if (showDirtyScreen === 'finished') {
      // ready to list
      showConfirmScreenCallback()
    } else {
      makeDirty(false)
      changeScreenCallback(showDirtyScreen)
    }

    // just in case
    setShowDirtyScreen(undefined)
  }, [changeScreenCallback, makeDirty, showConfirmScreenCallback, showDirtyScreen])

  const confirmList = useCallback(() => {
    if (isDirty) {
      setShowDirtyScreen('finished')
      return
    }

    showConfirmScreenCallback()
  }, [isDirty, showConfirmScreenCallback])

  return <>
    {
      !showDirtyScreen
        ? null
        : <DirtyModal>
          <p>
            <button className='full white' onClick={ unDirty }>変更を破棄する</button>
          </p>
          <p>
            <button className='full' onClick={ _ => setShowDirtyScreen(undefined) }>編集を続ける</button>
          </p>
        </DirtyModal>
    }
    <div className='slim hide-mobile'>
      <p className='match-title'>
        <strong>{ item.nameJa }</strong>
        {
          tag
            ? <span>{ tag.nameJa }</span>
            : null
        }
      </p>
    </div>
    <div className='slim'>
      <div className='left hide-mobile skinny preview-area'>
        <ul className='listings single'>
          <ListingSingle items={ [item] } unit={ unit } editing={ false } />
        </ul>
        {
          listing.priceJpy > 0
            ? <>
              <div className='checklist'>
                <button
                  className={ clsx({ done: unit.images && unit.images.length > 0 }) }
                  onClick={ _ => nav('images') }
                  disabled={ screen === 'images' }
                >
                  画像を編集する
                </button>
                <button
                  className={ clsx({ done: unit.condition > 0 }) }
                  onClick={ _ => nav('description') }
                  disabled={ screen === 'description' }
                >
                  説明と状態を編集する
                </button>
                <button
                  className={ clsx({ done: listing.priceJpy > 0 }) }
                  onClick={ _ => nav('price') }
                  disabled={ screen === 'price' }
                >
                  値段を編集する
                </button>
              </div>
              {
                screen === 'finished'
                  ? null
                  : <p>
                    <button
                      className={ clsx('full', { white: !(unit.images && unit.images.length > 0) || !unit.condition }) }
                      onClick={ confirmList }
                    >
                      {
                        listing.id
                          ? '出品情報を更新する'
                          : '出品する'
                      }
                    </button>
                  </p>
              }
            </>
            : null
        }
      </div>
      <div className='right fat full-height'>
        { children }
      </div>
    </div>
  </>
}

const ListingCreate = () => {
  const { setMinimalNavigation } = useContext(SettingsContext)
  const { addError } = useContext(ErrorsContext)

  useEffect(() => {
    if (!setMinimalNavigation) return

    setMinimalNavigation(true)

    return () => {
      setMinimalNavigation(false)
    }
  }, [setMinimalNavigation])

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSubscreen, setLoadingSubscreen] = useState<boolean>(false)

  const { currentUser } = useContext(AuthContext)

  const [item, setItem] = useState<Item|undefined>()
  const [unit, setUnit] = useState<Unit|undefined>()
  const [listing, setListing] = useState<Listing|undefined>()

  const paramsListing = useMatch('/items/:item_id/listing')?.params
  const paramsUnit = useMatch('/items/:item_id/unit/:unit_id/listing')?.params

  const itemId = useMemo<number|undefined>(() => {
    const id = parseInt(paramsListing?.item_id ?? '')
    if (isNaN(id)) {
      const id2 = parseInt(paramsUnit?.item_id ?? '')
      return isNaN(id2) ? undefined : id2
    }

    return id
  }, [paramsListing?.item_id, paramsUnit?.item_id])

  const unitId = useMemo<number|undefined>(() => {
    const id = parseInt(paramsUnit?.unit_id ?? '')
    return isNaN(id) ? undefined : id
  }, [paramsUnit])

  const getUnit = useCallback((id: number) => {
    unitsRepository
      .get(id)
      .then(({ unit }) => {
        setUnit(unit)

        if (unit.items && unit.items.length > 0) {
          setItem(unit.items[0])
        }
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError])

  // const getItem = useCallback((id: number) => {
  //   itemsRepository
  //     .get(id)
  //     .then(({ item }) => {
  //       setItem(item)
  //     })
  //     .catch((err: AxiosError) => {
  //       addError?.(err)
  //     })
  // }, [addError])

  useEffect(() => {
    if (!unitId) return

    getUnit(unitId)
  }, [getUnit, unitId])

  // useEffect(() => {
  //   // getUnit will include item anyway
  //   if (unitId) return

  //   if (!itemId) return

  //   getItem(itemId)
  // }, [getItem, itemId, unitId])

  useEffect(() => {
    if (!unit) return

    if (listing) return

    const holicListing = unit.listings?.filter(l => l.platform === 'holic') ?? []

    if (holicListing.length > 0) {
      setListing(holicListing[0])
      return
    }

    // create a new listing
    const newListing: Listing = {
      platform: 'holic',
      priceJpy: 0,
    }

    setListing(newListing)
  }, [listing, unit])

  const setNewUnit = useCallback((unit?: Unit) => {
    const items = unit?.items
      ? unit.items
      : item
        ? [item]
        : []

    const newUnit: Unit = {
      ...unit,
      condition: unit?.condition ?? 0,
      verified: unit?.verified ?? false,
      items: items,
    }

    if (unit?.id) {
      // get user item (for images etc)
      setLoading(true)
      getUnit(unit.id)
    }

    setUnit(newUnit)
  }, [getUnit, item])

  useEffect(() => {
    if (!item) return
    if (unit ?? unitId) return

    // no user item was requested
    setLoading(false)

    if (item.units && item.units?.length > 0) {
      // allow user to choose which unit
      return
    }

    // make a new one
    setNewUnit()
  }, [item, setNewUnit, unit, unitId])

  useEffect(() => {
    if (item && unit && listing) {
      setLoading(false)
    }
  }, [item, listing, unit])

  const listingPriceEntryCallback = useCallback((listing: Listing, s?: string) => {
    if (!unit || !item) return

    if (!unit.id) {
      // create the new unit here

      if (loadingSubscreen) return

      setLoadingSubscreen(true)

      unitsRepository
        .create({
          ...unit,
          itemIds: [item.id],
        })
        .then(({ unit }) => {
          setUnit(unit)

          setListing(listing)
          setScreen(s)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoadingSubscreen(false)
        })
    } else {
      setListing(listing)
      setScreen(s)
    }
  }, [addError, item, loadingSubscreen, unit])

  const descriptionCallback = useCallback((ui: Unit, s?: string) => {
    if (!unit?.id) return

    // if (loadingSubscreen) return

    // setLoadingSubscreen(true)

    setUnit(ui)
    setScreen(s)

    // unitsRepository
    //   .update({
    //     id: unit.id,
    //     params: {
    //       description: ui.description ?? '',
    //       condition: ui.condition,
    //     },
    //   })
    //   .then(() => {
    //     setUnit(ui)
    //     setScreen(undefined)
    //   })
    //   .catch((err: AxiosError) => {
    //     addError?.(err)
    //   })
    //   .finally(() => {
    //     setLoadingSubscreen(false)
    //   })
  }, [unit?.id])

  const imagesCallback = useCallback((ui: Unit, s?: string) => {
    setUnit(ui)
    setScreen(s)
  }, [])

  const [screen, setScreen] = useState<string|undefined>()

  const changeScreenCallback = useCallback((screen: string) => {
    setScreen(screen)
  }, [])

  const createOrUpdateListing = useCallback((unitId: number) => {
    if (!listing) return

    if (listing.id) {
      // update listing
      listingsRepository
        .update({
          id: listing.id,
          params: listing,
        })
        .then(() => {
          // done
          setScreen('updated')
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoadingSubscreen(false)
        })
    } else {
      // create listing
      listingsRepository
        .create({
          ...listing,
          unitId: unitId,
        })
        .then(({ listing }) => {
          // done
          setListing(listing)
          setScreen('created')
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoadingSubscreen(false)
        })
    }
  }, [addError, listing])

  const [showConfirmScreen, setShowConfirmScreen] = useState<boolean>(false)
  const showConfirmScreenCallback = useCallback(() => {
    setShowConfirmScreen(true)
  }, [])

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const showCancelModalCallback = useCallback(() => {
    setShowCancelModal(true)
  }, [])

  const confirmSaleCallback = useCallback(() => {
    if (!unit?.id) return

    if (loadingSubscreen) return

    setLoadingSubscreen(true)

    // update unit
    unitsRepository
      .update({
        id: unit.id,
        params: {
          description: unit.description ?? '',
          condition: unit.condition,
        },
      })
      .then(() => {
        // create or update listing
        createOrUpdateListing(unit.id ?? 0)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSubscreen(false)
        setShowConfirmScreen(false)
      })
  }, [addError, createOrUpdateListing, loadingSubscreen, unit])

  const series = useMemo(() => {
    if (!item) return

    return item.tags.length > 0 ? item.tags.reduce((prev, next) => prev.depth > next.depth ? prev : next) : undefined
  }, [item])

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const makeDirty = useCallback((dirty: boolean) => {
    setIsDirty(dirty)
  }, [])

  const condition = useMemo(() => {
    return unit && unit.condition > 0
      ? t(`collections.conditions.short.${unit.condition}`) + 'ランク'
      : t('collections.conditions.short.0')
  }, [unit])

  const unitForPreview = useMemo(() => {
    if (!listing || !unit) return unit

    const ui: Unit = {
      ...unit,
      listings: [listing],
    }

    return ui
  }, [listing, unit])

  const confirmCancelListing = useCallback(() => {
    if (!listing?.id) return

    if (loadingSubscreen) return

    setLoadingSubscreen(true)

    listingsRepository
      .destroy(listing.id)
      .then(() => {
        // done
        setScreen('cancelled')
        setShowCancelModal(false)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSubscreen(false)
      })
  }, [addError, listing, loadingSubscreen])

  const listingScreen = useMemo(() => {
    if (!unit || !listing || !item || !unitForPreview) return

    if (unit.public === false) return

    const screenLabel = screen === 'created' || screen === 'updated' ? 'finished' : screen ?? ''

    return <ListingWrapper
      screen={ screenLabel }
      unit={ unitForPreview }
      item={ item }
      listing={ listing }
      isDirty={ isDirty }
      makeDirty={ makeDirty }
      tag={ series }
      changeScreenCallback={ changeScreenCallback }
      showConfirmScreenCallback={ showConfirmScreenCallback }
    >
      {
        showCancelModal
          ? <ModalWrapper>
            <h1>出品をキャンセルしますか？</h1>
            <p>出品のキャンセルをすると、カードと画像はマイデッキに保存されます。その他の入力した情報はすべて失われます。</p>
            <p>
              <button className='full white' onClick={ confirmCancelListing } disabled={ loadingSubscreen }>キャンセルする</button>
            </p>
            <p>
              <button className='full' onClick={ _ => {
                setShowCancelModal(false)
              } } disabled={ loadingSubscreen }>出品を続ける</button>
            </p>
          </ModalWrapper>
          : null
      }
      {
        !showConfirmScreen
          ? null
          : <ModalWrapper>
            <h1>出品情報</h1>
            <ul className='editable'>
              <li>
                <span>カード</span>
                <span>{ item.nameJa }</span>
              </li>
              <li>
                <span>状態</span>
                <span>{ condition }</span>
              </li>
              <li>
                <span>値段</span>
                <span>{ Number(listing.priceJpy).toLocaleString() }円</span>
              </li>
            </ul>
            <p>
              <button className='full white' onClick={ _ => setShowConfirmScreen(false) } disabled={ loading }>修正する</button>
            </p>
            <p>
              <button className='full' onClick={ confirmSaleCallback } disabled={ loading }>
                {
                  listing.id
                    ? '出品情報を更新する'
                    : '出品する'
                }
              </button>
            </p>
          </ModalWrapper>
      }
      {
        screenLabel === 'finished'
          ? <ListingFinished unit={ unit } isNew={ screen === 'created' } item={ item } listing={ listing } series={ series } />
          : screenLabel === 'cancelled'
            ? <ListingCancelled unit={ unit } />
            : screenLabel === 'description'
              ? <ListingDescription unit={ unit } listing={ listing } isDirty={ isDirty } callback={ descriptionCallback } loading={ loadingSubscreen } makeDirty={ makeDirty } />
              : screenLabel === 'images'
                ? <ListingImages unit={ unit } callback={ imagesCallback } loading={ loadingSubscreen } />
                : screenLabel === 'price' || !listing.priceJpy || listing.priceJpy < 1
                  ? <ListingPriceEntry item={ item } listing={ listing } callback={ listingPriceEntryCallback } tag={ series } loading={ loadingSubscreen } makeDirty={ makeDirty } />
                  : <ListingPreview listing={ listing } item={ item } unit={ unitForPreview } changeScreenCallback={ changeScreenCallback } showConfirmScreenCallback={ showConfirmScreenCallback } showCancelModalCallback={ showCancelModalCallback } loading={ loadingSubscreen } />
      }
    </ListingWrapper>
  }, [changeScreenCallback, condition, confirmCancelListing, confirmSaleCallback, descriptionCallback, imagesCallback, isDirty, item, listing, listingPriceEntryCallback, loading, loadingSubscreen, makeDirty, screen, series, showCancelModal, showCancelModalCallback, showConfirmScreen, showConfirmScreenCallback, unit, unitForPreview])

  if (loading) {
    return <p>
      Loading...
    </p>
  }

  if (!unit) {
    if (item?.units) {
      if (!currentUser) return <></>

      return <ModalWrapper>
        <h1>カードを選んでください</h1>
        <p>このカードはすでにマイデッキに入っています。マイデッキ内にあるカードを売りますか？または、新しいカードを追加して売りますか？</p>
        <ul className='editable'>
          {
            item.units.map((unit) => {
              const condition = unit.condition > 0
                ? t(`collections.conditions.short.${unit.condition}`) + 'ランク'
                : t('collections.conditions.short.0')

              return <li key={ unit.id }>
                <span>
                  <Link target='_blank' to={ `/u/${currentUser.id}/units/${unit.id ?? 0}` }>{ item.nameJa }</Link> { condition }
                </span>
                <button className='tool' onClick={ _ => setNewUnit(unit) }>このカードを選ぶ</button>
              </li>
            })
          }
        </ul>
        <button className='full' onClick={ _ => setNewUnit() }>新しく追加する</button>
      </ModalWrapper>
    }
  }

  if (item?.id !== itemId) {
    return <GenericNotFound />
  }

  if (listingScreen) {
    return listingScreen
  }

  return <GenericNotFound />
}

export { ListingCreate }
