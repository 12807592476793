import { createContext, useState, useCallback, useEffect } from 'react'
import { getLocalIsAuthenticated, setLocalIsAuthenticated } from '../lib/local_storage'
import { sessionsRepository } from '../repositories/sessions_repository'
import { usersRepository } from '../repositories/users_repository'
import { Follow, UserSelf } from '../types'

const localIsAuthenticated = getLocalIsAuthenticated()

const createUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

const AuthContext = createContext<{
  sessionId: string
  isAuthenticated: boolean
  setIsAuthenticated?: (value: boolean) => void
  currentUser?: UserSelf
  setCurrentUser?: (value: UserSelf|undefined) => void
  currentUserFollowing: number[]
  setCurrentUserFollowing?: (value: number[]) => void
  userFollowings?: Follow[]
  setUserFollowings?: (value: Follow[]|undefined) => void
  reloadCurrentUser?: () => Promise<void>
  logout?: () => Promise<void>
}>({
      isAuthenticated: localIsAuthenticated,
      sessionId: '',
      currentUserFollowing: [],
    })

const AuthProvider = ({ children }) => {
  const sessionId = createUuid()
  const [isAuthenticated, _setIsAuthenticated] = useState<boolean>(localIsAuthenticated)
  const [currentUser, setCurrentUser] = useState<UserSelf>()
  const [currentUserFollowing, setCurrentUserFollowing] = useState<number[]>([])
  const [userFollowings, _setUserFollowings] = useState<Follow[]|undefined>()

  const setIsAuthenticated = useCallback((value: boolean) => {
    setLocalIsAuthenticated(value)
    _setIsAuthenticated(value)
  }, [])

  const setUserFollowings = useCallback((value?: Follow[]) => {
    _setUserFollowings(value)
  }, [])

  const getFollowsForUser = useCallback((userId: number) => {
    usersRepository
      .getFollowing(userId)
      .then((res) => {
        _setUserFollowings(res.follows)
      })
      .catch((error) => {
        alert(error)
      })
  }, [])

  const reloadCurrentUser = useCallback(async () => {
    if (!isAuthenticated) {
      setCurrentUser(undefined)
      return
    }

    try {
      const { user } = await usersRepository.getSelf()

      setCurrentUser(user)
    } catch (error) {
      alert(error)
    }
  }, [isAuthenticated])

  useEffect(() => {
    reloadCurrentUser()
  }, [reloadCurrentUser])

  useEffect(() => {
    if (!currentUser) return

    setCurrentUserFollowing(currentUser.tags)
  }, [currentUser])

  useEffect(() => {
    // if user is not authenticated, reset followers list
    if (!isAuthenticated) {
      _setUserFollowings(undefined)
      return
    }

    if (!currentUser) return

    if (userFollowings) return

    getFollowsForUser(currentUser.id)
  }, [currentUser, getFollowsForUser, isAuthenticated, userFollowings])

  const logout = useCallback(async () => {
    try {
      sessionStorage.removeItem('storedLocation')
      await sessionsRepository.destroy()
      setLocalIsAuthenticated(false)
      _setIsAuthenticated(false)
      setCurrentUser(undefined)
      window.location.assign('/')
    } catch (error) {
      alert(error)
    }
  }, [])

  return (
    <AuthContext.Provider value={ { sessionId, isAuthenticated, setIsAuthenticated, currentUser, setCurrentUser, reloadCurrentUser, logout, currentUserFollowing, setCurrentUserFollowing, setUserFollowings, userFollowings } }>
      { children }
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
