import clsx from 'clsx'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/auth_context'

import avatarDefault from '../img/avatar_default.png'
import avatarNatadecoco from '../img/avatars/natadecoco.png'
import avatarKomi from '../img/avatars/komi.png'
import avatarPi from '../img/avatars/pi.jpg'
import FollowButton from './follow_button'
import { SearchContext } from '../contexts/search_context'

type Props = Readonly<{
  platformName?: string
  userId?: string
  displayName: string
  salesLevel?: number
  salesRating?: number
  clickable?: boolean
}>

const UserBanner = ({ platformName, userId, displayName, salesLevel, salesRating, clickable }: Props) => {
  const { isAuthenticated } = useContext(AuthContext)

  const { endpointForExistingFilters, searchString, filters } = useContext(SearchContext)

  const { t } = useTranslation()

  const userRating = useMemo(() => {
    if (!salesLevel) {
      return <span className="rating new">{ t('cards.item.rating.new') }</span>
    }

    return (
      <>
        <span className="rating level">{ t('cards.item.rating.level', { level: salesLevel }) }</span> <span className={ `rating score l${salesRating ?? 0}` }></span>
      </>
    )
  }, [salesLevel, salesRating, t])

  const followButton = useMemo(() => {
    if (!isAuthenticated) return null

    return <FollowButton
      userId={ Number(userId) }
      displayName={ displayName }
    />
  }, [displayName, isAuthenticated, userId])

  const avatar = useMemo(() => {
    if (!userId) return avatarDefault

    switch (userId) {
      case '121':
        return avatarNatadecoco
      case '333':
        return avatarPi
      case '431':
        return avatarKomi
      default:
        return avatarDefault
    }
  }, [userId])

  const collectionLink = useCallback((path: string) => {
    if (!userId) return path

    if (!endpointForExistingFilters) return path

    const newUrl = endpointForExistingFilters(filters, searchString) ?? ''

    return path + newUrl
  }, [endpointForExistingFilters, filters, searchString, userId])

  if (platformName) {
    return <div className='user thirdparty'>
      ショップ：{ platformName }
    </div>
  }

  if (!userId) {
    return null
  }

  return <Link to={ collectionLink(`/u/${userId}`) } className={ clsx('user holic', { clickable: clickable === undefined ? true : clickable }) }>
    <div className='avatar'>
      <img src={ avatar } />
    </div>
    <div className='info'>
      <p className='displayname'>
        { displayName }
      </p>
      <Link className='stats' to={ collectionLink(`/u/${userId}/reviews`) }>
        { userRating }
      </Link>
    </div>
    { followButton }
  </Link>
}

export { UserBanner }
