import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { SubscriptionPaymentForm } from './subscription_payment_form'
import { useEffect, useMemo, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { ordersRepository } from '../repositories/orders_repository'
import { ErrorExpired } from './error_expired'
import { Order } from '../types'

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY))

const Subscriptions = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [order, setOrder] = useState<Order>()
  const [options, setOptions] = useState<Record<string, string>>({})

  const params = useMatch('/subscriptions/:id')?.params

  const orderId = useMemo<string>(() => {
    return params?.id ?? ''
  }, [params])

  // get the order
  useEffect(() => {
    if (!orderId) {
      return
    }

    // attempt to get the order
    // will try 10 times with a 1 second interval
    // after 10 attempts, will try every 5 seconds for 10 more attempts
    let attempts = 0
    let interval = 1000
    const maxAttempts = 20

    const fetchOrder = () => {
      ordersRepository
        .get(orderId)
        .then((res) => {
          // an order was found
          attempts = maxAttempts

          if (res.order.status === 'incomplete') {
            setOrder(res.order)

            setOptions({
              clientSecret: res.order.providerSecret ?? '',
            })
          }
        })
        .finally(() => {
          attempts += 1
          if (attempts < maxAttempts) {
            if (attempts >= maxAttempts / 2) {
              interval = 5000
            }

            setTimeout(fetchOrder, interval)
          } else {
            setLoading(false)
          }
        })
    }

    fetchOrder()
  }, [orderId])

  if (!stripePromise) {
    console.error('Stripe promise or client secret not found')
    return <></>
  }

  if (loading) {
    return <p>
      Loading...
    </p>
  }

  if (!order) {
    // expired form
    return <ErrorExpired />
  }

  return <Elements stripe={stripePromise} options={options}>
    <SubscriptionPaymentForm order={order} orderId={orderId} />
  </Elements>
}

export { Subscriptions }
