import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Order, Pagination, RegionShippingService, StripeAccount, UserSelf } from '../types'
import { RegionShippingServiceModal } from './region_shipping_service_modal'
import { regionShippingServicesRepository } from '../repositories/region_shipping_services_repository'
import { EditStripeAccountModal } from './edit_stripe_account_modal'
import { StripeLinkedModal } from './stripe_linked_modal'
import { usersRepository } from '../repositories/users_repository'
import { stripeAccountLinkRepository } from '../repositories/stripe_account_links_repository'
import { countries } from '../lib/countries'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { ebayRepository } from '../repositories/ebay_accounts_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { ordersRepository } from '../repositories/orders_repository'
import clsx from 'clsx'
import { AuthContext } from '../contexts/auth_context'
import { ModalWrapper } from './modal_wrapper'
import { ReturnsOptionsModal } from './returns_options_modal'

type Props = Readonly<{
  currentPage: number
  subpath?: string
}>

const AccountSell = ({ currentPage, subpath }: Props) => {
  const { t } = useTranslation()
  const { sessionId } = useContext(AuthContext)
  const { addError } = useContext(ErrorsContext)

  const location = useLocation()
  const qS = queryString.parse(location.search)

  const queryModal = qS.modal as string
  const querySessionId = qS.session_id as string

  const [regionShippingServices, setRegionShippingServices] = useState<RegionShippingService[]>()
  const [regionShippingServicesLoading, setRegionShippingServicesLoading] = useState<boolean>(true)
  const [editingRegionShippingService, setEditingRegionShippingService] = useState<RegionShippingService|undefined>()
  const [stripeAccount, setStripeAccount] = useState<StripeAccount>()
  const [orders, setOrders] = useState<Order[]>()
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false)

  const [regionShippingServiceModalShown, setRegionShippingServiceModalShown] = useState<boolean>(queryModal === 'shipping-services' && (querySessionId === sessionId || !querySessionId))
  const [returnsOptionsModalShown, setReturnsOptionsModalShown] = useState<boolean>(queryModal === 'returns-options' && (querySessionId === sessionId || !querySessionId))
  const [isEditStripeAccountModalShown, setIsEditStripeAccountModalShown] = useState<boolean>(queryModal === 'seller-information' && (querySessionId === sessionId || !querySessionId))

  // since this should be called by Stripe callback, don't check for session id
  const [isStripeLinkedModalShown, setIsStripeLinkedModalShown] = useState<boolean>(queryModal === 'stripe-linked')

  // pagination for orders
  const [ordersPagination, setOrdersPagination] = useState<Pagination>({
    currentPage,
    totalPages: 1,
  })

  useEffect(() => {
    setOrdersPagination((prev) => ({
      ...prev,
      currentPage,
    }))

    setOrdersLoading(true)
  }, [currentPage])

  const [loadingSellerAccounts, setLoadingSellerAccounts] = useState<boolean>(true)
  const [userSelf, setUserSelf] = useState<UserSelf|undefined>()
  // const [ebaySettingsLoading, setEbaySettingsLoading] = useState<boolean>(false)
  // const [existingEbayListingsToUpdate, setExistingEbayListingsToUpdate] = useState<number|undefined>()

  const [loadingConnectData, setLoadingConnectData] = useState<boolean>(false)
  const [connectComplete, setConnectComplete] = useState<boolean>(false)
  const [connectError, setConnectError] = useState<boolean>(false)

  const ebayCode = qS.code as string

  const sendEbayData = useCallback(() => {
    if (!ebayCode) {
      setConnectError(true)
    } else {
      setLoadingConnectData(true)

      ebayRepository
        .create({ code: ebayCode })
        .then(({ ebayAccount }) => {
          setConnectComplete(true)
          setUserSelf(u => {
            if (!u) return

            const user: UserSelf = {
              ...u,
              ebayAccount,
            }

            return user
          })
        })
        .catch((err: AxiosError) => {
          setConnectError(true)
          // eslint-disable-next-line no-console
          console.error(err)
          // TODO: make error notify common function.
          alert(err)
        })
        .finally(() => {
          setLoadingConnectData(false)
        })
    }
  }, [ebayCode])

  useEffect(() => {
    if (subpath === 'ebay') {
      sendEbayData()
    } else {
      setConnectError(false)
      setConnectComplete(false)
    }
  }, [sendEbayData, subpath])

  const toggleRegionShippingServiceModal = useCallback((on: boolean, address?: RegionShippingService) => {
    setEditingRegionShippingService(address)
    setRegionShippingServiceModalShown(on)
  }, [])

  const toggleReturnsOptionsModal = useCallback((on: boolean) => {
    setReturnsOptionsModalShown(on)
  }, [])

  const regionShippingServiceModalCallback = useCallback((newRegionShippingService?: RegionShippingService) => {
    setRegionShippingServiceModalShown(false)

    if (!newRegionShippingService) return

    if (editingRegionShippingService) {
      setRegionShippingServices((prev) => prev?.map(a => a.id === editingRegionShippingService?.id ? newRegionShippingService : a))
    } else {
      setRegionShippingServices((prev) => prev?.concat([newRegionShippingService]))
    }
  }, [editingRegionShippingService])

  const getRegionShippingServices = useCallback(() => {
    if (!regionShippingServicesLoading) return

    regionShippingServicesRepository
      .index()
      .then(({ regionShippingServices }) => {
        setRegionShippingServices(regionShippingServices)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setRegionShippingServicesLoading(false)
      })
  }, [addError, regionShippingServicesLoading])

  const toggleEditStripeAccountModal = useCallback((on: boolean, updatedStripeAccount?: StripeAccount) => {
    setStripeAccount(updatedStripeAccount)
    setIsEditStripeAccountModalShown(on)
  }, [])

  const editStripeAccountModalCallback = useCallback((newStripeAccount?: StripeAccount) => {
    setStripeAccount(newStripeAccount)
    setIsEditStripeAccountModalShown(false)
  }, [])

  useEffect(() => {
    if (!loadingSellerAccounts) return

    usersRepository
      .getSelf()
      .then(({ stripeAccount, user }) => {
        setStripeAccount(stripeAccount)
        setUserSelf(user)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSellerAccounts(false)
      })
  }, [addError, loadingSellerAccounts, stripeAccount])

  useEffect(() => {
    if (!userSelf?.canSellHolic) return

    getRegionShippingServices()
  }, [getRegionShippingServices, userSelf?.canSellHolic])

  const getOrders = useCallback(() => {
    if (!ordersLoading) return

    ordersRepository
      .index({ params: { page: ordersPagination.currentPage, limit: 10, show: 'seller' } })
      .then(({ orders, pagination }) => {
        setOrders(orders)
        setOrdersPagination(pagination)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setOrdersLoading(false)
      })
  }, [addError, ordersLoading, ordersPagination.currentPage])

  useEffect(() => {
    if (!userSelf?.canSellHolic) return

    getOrders()
  }, [getOrders, userSelf?.canSellHolic])

  const ordersList = useMemo(() => {
    // return (
    //   <div>
    //     <h2>{ t('account.sell.orders.title_pending') }</h2>
    //     <p>
    //       { t('account.sell.orders.none') }
    //     </p>
    //     <hr />
    //   </div>
    // )
    if (ordersLoading) {
      return (
        <p>{ t('account.buy.orders.loading') }</p>
      )
    }

    if (!orders || orders?.length === 0) {
      return (
        <>
          <h2>{ t('account.sell.orders.title') }</h2>
          <p>{ t('account.buy.orders.none') }</p>
          <hr />
        </>
      )
    }

    return (
      <>
        <h2>{ t('account.sell.orders.title') }</h2>
        <ul className="editable">
          {
            orders.map((o) => {
              const statusColor = o?.status === 'shipped' || o?.status === 'active'
                ? 'green'
                : o?.status === 'succeeded'
                  ? 'red'
                  : ''

              return (
                <li key={ o.id }>
                  <span>
                    <strong className={ statusColor }>{ t(`account.order_status_seller.${o.status}`) }</strong>
                    #{ o.id }<br />
                    { o.createdAt ? new Date(o.createdAt).toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : '' }
                  </span>
                  <span className="buttons"><Link to={ `/orders/${o.id ?? 0}` } className="button tool">{ t('account.buy.orders.view') }</Link></span>
                </li>
              )
            })
          }
        </ul>
        <div className="pagination">
          <span>{ t('Page:') }</span>
          {
            Array.apply(null, Array(ordersPagination.totalPages)).map((p, i) => {
              if (
                i >= 1 &&
                i !== ordersPagination.totalPages - 1 &&
                (ordersPagination.currentPage > 4 || i > 4)
              ) {
                if (i <= ordersPagination.currentPage - 4) return null
                if (i >= ordersPagination.currentPage + 2) return null
              }
              const pageNumber = i === 0
                ? t('最初')
                : i === ordersPagination.totalPages - 1 ? t('最後') : i + 1

              return <Link key= { i } className={ clsx('button', { selected: i + 1 === ordersPagination.currentPage }) } to={ `/account/sell${i > 0 ? `?page=${i + 1}` : ''}` }>{ pageNumber }</Link>
            })
          }
        </div>
        <hr />
      </>
    )
  }, [orders, ordersLoading, ordersPagination.currentPage, ordersPagination.totalPages, t])

  const accounts = useMemo(() => {
    return <div></div>

    // return (
    //   <div>
    //     <h2>{ t('account.sell.accounts.title') }</h2>
    //     <ul>
    //       <li><span>{ t('account.sell.accounts.ebay') }</span> { t('account.sell.accounts.linked') } <button className="tool">{ t('account.sell.accounts.manage') }</button></li>
    //       <li><span>{ t('account.sell.accounts.mercari') }</span> { t('account.sell.accounts.not_linked') } <button className="tool">{ t('account.sell.accounts.link') }</button></li>
    //     </ul>
    //     <hr />
    //   </div>
    // )
  }, [])

  const shippingOptions = useMemo(() => {
    if (regionShippingServicesLoading) {
      return (
        <p>{ t('account.sell.shipping_options.loading') }</p>
      )
    }

    if (!regionShippingServices || regionShippingServices?.length === 0) {
      return (
        <p>{ t('account.sell.shipping_options.none') }</p>
      )
    }

    return (
      <>
        <ul className="editable">
          {
          regionShippingServices.map(r => {
            return (
              <li key={ r.id }>
                <span>{ t(`account.sell.shipping_options.regions.region${r.region.id}`) }, { t(`account.sell.shipping_options.options.${r.shippingService.label}`) }: { t('listings.price.price_with_currency_symbol', { price: Math.round(r.priceJpy) }) }</span>
                <span className="buttons"></span><button className="tool" onClick={ () => { toggleRegionShippingServiceModal(true, r) } }>{ t('account.sell.shipping_options.edit') }</button>
              </li>
            )
          })
        }
        </ul>
      </>
    )
  }, [t, regionShippingServices, regionShippingServicesLoading, toggleRegionShippingServiceModal])

  const shippingOptionsSection = useMemo(() =>
    <>
      <h2>{ t('account.sell.shipping_options.title') }</h2>
      { shippingOptions }
      <p>
        <button className="tool" onClick={ () => { toggleRegionShippingServiceModal(true, undefined) } }>{ t('account.sell.shipping_options.button_new') }</button>
      </p>
      {
        regionShippingServiceModalShown
          ? <RegionShippingServiceModal onClose={ regionShippingServiceModalCallback } regionShippingService={ editingRegionShippingService } />
          : null
      }
      <hr />
    </>
  , [editingRegionShippingService, regionShippingServiceModalCallback, regionShippingServiceModalShown, shippingOptions, t, toggleRegionShippingServiceModal])

  const returnsOptionsModalCallback = useCallback((s: StripeAccount) => {
    setStripeAccount(s)
    setReturnsOptionsModalShown(false)
  }, [])

  const returnsOptionsSection = useMemo(() => {
    if (!stripeAccount) return

    return <>
      <h2>{ t('account.sell.returns.title') }</h2>
      <ul className="editable">
        <li>
          <span>{ t('account.sell.returns.enabled.title') }</span>
          <span>{
            stripeAccount.returns > 0
              ? t('account.sell.returns.enabled.true')
              : t('account.sell.returns.enabled.false')
          }</span>
        </li>
        {
          stripeAccount.returns === 0
            ? null
            : <li>
              <span>{ t('account.sell.returns.burden.title') }</span>
              <span>{
                stripeAccount.returns === 1
                  ? t('account.sell.returns.burden.seller')
                  : t('account.sell.returns.burden.customer')
              }</span>
            </li>
        }
      </ul>
      <p>
        <button className="tool" onClick={ () => { toggleReturnsOptionsModal(true) } }>{ t('account.sell.returns.button_edit') }</button>
      </p>
      {
        returnsOptionsModalShown
          ? <ReturnsOptionsModal onClose={ returnsOptionsModalCallback } stripeAccount={ stripeAccount } />
          : null
      }
      <hr />
    </>
  }, [returnsOptionsModalCallback, returnsOptionsModalShown, stripeAccount, t, toggleReturnsOptionsModal])

  const sellerInformationEditButton = useMemo(() => {
    if (loadingSellerAccounts) return

    if (userSelf?.canSellHolic) {
      return <button className="tool" onClick={ () => { toggleEditStripeAccountModal(true, stripeAccount) } }>{ t('general.edit') }</button>
    }

    return <Link to='/sell' className='button'>出品をはじめる</Link>
  }, [loadingSellerAccounts, stripeAccount, t, toggleEditStripeAccountModal, userSelf?.canSellHolic])

  const sellerInformation = useMemo(() => {
    if (loadingSellerAccounts) {
      return (
        <p>{ t('account.sell.intro.loading') }</p>
      )
    }

    if (!stripeAccount) {
      return (
        <>
          <h2>{ t('account.sell.intro.title') }</h2>
          <p>
            { t('account.sell.intro.text1') }
          </p>
          <p>
            { t('account.sell.intro.text2') }
          </p>
        </>
      )
    }

    const nationality = countries.find(c => c.value === stripeAccount.nationalityCode)
    const country = countries.find(c => c.value === stripeAccount.countryCode)

    const sellerName: string = (
      !stripeAccount.lastName && !stripeAccount.firstName
        ? '-'
        : (nationality?.familyNameFirst) ?? (country?.familyNameFirst)
            ? `${stripeAccount.lastName} ${stripeAccount.firstName}`
            : `${stripeAccount.firstName} ${stripeAccount.lastName}`
    )

    return (
      <>
        <h2>{ t('account.sell.stripe_account.title') }</h2>
        <ul className="editable">
          {
          // <li>
          //   <span>
          //     { t('account.sell.edit_stripe_account.nationality') }
          //   </span>
          //   <span>
          //     { nationality ? t(`account.countries.${nationality?.value ?? ''}`) : t('account.sell.edit_stripe_account.none') }
          //   </span>
          // </li>
          }
          <li>
            <span>
              { t('account.sell.edit_stripe_account.country') }
            </span>
            <span>
              { country ? t(`account.countries.${country?.value ?? ''}`) : t('account.sell.edit_stripe_account.none') }
            </span>
          </li>
          {
            stripeAccount.state &&
              (
                <li>
                  <span>
                    { t('account.sell.edit_stripe_account.state') }
                  </span>
                  <span>
                    { stripeAccount.state }
                  </span>
                </li>
              )
          }
          {
            stripeAccount.city &&
              (
                <li>
                  <span>
                    { t('account.sell.edit_stripe_account.line3') }
                  </span>
                  <span>
                    { stripeAccount.city }
                  </span>
                </li>
              )
          }
          {
            stripeAccount.zipCode &&
              (
                <li>
                  <span>
                    { t('account.sell.edit_stripe_account.postcode') }
                  </span>
                  <span>
                    { stripeAccount.zipCode }
                  </span>
                </li>
              )
          }
          <li>
            <span>
              { t('account.sell.edit_stripe_account.name') }
            </span>
            <span>
              { sellerName }
            </span>
          </li>
          <li>
            <span>
              { t('account.sell.edit_stripe_account.birth_date') }
            </span>
            <span>
              { stripeAccount.birthDate }
            </span>
          </li>
        </ul>
      </>
    )
  }, [loadingSellerAccounts, stripeAccount, t])

  const goToStripeOnboarding = useCallback(async (e) => {
    e.preventDefault()

    try {
      const { url } = await stripeAccountLinkRepository.get()
      window.location.assign(url)
    } catch (error) {
      alert(error)
    }
  }, [])

  const stripeOnboardingButton = useMemo(() => {
    if (!stripeAccount?.stripeId) return

    return <>
      <hr />
      {
        !stripeAccount?.payoutsEnabled
          ? <h2>{ t('account.sell.stripe_account.payouts_disabled.title') }</h2>
          : (
            <>
              <h2>{ t('account.sell.stripe_account.payouts_enabled.title') }</h2>
              <p>{ t('account.sell.stripe_account.payouts_enabled.description') }</p>
            </>
            )
      }
      <p>
        <button onClick={ goToStripeOnboarding } className={ !stripeAccount?.payoutsEnabled ? '' : 'tool' }>
          {
            !stripeAccount?.payoutsEnabled
              ? t('account.sell.stripe_account.button_link_with_stripe')
              : t('account.sell.stripe_account.button_modify_bank_account')
          }
        </button>
      </p>
    </>
  }, [goToStripeOnboarding, stripeAccount?.payoutsEnabled, stripeAccount?.stripeId, t])

  const sellerInformationSection = useMemo(() => {
    return (
      <>
        { sellerInformation }
        <p>
          { sellerInformationEditButton }
        </p>
        { stripeOnboardingButton }
        {
          isEditStripeAccountModalShown
            ? <ModalWrapper>
              <h1>{ t('account.sell.edit_stripe_account.title') }</h1>
              <EditStripeAccountModal onClose={ editStripeAccountModalCallback } stripeAccount={ stripeAccount } showCancelButton={ true } buttonText={ t('general.save') } />
            </ModalWrapper>
            : null
        }
        {
          isStripeLinkedModalShown
            ? <StripeLinkedModal onClose={ () => setIsStripeLinkedModalShown(false) } />
            : null
        }
      </>
    )
  }, [editStripeAccountModalCallback, isEditStripeAccountModalShown, isStripeLinkedModalShown, sellerInformation, sellerInformationEditButton, stripeAccount, stripeOnboardingButton, t])

  // const connectEbay = useCallback(() => {
  //   const endpoint = String(process.env.REACT_APP_API_BASE_URL)

  //   const endpointReplace = endpoint.substr(endpoint.length - 4) === '/api' ? endpoint.slice(0, -4) : endpoint

  //   window.location.assign(`${endpointReplace}/auth/ebay`)
  // }, [])

  // const {
  //   register: registerEbaySettings,
  //   reset: resetEbaySettings,
  //   handleSubmit: handleSubmitEbaySettings,
  // } = useForm<EbayAccount>()

  // useEffect(() => {
  //   resetEbaySettings(userSelf?.ebayAccount)
  // }, [resetEbaySettings, userSelf])

  // const onSubmitEbayFields = useCallback((params) => {
  //   // TODO: Validation
  //   if (ebaySettingsLoading) return

  //   setEbaySettingsLoading(true)

  //   if (params.returnPolicy === '') params.returnPolicy = null
  //   if (params.paymentPolicy === '') params.paymentPolicy = null

  //   ebayRepository
  //     .update({ params })
  //     .then(({ totalListings }) => {
  //       setUserSelf(u => {
  //         if (!u) return

  //         const user: UserSelf = {
  //           ...u,
  //           hasEbayAccount: true,
  //           ebayAccount: {
  //             ...params,
  //             active: true,
  //             profiles: u.ebayAccount?.profiles
  //           },
  //         }

  //         return user
  //       })

  //       if (totalListings > 0) {
  //         setExistingEbayListingsToUpdate(totalListings)
  //       } else {
  //         alert('update successful')
  //       }
  //     })
  //     .catch((err: AxiosError) => {
  //       // eslint-disable-next-line no-console
  //       console.error(err)
  //       // TODO: make error notify common function.
  //       alert(err)
  //     })
  //     .finally(() => {
  //       setEbaySettingsLoading(false)
  //     })
  // }, [ebaySettingsLoading])

  // const [showEbayPolicyHelpModal, setShowEbayPolicyHelpModal] = useState<boolean>(false)
  // const onClickShowEbayPolicyHelpModal = useCallback((e) => {
  //   e.preventDefault()
  //   setShowEbayPolicyHelpModal(true)
  // }, [])

  // const ebayProfiles = useMemo(() => {
  //   if (!userSelf?.ebayAccount?.profiles) return

  //   const profiles = userSelf.ebayAccount.profiles.map((p) => {
  //     return { ...p, label: p.name }
  //   })

  //   return {
  //     paymentPolicies: profiles.filter(p => p.type === 'PaymentPolicy'),
  //     returnPolicies: profiles.filter(p => p.type === 'ReturnPolicy'),
  //     shippingPolicies: profiles.filter(p => p.type === 'ShippingPolicy'),
  //   }
  // }, [userSelf?.ebayAccount?.profiles])

  // const [refreshPoliciesLoading, setRefreshPoliciesLoading] = useState<boolean>(false)
  // const refreshEbayPolicies = useCallback((e) => {
  //   e.preventDefault()

  //   if (!userSelf?.ebayAccount ?? refreshPoliciesLoading) return

  //   setRefreshPoliciesLoading(true)

  //   ebayRepository
  //     .refresh_profiles()
  //     .then(({ profiles }) => {
  //       setUserSelf(u => {
  //         if (!u || !u.ebayAccount) return

  //         const selectedPayments = profiles.find(p => p.isSelected && p.type === 'PaymentPolicy')
  //         const selectedReturns = profiles.find(p => p.isSelected && p.type === 'ReturnPolicy')
  //         const selectedShipping = profiles.find(p => p.isSelected && p.type === 'ShippingPolicy')

  //         const user: UserSelf = {
  //           ...u,
  //           ebayAccount: {
  //             ...u.ebayAccount,
  //             paymentPolicy: selectedPayments?.value,
  //             returnPolicy: selectedReturns?.value,
  //             shippingPolicy: selectedShipping?.value,
  //             profiles,
  //           },
  //         }

  //         return user
  //       })

  //       alert(t('account.sell.thirdparty.set.policy_refresh_done'))
  //     })
  //     .catch((err: AxiosError) => {
  //       // eslint-disable-next-line no-console
  //       console.error(err)
  //       // TODO: make error notify common function.
  //       alert(err)
  //     })
  //     .finally(() => {
  //       setRefreshPoliciesLoading(false)
  //     })
  // }, [refreshPoliciesLoading, t, userSelf?.ebayAccount])

  // const [showEbayFeeHelp, setShowEbayFeeHelp] = useState<boolean>(false)
  // const ebayFeeHelpOnClick = useCallback((e) => {
  //   e.preventDefault()
  //   setShowEbayFeeHelp(s => !s)
  // }, [])

  // const [showEbayDescriptionHelp, setShowEbayDescriptionHelp] = useState<boolean>(false)
  // const ebayDescriptionHelpOnClick = useCallback((e) => {
  //   e.preventDefault()
  //   setShowEbayDescriptionHelp(s => !s)
  // }, [])

  // const ebayFields = useMemo(() => {
  //   if (!userSelf?.ebayAccount) return

  //   const hasPaymentPolicies = ebayProfiles?.paymentPolicies && ebayProfiles?.paymentPolicies.length > 0
  //   const hasReturnPolicies = ebayProfiles?.returnPolicies && ebayProfiles?.returnPolicies.length > 0
  //   const hasShippingPolicies = ebayProfiles?.shippingPolicies && ebayProfiles?.shippingPolicies.length > 0

  //   return (
  //     <>
  //       <form key={ 'basic' } onSubmit={ handleSubmitEbaySettings(onSubmitEbayFields) } className="single-line">
  //         <div>
  //           <label></label>
  //           <span>
  //             <button className="tool" onClick={ (e) => refreshEbayPolicies(e) } disabled={ refreshPoliciesLoading }>{ refreshPoliciesLoading ? t('account.sell.thirdparty.set.policy_loading') : t('account.sell.thirdparty.set.policy_refresh') }</button> <button className="tool" onClick={ (e) => onClickShowEbayPolicyHelpModal(e) }>?</button>
  //           </span>
  //         </div>
  //         <div className="select">
  //           <label>
  //             { t('account.sell.thirdparty.set.payments') }
  //           </label>
  //           {
  //             hasPaymentPolicies
  //               ? (
  //                 <select { ...registerEbaySettings('paymentPolicy') }>
  //                   {
  //                     ebayProfiles?.paymentPolicies.map((policy) => {
  //                       return <option key={ policy.value } value={ policy.value }>{ policy.name }</option>
  //                     })
  //                   }
  //                 </select>
  //                 // <Controller
  //                 //   control={ controlEbaySettings }
  //                 //   name="paymentPolicy"
  //                 //   defaultValue= { ebayProfiles?.paymentPolicies.find(p => p.value === userSelf?.ebayAccount?.paymentPolicy)?.value }
  //                 //   render={ ({ onChange, value, ref }) => (
  //                 //     <Select
  //                 //       ref={ ref }
  //                 //       options={ ebayProfiles?.paymentPolicies }
  //                 //       value={ ebayProfiles?.paymentPolicies.find(p => p.value === value) }
  //                 //       onChange={ (p) => onChange(p?.value) }
  //                 //       placeholder={ t('defaults.select') }
  //                 //     />
  //                 //   ) }
  //                 // />
  //                 )
  //               : (
  //                 <span><button className="tool" onClick={ (e) => onClickShowEbayPolicyHelpModal(e) }>{ t('account.sell.thirdparty.set.setup') }</button></span>
  //                 )
  //           }
  //         </div>
  //         <div className="select">
  //           <label>
  //             { t('account.sell.thirdparty.set.returns') }
  //           </label>
  //           {
  //             hasReturnPolicies
  //               ? (
  //                 <select { ...registerEbaySettings('returnPolicy') }>
  //                   {
  //                     ebayProfiles?.returnPolicies.map((policy) => {
  //                       return <option key={ policy.value } value={ policy.value }>{ policy.name }</option>
  //                     })
  //                   }
  //                 </select>
  //                 // <Controller
  //                 //   control={ controlEbaySettings }
  //                 //   name="returnPolicy"
  //                 //   defaultValue= { ebayProfiles?.returnPolicies.find(p => p.value === userSelf?.ebayAccount?.returnPolicy)?.value }
  //                 //   render={ ({ onChange, value, ref }) => (
  //                 //     <Select
  //                 //       ref={ ref }
  //                 //       options={ ebayProfiles?.returnPolicies }
  //                 //       value={ ebayProfiles?.returnPolicies.find(p => p.value === value) }
  //                 //       onChange={ (p) => onChange(p?.value) }
  //                 //       placeholder={ t('defaults.select') }
  //                 //     />
  //                 //   ) }
  //                 // />
  //                 )
  //               : (
  //                 <span><button className="tool" onClick={ (e) => onClickShowEbayPolicyHelpModal(e) }>{ t('account.sell.thirdparty.set.setup') }</button></span>
  //                 )
  //           }
  //         </div>
  //         <div className="select">
  //           <label>
  //             { t('account.sell.thirdparty.set.shipping') }
  //           </label>
  //           {
  //             hasShippingPolicies
  //               ? (
  //                 <select { ...registerEbaySettings('shippingPolicy') }>
  //                   {
  //                     ebayProfiles?.shippingPolicies.map((policy) => {
  //                       return <option key={ policy.value } value={ policy.value }>{ policy.name }</option>
  //                     })
  //                   }
  //                 </select>
  //                 // <Controller
  //                 //   control={ controlEbaySettings }
  //                 //   name="shippingPolicy"
  //                 //   defaultValue= { ebayProfiles?.shippingPolicies.find(p => p.value === userSelf?.ebayAccount?.shippingPolicy)?.value }
  //                 //   render={ ({ onChange, value, ref }) => (
  //                 //     <Select
  //                 //       ref={ ref }
  //                 //       options={ ebayProfiles?.shippingPolicies }
  //                 //       value={ ebayProfiles?.shippingPolicies.find(p => p.value === value) }
  //                 //       onChange={ (p) => onChange(p?.value) }
  //                 //       placeholder={ t('defaults.select') }
  //                 //     />
  //                 //   ) }
  //                 // />
  //                 )
  //               : (
  //                 <span><button className="tool" onClick={ (e) => onClickShowEbayPolicyHelpModal(e) }>{ t('account.sell.thirdparty.set.setup') }</button></span>
  //                 )
  //           }
  //         </div>
  //         <div>
  //           <label>{ t('account.sell.thirdparty.set.fee') }</label>
  //           <span>
  //             <input type="number" step="0.1" min="0" max="15" { ...registerEbaySettings('feeOffset') } /> <button className="tool" onClick={ (e) => ebayFeeHelpOnClick(e) }>{ showEbayFeeHelp ? 'X' : '?' }</button>
  //           </span>
  //         </div>
  //         {
  //           showEbayFeeHelp
  //             ? <p>{ t('account.sell.thirdparty.set.fee_help') }</p>
  //             : null
  //         }
  //         <div>
  //           <h3>{ t('account.sell.thirdparty.set.item_description.title') }</h3>
  //           <span><button className="tool" onClick={ (e) => ebayDescriptionHelpOnClick(e) }>{ showEbayDescriptionHelp ? 'X' : '?' }</button></span>
  //         </div>
  //         {
  //           showEbayDescriptionHelp
  //             ? <p>{ t('account.sell.thirdparty.set.item_description.help') }</p>
  //             : null
  //         }
  //         <p>
  //           <label>{ t('account.sell.thirdparty.set.item_description.prefix') }</label>
  //           <label>
  //             <textarea { ...registerEbaySettings('descriptionPrefix') } />
  //           </label>
  //         </p>
  //         <p>
  //           <label>{ t('account.sell.thirdparty.set.item_description.suffix') }</label>
  //           <label>
  //             <textarea { ...registerEbaySettings('descriptionSuffix') } />
  //           </label>
  //         </p>
  //         <p>
  //           <button className="full" disabled={ ebaySettingsLoading }>
  //             { t('account.submit_basic') }
  //           </button>
  //         </p>
  //       </form>
  //     </>
  //   )
  // }, [userSelf?.ebayAccount, ebayProfiles?.paymentPolicies, ebayProfiles?.returnPolicies, ebayProfiles?.shippingPolicies, handleSubmitEbaySettings, onSubmitEbayFields, refreshPoliciesLoading, t, registerEbaySettings, showEbayFeeHelp, showEbayDescriptionHelp, ebaySettingsLoading, refreshEbayPolicies, onClickShowEbayPolicyHelpModal, ebayFeeHelpOnClick, ebayDescriptionHelpOnClick])

  // const sellConnect = useMemo(() => {
  //   if (loadingSellerAccounts || !stripeAccount) return

  //   if (!userSelf?.ebayAccount) {
  //     return (
  //       <>
  //         <hr />
  //         <h2>{ t('account.sell.thirdparty.not_set.title') }</h2>
  //         <p>
  //           { t('account.sell.thirdparty.not_set.text1') }
  //         </p>
  //         <p>
  //           { t('account.sell.thirdparty.not_set.text2') }
  //         </p>
  //         <p>
  //           <button onClick={ connectEbay }>{ t('account.sell.thirdparty.not_set.button') }</button>
  //         </p>
  //         <p>
  //           { t('account.sell.thirdparty.not_set.clause') } <Link to="/about/ebay-fees">About eBay fees</Link>
  //         </p>
  //       </>
  //     )
  //   }

  //   const notice = (!userSelf?.ebayAccount?.paymentPolicy || !userSelf?.ebayAccount?.returnPolicy || !userSelf?.ebayAccount?.shippingPolicy)
  //     ? <p>{ t('account.sell.thirdparty.set.text_setup') }</p>
  //     : (
  //       <p>
  //         <Trans i18nKey="account.sell.thirdparty.set.text_list">
  //           To sell on eBay, <Link to="/u/me">visit your collection</Link> and select cards to sell.
  //         </Trans>
  //       </p>
  //       )

  //   return (
  //     <>
  //       <hr />
  //       <h2>{ t('account.sell.thirdparty.set.title') }</h2>
  //       <p>
  //         <Trans i18nKey="account.sell.thirdparty.set.text">
  //           Your eBay account is <strong>connected</strong>.
  //         </Trans>
  //       </p>
  //       { notice }
  //       { ebayFields }
  //     </>
  //   )
  // }, [connectEbay, ebayFields, loadingSellerAccounts, stripeAccount, t, userSelf?.ebayAccount])

  if (loadingConnectData) {
    return (
      <div className="narrow">
        <h2>{ t('account.sell.thirdparty.connect.loading.title') }</h2>
        <p>{ t('account.sell.thirdparty.connect.loading.text') }</p>
      </div>
    )
  }

  if (connectError) {
    return (
      <div className="narrow">
        <h2>{ t('account.sell.thirdparty.connect.error.title') }</h2>
        <p>{ t('account.sell.thirdparty.connect.error.text') }</p>
        <p>
          <Link to="/account/sell" className="button large">{ t('account.sell.thirdparty.connect.error.button') }</Link>
        </p>
      </div>
    )
  }

  if (connectComplete) {
    return (
      <div className="narrow">
        <h2>{ t('account.sell.thirdparty.connect.success.title') }</h2>
        <p>{ t('account.sell.thirdparty.connect.success.text1') }</p>
        <p>{ t('account.sell.thirdparty.connect.success.text2') }</p>
        <p>
          <Link to="/account/sell" className="button large">{ t('account.sell.thirdparty.connect.success.button') }</Link>
        </p>
      </div>
    )
  }

  return (
    <div>
      <div className="narrow">
        { userSelf?.canSellHolic && (
          <>
            { ordersList }
            { accounts }
            { shippingOptionsSection }
            { returnsOptionsSection }
          </>
        ) }
        { sellerInformationSection }
        { /* Sell on other marketplaces */ }
        { /* sellConnect */ }
        { /* { showEbayPolicyHelpModal && <EbayPolicyHelpModal onClose={ () => setShowEbayPolicyHelpModal(false) } /> } */ }
        { /* { existingEbayListingsToUpdate && <ListingsBatchUpdateModal totalListings={ existingEbayListingsToUpdate } platform="ebay" onClose={ () => setExistingEbayListingsToUpdate(undefined) } /> } */ }
      </div>
    </div>
  )
}

export { AccountSell }
