import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router'
import { ErrorsContext } from '../contexts/errors_context'
import { SettingsContext } from '../contexts/settings_context'
import { itemsRepository } from '../repositories/items_repository'

import '../stylesheets/Onboarding.scss'

import { Item } from '../types'
import { GenericNotFound } from './generic_not_found'

const ItemOnboard = (): JSX.Element => {
  const { i18n, t } = useTranslation()

  const { addError } = useContext(ErrorsContext)

  const { setMinimalUi } = useContext(SettingsContext)
  useEffect(() => {
    if (!setMinimalUi) return

    setMinimalUi(true)

    return () => {
      setMinimalUi(false)
    }
  }, [setMinimalUi])

  const [loading, setLoading] = useState<boolean>(true)
  const [item, setItem] = useState<Item>()
  const [stage, setStage] = useState<number>(0)

  const params = useMatch('/categories/:category_id/items/:item_id/ob')?.params

  const itemId = useMemo<number|undefined>(() => {
    const id = parseInt(params?.item_id ?? '')
    return isNaN(id) ? undefined : id
  }, [params])

  const getItem = useCallback((id: number) => {
    setLoading(true)

    itemsRepository
      .get(id)
      .then(({ item }) => {
        setItem(item)

        ReactGA.event({
          category: 'Onboarding',
          action: 'get-item',
          value: item.id,
        })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  const image = useMemo(() => {
    if (!item || item.images.length === 0) return

    return item?.images[0]
  }, [item])

  useEffect(() => {
    if (itemId) {
      getItem(itemId)
    } else {
      setLoading(false)
    }
  }, [getItem, itemId])

  const advanceStage = useCallback((s: number) => {
    setStage(s)

    ReactGA.event({
      category: 'Onboarding',
      action: 'set-stage',
      label: `stage-${String(s + 1)}`,
      value: item?.id,
    })
  }, [item?.id])

  const itemName = useMemo(() => {
    if (!item) return ''

    return ((i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn) ?? ''
  }, [i18n.language, item])

  const stage0 = useMemo(() => {
    return <>
      <div className='content'>
        <div className={ 'fanfare-text' }>
          <div>ゲット</div>
          <div>だぜ！</div>
        </div>
        <div className='success-text'>
          <p>
            {
              i18n.language === 'ja'
                ? <><strong>{ itemName }</strong>がマイデッキに追加されました</>
                : <><strong>{ itemName }</strong> was added to your HOLIC card collection.</>
            }
          </p>
        </div>
        {
          image
            ? <img className="card" src={ image.thumb2x } />
            : null
        }
        <p className='title'>{ itemName }</p>
      </div>
      <div className='buttons first'>
        <p>
          <button className='full' onClick={ () => { advanceStage(1) } }>{
            i18n.language === 'ja' ? '集める' : 'Collect it'
          }</button>
        </p>
        <p>
          {
            i18n.language === 'ja'
              ? <><strong>{ itemName }</strong>をマイデッキに加える</>
              : <>Add <strong>{ itemName }</strong> to your collection</>
          }
        </p>
      </div>
      <div className='buttons second'>
        <p>
          <button className='full' onClick={ () => { advanceStage(2) } }>{
            i18n.language === 'ja' ? '次へ' : 'Next'
          }</button>
        </p>
        <p>
          &nbsp;
        </p>
      </div>
    </>
  }, [advanceStage, i18n.language, image, itemName])

  const registerButtonClick = useCallback(() => {
    ReactGA.event({
      category: 'Onboarding',
      action: 'register-button-click',
      value: item?.id,
    })
  }, [item?.id])

  const stage2 = useMemo(() => {
    return <>
      <div className='content'>
        <div className='inner'>
          <h2>HOLICへようこそ！</h2>
          <p>
            HOLICでは…
          </p>
          <p>
            ・集めたカードのデジタル版デッキがつくれます。
          </p>
          <p>
            ・マイデッキをシェアしましょう。
          </p>
          <p>
            ・足りないカードは見つけて購入できます。
          </p>
          <p>
            ・いらないカードはお得に売れます（販売手数料6.4％より）
          </p>
        </div>
      </div>
      <div className='buttons'>
        <p>
          <a className='button full' href={ `/register/nologin?item_id=${item?.id ?? ''}` } onClick={ registerButtonClick }>
            HOLICをはじめよう！
          </a>
        </p>
        <p>
          &nbsp;
        </p>
      </div>
    </>
  }, [item?.id, registerButtonClick])

  const stageUi = useMemo(() => {
    if (stage <= 1) return stage0

    return stage2
  }, [stage, stage0, stage2])

  if (loading) {
    return <p>{ t('cards.marketplace.loading') }</p>
  }

  if (!item) return <GenericNotFound />

  return (
    <div className={ `onboarding-container stage-${stage}` }>
      <div>
        <div className='header'>
          <h1 className='logo'>HOLIC</h1>
        </div>
        { stageUi }
      </div>
    </div>
  )
}

export { ItemOnboard }
