import { useCallback, useContext, useEffect, useState, Suspense, lazy } from 'react'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { blogsRepository } from '../repositories/blogs_repository'
import { Blog } from '../types'
import { Link } from 'react-router-dom'

const Loading = () => {
  return (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

type Props = Readonly<{
  userId: number
  canEdit: boolean
}>

const BlogsIndex = ({ userId, canEdit }: Props) => {
  const { addError } = useContext(ErrorsContext)
  const BlogView = lazy(() => import('./blog_view'))

  const [blogs, setBlogs] = useState<Blog[]>([])
  const [blogsLoading, setBlogsLoading] = useState<boolean>(false)

  const getBlogs = useCallback((uid: number) => {
    setBlogsLoading(true)

    blogsRepository
      .index(uid)
      .then(({ blogs }) => {
        setBlogs(blogs)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setBlogsLoading(false)
      })
  }, [addError])

  useEffect(() => {
    getBlogs(userId)
  }, [userId, getBlogs])

  return (
    <>
      {
        blogsLoading
          ? <Loading />
          : <>
            {
              canEdit
                ? <div className='blog semi-narrow none'>
                  <Link to={ `/u/${userId}/blog/new` } className="button tool">ブログを書く</Link>
                  <hr />
                </div>
                : null
            }
            {
              blogs.length === 0
                ? (
                  <div className='blog semi-narrow none'>
                    <h2>ブログ</h2>
                    <p className="">まだブログがありません...</p>
                  </div>
                  )
                : <div className="blog semi-narrow">
                  {
                    blogs.map((blog) => (
                      <Suspense fallback={<div>読み込み中…</div>}>
                        <BlogView key={ blog.id } id={ blog.id ?? 0 } userId={ blog.userId } _blog={ blog } />
                        <hr />
                      </Suspense>
                    ))
                  }
                </div>
            }
          </>
      }
    </>
  )
}

export default BlogsIndex
