import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'
import { Link } from 'react-router-dom'

import { Item, Condition } from '../types'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  onClose: () => void
  item: Item
  condition?: Condition
}>

const CardAddedModal = ({ onClose, item, condition }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <></>

  return (
    <ModalWrapper>
      <h1>{ t('cards.added_modal.title') }</h1>
      <p>
        <Trans
          i18nKey="cards.added_modal.summary"
          defaults="<bold>{{condition}} {{name}}</bold> was added to your collection."
          values={ { condition: condition?.name ?? '', name: item.nameEn } }
          components={ { bold: <strong /> } }
        />
      </p>
      <p>{ t('cards.added_modal.body') }</p>
      <p>
        <Link to={ `/u/${currentUser.id}` } className="button full white" onClick={ onClose }>{ t('cards.added_modal.button_collection') }</Link>
      </p>
      <p>
        <button className="full" onClick={ onClose }>
          { t('cards.added_modal.button_add', { name: item.nameEn }) }
        </button>
      </p>
    </ModalWrapper>
  )
}

export { CardAddedModal }
