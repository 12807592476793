import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/auth_context'
import { ErrorsContext } from '../contexts/errors_context'
import { usersRepository } from '../repositories/users_repository'
import { Follow } from '../types'

type FollowButtonProps = Readonly<{
  userId: number
  displayName: string
}>

const FollowButton = ({ userId, displayName }: FollowButtonProps) => {
  const { addError } = useContext(ErrorsContext)
  const { isAuthenticated, userFollowings, setUserFollowings, currentUser } = useContext(AuthContext)

  const [followingThisUser, setFollowingThisUser] = useState<boolean>(false)

  useEffect(() => {
    if (!isAuthenticated || !userFollowings) return

    setFollowingThisUser(userFollowings.find((f) => f.id === userId) !== undefined)
  }, [isAuthenticated, userFollowings, userId])

  const follow = useCallback((e) => {
    e.preventDefault()

    usersRepository
      .follow(userId)
      .then(() => {
        const newFollow: Follow = {
          id: userId,
          displayName,
        }

        setUserFollowings?.([...userFollowings?.filter((f: Follow) => f.id !== userId) ?? [], newFollow])
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, displayName, setUserFollowings, userFollowings, userId])

  const unfollow = useCallback((e) => {
    e.preventDefault()

    usersRepository
      .unfollow(userId)
      .then(() => {
        setUserFollowings?.(userFollowings?.filter((f: Follow) => f.id !== userId) ?? [])
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, setUserFollowings, userFollowings, userId])

  if (!isAuthenticated || currentUser?.id === userId) {
    return null
  }

  if (!userFollowings) {
    return <>
      <button className="small white disabled">フォロー</button>
    </>
  }

  return <>
    {
      followingThisUser
        ? <button className="small follow white" onClick={ unfollow }>フォロー中</button>
        : <button className="small follow" onClick={ follow }>フォロー</button>
    }
  </>
}

export default FollowButton
