import { useCallback, useContext, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { ModalWrapper } from './modal_wrapper'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { RegionShippingService, RegionShippingServiceForm } from '../types'
import { regions } from '../lib/regions'
import { shippingServices } from '../lib/shipping_services'
import { regionShippingServicesRepository } from '../repositories/region_shipping_services_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  onClose: (regionShippingService: RegionShippingService|undefined) => void
  regionShippingService?: RegionShippingService
}>

const RegionShippingServiceModal = ({ onClose, regionShippingService }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const setLoading = useState<boolean>(true)[1]

  const { currentUser, setCurrentUser } = useContext(AuthContext)

  const {
    control,
    handleSubmit,
    register,
    getValues,
    // reset,
  } = useForm<RegionShippingService>({
    defaultValues: regionShippingService,
  })

  // useEffect(() => {
  //   reset(regionShippingService)
  // }, [regionShippingService, reset])

  const onSubmit = useCallback((params: RegionShippingService) => {
    // TODO: Validation
    const newRegionShippingService: RegionShippingServiceForm = {
      ...params,
      region: params.region.id,
      shippingService: params.shippingService.id,
    }

    if (regionShippingService) {
      if (!regionShippingService.id) return

      regionShippingServicesRepository
        .update({ id: regionShippingService.id, params: newRegionShippingService })
        .then(() => {
          const rss: RegionShippingService = {
            ...params,
            id: regionShippingService?.id,
          }

          onClose(rss)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      // creating a new rss
      regionShippingServicesRepository
        .create(newRegionShippingService)
        .then(({ regionShippingService }) => {
          currentUser && setCurrentUser?.({
            ...currentUser,
            hasRegionShippingServices: true,
          })

          onClose(regionShippingService)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setLoading(true)
  }, [regionShippingService, setLoading, onClose, addError, currentUser, setCurrentUser])

  const regionShippingServiceTitle = useMemo(() => {
    return regionShippingService ? t('account.sell.shipping_options.modal.title_edit') : t('account.sell.shipping_options.modal.title_new')
  }, [t, regionShippingService])

  const regionsLocalised = regions.map(r => {
    return {
      id: r.id,
      label: t(`account.sell.shipping_options.regions.region${r.id}`),
      value: r.id,
    }
  })

  const shippingServicesLocalised = shippingServices.map(s => {
    return {
      ...s,
      label: t(`account.sell.shipping_options.options.${s.label}`) ?? '',
      value: s.id,
    }
  })

  if (!regionShippingService) {
    return <>Loading...</>
  }

  return (
    <ModalWrapper>
      <div>
        <h1>{ regionShippingServiceTitle }</h1>
        <form onSubmit={ handleSubmit(onSubmit) } className="single-line">
          <div className="select">
            <label>
              { t('account.sell.shipping_options.modal.region') }
            </label>
            <Controller
              control={ control }
              name='region'
              render={ ({ field }) => (
                <Select
                  options={ regionsLocalised }
                  onChange={ val => field.onChange(val) }
                  placeholder={ t('defaults.select') }
                  value={ regionsLocalised.find(r => r.id === getValues('region').id) }
                />
              ) }
            />
          </div>
          <div className="select">
            <label>
              { t('account.sell.shipping_options.modal.service') }
            </label>
            <Controller
              control={ control }
              name='shippingService'
              render={ ({ field }) => (
                <Select
                  options={ shippingServicesLocalised }
                  onChange={ val => field.onChange(val) }
                  placeholder={ t('defaults.select') }
                  value={ shippingServicesLocalised.find(s => s.id === getValues('shippingService').id) }
                />
              ) }
            />
          </div>
          <p>
            <label>
              { t('account.sell.shipping_options.modal.price_jpy') }
            </label>
            <label>
              <input className="usd" type="number" step="1" min="0" { ...register('priceJpy') } required /> 円
            </label>
          </p>
          <p>
            <label>
              { t('account.sell.shipping_options.modal.description') }
            </label>
            <label>
              <textarea className="usd" { ...register('description') } />
            </label>
          </p>
          <p>
            <button className="full">
              { t('account.sell.shipping_options.modal.save') }
            </button>
          </p>
        </form>
        <p>
          <button className="large tool" onClick={ () => { onClose(undefined) } }>
            { t('account.sell.shipping_options.modal.cancel') }
          </button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { RegionShippingServiceModal }
