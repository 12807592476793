import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'

import { BasketContext } from '../contexts/basket_context'
import { Unit } from '../types'

type Props = Readonly<{
  unit: Unit
  quantity: number
  mode: 'payment' | 'subscription'
  onClose: () => void
}>

const UnitBasketMultipleSellersModal = ({ unit, quantity, mode, onClose }: Props): JSX.Element => {
  const { t } = useTranslation()

  const { addUnitToBasket, setShowBasket } = useContext(BasketContext)

  const showBasket = useCallback(() => {
    onClose()
    setShowBasket?.(true)
  }, [onClose, setShowBasket])

  const clearBasketAndAddUnit = useCallback(() => {
    addUnitToBasket?.(unit, true, quantity, mode)
    showBasket()
  }, [addUnitToBasket, unit, showBasket])

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('checkout.basket_multiple_sellers.title') }</h1>
        <p>{ t('checkout.basket_multiple_sellers.body1') }</p>
        <p>{ t('checkout.basket_multiple_sellers.body2') }</p>
        <p>
          <button className="full" onClick={ _ => clearBasketAndAddUnit() }>{ t('checkout.basket_multiple_sellers.button_clear') }</button>
        </p>
        <p>
          <button className="full" onClick={ showBasket }>{ t('checkout.basket_multiple_sellers.button_view') }</button>
        </p>
        <p>
          <button className="full white" onClick={ onClose }>{ t('checkout.basket_multiple_sellers.button_cancel') }</button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { UnitBasketMultipleSellersModal }
