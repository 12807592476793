import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ModalWrapper } from './modal_wrapper'

import { BasketContext } from '../contexts/basket_context'
import { UnitOrderSummary } from './unit_order_summary'
import { AuthContext } from '../contexts/auth_context'

const UnitBasketModal = (): JSX.Element => {
  const { t, i18n } = useTranslation()

  const { isAuthenticated } = useContext(AuthContext)
  const { basketUnits, setShowBasket, setShowOrderAddress, basketAddedMessage } = useContext(BasketContext)

  const onClose = useCallback(() => {
    setShowBasket?.(false)
  }, [setShowBasket])

  // allow escape key to close modal
  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', keyListener)

    return () => {
      window.removeEventListener('keydown', keyListener)
    }
  }, [onClose])

  const addressRequired = useMemo(() => {
    // determine if a basket item requires an address
    return basketUnits.some(basketUnit => basketUnit.unit.items?.some(item => item.type !== 'DigitalDownload'))
  }, [basketUnits])

  const totalBasketPrice = useMemo(() => {
    const i = basketUnits
      .map((basketUnit) => {
        const listing = basketUnit.unit.listings?.find(l => l.platform === 'holic')
        return (listing?.priceJpy ?? 0) * basketUnit.quantity
      })

    if (i.length > 0) {
      return i.reduce((total, next) => +total + +next)
    } else {
      return 0
    }
  }, [basketUnits])

  const nextScreen = useCallback(() => {
    // future: only show the address screen if addressRequired is true
    // currently the address screen sends the request so we cannot prevent showing it
    setShowOrderAddress?.(true)
    setShowBasket?.(false)
  }, [setShowBasket, setShowOrderAddress])

  const newItemAddedMessage = useMemo(() => {
    if (!basketAddedMessage) return

    const items = basketAddedMessage.items

    const itemName = items?.map(item => {
      return (i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn
    }).join(' / ') ?? ''

    return (
      <p className="success">
        <Trans
          i18nKey="checkout.basket.add_success"
          values={ { name: itemName } }
          components={ { bold: <strong /> } }
        />
      </p>
    )
  }, [basketAddedMessage, i18n.language])

  const checkoutButton = useMemo(() => {
    if (basketUnits.length === 0) return

    if (!isAuthenticated) {
      return (
        <>
          <p>
            <Link to="/register" className="button full" onClick={ onClose }>
              { t('checkout.basket.button_register') }
            </Link>
          </p>
          <p>
            <Link to="/login" className="button full white" onClick={ onClose }>
              { t('checkout.basket.button_login') }
            </Link>
          </p>
        </>
      )
    }

    return (
      <p>
        <button className="full" onClick={ nextScreen }>
          { t('checkout.basket.button_checkout') }
        </button>
      </p>
    )
  }, [basketUnits.length, isAuthenticated, nextScreen, onClose, t])

  const total = useMemo(() => {
    if (basketUnits.length === 0) return

    return (
      <ul className="organised">
        <li>
          <span>{ t('checkout.basket.total') }</span>
          <span>{
            // add commas to the total price
            Math.round(totalBasketPrice).toLocaleString()
          }{ t('listings.price.currency_symbol') }{
            addressRequired
              ? t('checkout.basket.plus_shipping')
              : ''
          } (税込)</span>
        </li>
      </ul>
    )
  }, [addressRequired, basketUnits.length, t, totalBasketPrice])

  return (
    <ModalWrapper>
      <div className="basket">
        <h1>{ t('checkout.basket.title') }</h1>
        { newItemAddedMessage }
        <UnitOrderSummary onClose={ onClose } canDelete={ true } canView={ true } />
        { total }
        { checkoutButton }
        <p>
          <button className="full white" onClick={ onClose }>
            { basketUnits.length === 0 ? t('checkout.basket.button_close_a') : t('checkout.basket.button_close_b') }
          </button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { UnitBasketModal }
